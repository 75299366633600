import React from 'react';
import { ReactComponent as ScorecardIcon } from 'i/icons/scorecard.svg';

/**
 * Specific icon used for the "Scorecard" navigation link
 *
 * @returns {object} React render
 */
function ScoreIcon() {

    return (
        <div className="score-icon">
            <ScorecardIcon className="navigation__link-icon score-icon__frame" />
            <span className="score-icon__score">100</span>
        </div>
    );
}

export default ScoreIcon;
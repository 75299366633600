import React from 'react';
import PropTypes from 'prop-types';

import { en } from 'scripts/translations';

import TeamBadge from 'scripts/components/common/TeamBadge/TeamBadge';

/**
 * An individual match summarised into a small card
 *
 * @param {object} props - properties passed to the component
 * @param {object} props.match - a match object returned from a cricket match list API
 * @returns {object} React render
 */
function MatchCard( { match } ) {

    return (
        <a
            className={ `match-card ${ match.teams.home.teamClass ? `t-team-theme t-${ match.teams.home.teamClass }` : '' } ${ match.isFinal ? 'match-card--final' : '' }` }
            data-match-id={ match.matchId }
            href={ `${ match.linkUrl }/latest` }>
            <div className="match-card__theme team-background" aria-hidden="true"></div>
            <time className="match-card__date-container">
                <span className="match-card__day">{ match.displayTime.day }</span>
                <span className="match-card__date">{ match.displayTime.date }</span>
                <span className="match-card__month">{ match.displayTime.month }</span>
            </time>
            <div className="match-card__logo-container">
                <span className="match-card__team-logo">
                    <TeamBadge abbr={ match.teams.home.abbr } className="team-logo" type="matchListLight" />
                </span>
                <span className="match-card__team-name u-show-tablet">{ match.teams.home.shortName }</span>
                <span className="match-card__vs u-show-tablet">{ en.vs }</span>
                <span className="match-card__team-logo">
                    <TeamBadge abbr={ match.teams.away.abbr } className="team-logo" type="matchListLight" />
                </span>
                <span className="match-card__team-name u-show-tablet">{ match.teams.away.shortName }</span>
            </div>
            <div className="match-card__description">
                <span className="match-card__meta">
                    <span className="match-card__gender">
                        { match.gender } &bull; { match.description }
                    </span> &bull; <strong>{ match.displayTime.time }</strong> &bull; { match.venue }, { match.city }
                </span>
                <span className="match-card__team-name u-hide-tablet">{ match.teams.home.shortName }</span>
                <span className="match-card__team-name u-hide-tablet">
                    <span className="match-card__vs">{ en.vs }</span>
                    { match.teams.away.shortName }
                </span>
            </div>
            <div className="match-card__button-container">
                <div className="match-card__button">Match Centre</div>
            </div>
        </a>
    );
}

MatchCard.propTypes = {
    match: PropTypes.object
};

export default MatchCard;
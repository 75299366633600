import React from 'react';
import PropTypes from 'prop-types';

import { en } from 'scripts/translations';

import TeamBadge from 'scripts/components/common/TeamBadge/TeamBadge';
import LazyLoadImage from 'scripts/components/common/LazyLoadImage/LazyLoadImage';

/**
 * Latest Feed Card for lineups
 *
 * @param {object} props - properties passed to the component
 * @param {object} props.teams - the teams taking part in the match
 * @returns {object} React render
 */
function LineupsCard( { teams } ) {

    const teamsMap = [ teams.home, teams.away ];

    const renderHeadshot = headshotUrl => {

        if ( headshotUrl ) {
            return (
                /**
                 * although the following dimensions are very large for this use case, these are the same dimensions used for headshots through the site
                 * using them in this way makes better use of the ODIR cloudfront cache, which makes requests much, much faster
                 */
                <LazyLoadImage
                    src={ `${ headshotUrl }?width=460height=690` }
                    alt="Player Image"
                    imgClassName="lineups__player-img"
                    placeholder={ false } />
            );
        }

        return null;
    };

    return (
        <div className="generic-card__content">
            <h1 className="latest-feed__card-title latest-feed__card-title--active">{ en.lineups }</h1>
            <div className="generic-card__info-container">
                <div className="lineups">
                    {
                        teamsMap.map( ( team, i ) => {
                            return (
                                <div className={ `lineups__lineup t-team-theme t-${ team.className }` } key={ i }>
                                    <div className="lineups__team">
                                        <TeamBadge
                                            abbr={ team.abbr }
                                            className="lineups__team-badge"
                                            type="symbol" />
                                        <h3 className="lineups__team-name">{ team.shortName }</h3>
                                    </div>
                                    <ol className="lineups__list">
                                        {
                                            team.squad.map( player => {
                                                return (
                                                    <li className="lineups__player" key={ player.id }>
                                                        <div className="lineups__player-img-container">
                                                            { renderHeadshot( player.headshotUrl ) }
                                                        </div>
                                                        <span className="lineups__player-name">
                                                            { player.firstName }
                                                            <span className="lineups__player-name-last">{ player.lastName }</span>
                                                        </span>
                                                    </li>
                                                );
                                            } )
                                        }
                                    </ol>
                                </div>
                            );
                        } )
                    }
                </div>
            </div>
            <h1 className="latest-feed__card-title latest-feed__card-title--inactive u-hide-tablet">{ en.lineups }</h1>
        </div>
    );
}

LineupsCard.propTypes = {
    teams: PropTypes.object
};

export default LineupsCard;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { ONE_HUNDRED_PERCENT } from 'scripts/constants';
import { prependZeroes } from 'scripts/helpers';

import TeamBadge from 'scripts/components/common/TeamBadge/TeamBadge';

import { ReactComponent as TargetIcon } from 'i/icons/target.svg';

/**
 * When in the second innings, this bar tracks the progress of the batting team to reach the run total set by their opponent
 *
 * @param {object} props - properties passed to the component
 * @param {number} props.currentScore - the run total of the batting team
 * @param {number} props.oppositionScore - the run total of the opposing team in the first innings
 * @param {object} props.opposingTeam - info on the opposing team
 * @returns {object} React render
 */
function ChaseBar( { currentScore, oppositionScore, opposingTeam } ) {

    const calcChaseProgressPercent = ( score, comparibleSore ) => {
        const chaseProgressPercent = ( score / comparibleSore ) * ONE_HUNDRED_PERCENT;
        return `${ chaseProgressPercent }%`;
    };
    const chaseBarPercent = useMemo( () => calcChaseProgressPercent( currentScore, oppositionScore ), [ currentScore, oppositionScore ] );

    return (
        <div className="chase-bar">
            <span className="chase-bar__text">The Chase</span>
            <div className="chase-bar__progress">
                <div className="chase-bar__progress-inner" style={ { width: chaseBarPercent } }></div>
                <TargetIcon className="chase-bar__target-icon"/>
            </div>
            <div className="chase-bar__target">
                <span className="chase-bar__target-text">Target</span>
                <span className="chase-bar__target-score" dangerouslySetInnerHTML={ prependZeroes( oppositionScore, true, 'chase-bar__target-score chase-bar__target-score--zero' ) }></span>
            </div>
            <div className={ `chase-bar__opposing-team t-team-theme t-${ opposingTeam.className }` }>
                <TeamBadge abbr={ opposingTeam.abbr } className="chase-bar__team-badge" type="matchListDark" />
            </div>
        </div>
    );
}

ChaseBar.propTypes = {
    currentScore: PropTypes.number.isRequired,
    oppositionScore: PropTypes.number.isRequired,
    opposingTeam: PropTypes.object
};

export default ChaseBar;
import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

/**
 * Tabs container for multiple content tabs that can be switched between
 *
 * @param {object} props - properties passed to the component
 * @param {Array<object>} props.children - React element children to display in each tab
 * @param {string} props.className - additional CSS Class to add to container
 * @returns {object} React render
 */
function TabsComponent( { children, className = '' } ) {

    const currentInningsIndex = children[ 0 ].props.children.props.currentInningsIndex ? children[ 0 ].props.children.props.currentInningsIndex : 0;

    return (
        <Tabs defaultIndex={ currentInningsIndex } className={ `tabs ${ className }` }>
            <TabList className="tabs__tab-list">
                {
                    children.map( ( tab ) => {
                        return (
                            <Tab
                                className={ `tabs__tab ${ tab.props.tabclass || '' }` }
                                selectedClassName="is-active"
                                key={ tab.props.tabname }>
                                { tab.props.tabname }
                            </Tab>
                        );
                    } )
                }
            </TabList>
            {
                children.map( tab => {
                    return (
                        <TabPanel
                            className="tabs__tab-panel"
                            selectedClassName="is-active"
                            key={ tab.props.tabname }>
                            { tab }
                        </TabPanel>
                    );
                } )
            }
        </Tabs>
    );
}

TabsComponent.propTypes = {
    children: PropTypes.instanceOf( Array ).isRequired,
    className: PropTypes.string,
    transitionDuration: PropTypes.number
};

export default TabsComponent;
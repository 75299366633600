/**
 * Makes the Team Name into a class
 *
 * @param {string} teamName - the team's name, e.g. 'Northern Superchargers'
 * @returns {string} the team name in the format 'northern-superchargers' or null
 */
export const getTeamClass = teamName => {

    switch ( teamName.toLowerCase() ) {
        case 'tbc':
        case 'tbd':
        case '1st':
        case '1st-place':
        case '2nd':
        case '2nd-place':
        case '3rd':
        case '3rd-place':
        case '4th':
        case '4th-place':
            return null;
        default:
            return teamName.replace( /\s/g, '-' ).toLowerCase();
    }
};

/**
 * Using a promo's tags, gets any modifiers set in the CMS
 * These are:
 * * The Hundred - Alternate Branding, used to reverse the branding colours on the promo
 * * The Hundred - Flip Content, used to reverse the direction of the content inside the promo
 *
 * @param {string} overClassName - the name of the class to be used when constructing the modifiers
 * @param {Array<object>} tags - an array of tags objects assigned in the CMS
 * @returns {string} the BEM modifiers to apply directly as a class
 */
export const getPromoModifiers = ( overClassName, tags ) => {
    let className = '';

    if ( tags.length > 0 ) {
        tags.forEach( tag => {
            switch ( tag.label ) {
                case 'The Hundred - Alternate Branding':
                    className += ` ${ overClassName }--alternate`;
                    break;
                case 'The Hundred - Flip Content':
                    className += ` ${ overClassName }--flipped`;
                    break;
                default:
                    break;
            }
        } );
    }

    return className;
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { en } from 'scripts/translations';

import Batting from './Batting';
import Bowling from './Bowling';
import FallOfWicket from './FallOfWicket';

/**
 * A scorecard innings, displayed in a tab
 *
 * @param {object} props - properties passed to the component
 * @param {object} props.inningsData - scoring context data for this innings
 * @returns {object} React render
 */
function Innings( { inningsData } ) {

    const [ activeToggle, setActiveToggle ] = useState( false );

    const showAllTable = ( showAll ) => {
        if ( showAll ) {
            setActiveToggle( true );
        } else {
            setActiveToggle( false );
        }
    };
    
    return (
        <div className="scorecard__main">
            <div className="scorecard__toggle-container u-show-tablet">
                <button
                    className={ `scorecard__toggle ${ !activeToggle ? 'active' : '' }` }
                    onClick={ () => showAllTable( false ) }>
                    { en.basic }
                </button>
                <button
                    className={ `scorecard__toggle ${ activeToggle ? 'active' : '' }` }
                    onClick={ () => showAllTable( true ) }>
                    { en.advanced }
                </button>
            </div>
            <Batting inningsData={ inningsData } showAllTable={ activeToggle } />
            {
                inningsData.batting.stats.fow && inningsData.batting.stats.fow.length > 0 &&
                <FallOfWicket inningsData={ inningsData } showAllTable={ activeToggle }/>
            }
            <Bowling inningsData={ inningsData } showAllTable={ activeToggle } />
        </div>
    );
}

Innings.propTypes = {
    inningsData: PropTypes.object.isRequired
};

export default Innings;
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';

import ImageLoader from './ImageLoader';

/**
 * A container for an Image Loader that provides a placeholder
 *
 * @param {object} props - properties passed to the component
 * @param {string} props.src - src ODIR URL for an image
 * @param {Array<object>} props.srcConfigs - object containing srcSet string of ODIR URLs, and a mediaQuery to control them
 * @param {string} props.alt - image alt text
 * @param {string} props.className - additional CSS className for the component
 * @param {string} props.pictureClassName - additional CSS className for the <picture> element
 * @param {string} props.imgClassName - additional CSS className for the <img> element
 * @param {boolean} props.placeholder - set to false if the image should not have a placeholder
 * @param {string} props.placeholderModifier - additional CSS class for the placeholder
 * @returns {object} React render
 */
function LazyLoadImage( { src, srcConfigs, alt, className, pictureClassName, imgClassName, placeholder = true, placeholderModifier } ) {

    let placeholderClass;
    if ( placeholder ) {
        placeholderClass = `lazy-image__placeholder ${ placeholderModifier || '' }`;
    }

    useEffect( () => {
        return () => { // unmount
            null;
        };
    }, [] ); // eslint-disable-line

    return (
        <LazyLoad height={ 100 } once>
            <div className={ `lazy-image ${ className || '' }` }>
                {
                    placeholder ? (
                        <div className={ placeholderClass }>
                            <ImageLoader
                                src={ src }
                                srcConfigs={ srcConfigs }
                                alt={ alt }
                                pictureClassName={ pictureClassName }
                                imgClassName={ imgClassName }/>
                        </div>
                    ) : (
                        <ImageLoader
                            src={ src }
                            srcConfigs={ srcConfigs }
                            alt={ alt }
                            pictureClassName={ pictureClassName }
                            imgClassName={ imgClassName }/>
                    )
                }
            </div>
        </LazyLoad>
    );
}

LazyLoadImage.propTypes = {
    src: PropTypes.string.isRequired,
    srcConfigs: PropTypes.arrayOf( PropTypes.object ),
    alt: PropTypes.string,
    placeholder: PropTypes.bool,
    placeholderModifier: PropTypes.string,
    className: PropTypes.string,
    pictureClassName: PropTypes.string,
    imgClassName: PropTypes.string
};

export default LazyLoadImage;
import React, { useContext, useMemo } from 'react';
import { CSSTransition } from 'react-transition-group';

import matchContext from 'scripts/context/match/matchContext';

import Header from './Header';
import NoScorebox from './noMatch/NoScorebox';
import PreScorebox from './preMatch/PreScorebox';
import LiveScorebox from './liveMatch/LiveScorebox';
import PostScorebox from './postMatch/PostScorebox';

/**
 * Container for the current score state of the match
 *
 * @returns {object} React render
 */
export default function Scorebox() {

    const { match } = useContext( matchContext );
    const { info, scorecard, teams, fixtureNoScoring } = match;

    const getScoreboxState = matchState => {

        switch ( matchState ) {
            case 'U':
                return <PreScorebox />;
            case 'L':
                return <LiveScorebox />;
            case 'C':
                return <PostScorebox />;
            default:
                return <NoScorebox />;
        }
    };
    const scoreboxState = useMemo( () => getScoreboxState( info.state ), [ info.state ] );

    const transitionOptions = {
        'in': true,
        'appear': true,
        'timeout': 100,
        'classNames': 'scorebox',
        'unmountOnExit': true
    };

    return (
        <CSSTransition { ...transitionOptions }>
            <div className="scorebox">
                <Header
                    info={ info }
                    scorecard={ scorecard }
                    teams={ teams }
                    fixtureNoScoring={ fixtureNoScoring }
                    isLive={ ( info.state && info.state === 'L' ) && ( scorecard && scorecard.currentInningsIndex <= 1 ) } />
                <div className="scorebox__wrapper wrapper">
                    { scoreboxState }
                </div>
            </div>
        </CSSTransition>
    );
}
import { STYLE_CLS_NAMES } from 'scripts/constants';

export const toggleScorebox = () => {
    const scoreboxElement = document.querySelector( '.js-scorebox-container' );
    if ( scoreboxElement ) {
        if ( scoreboxElement.classList.contains( STYLE_CLS_NAMES.U_HIDE ) ) {
            scoreboxElement.classList.remove( STYLE_CLS_NAMES.U_HIDE );
        } else {
            scoreboxElement.classList.add( STYLE_CLS_NAMES.U_HIDE );
        }
    }
};
export const MENS_TOURNAMENT_ID = '75075';
export const WOMENS_TOURNAMENT_ID = '75076';
// export const MENS_TOURNAMENT_ID = '75167';
// export const WOMENS_TOURNAMENT_ID = '75167';

// RESPONSE STATUS CODES
export const RESPONSE_STATUS = {
    SUCCESS: 200,
    NOT_FOUND: 404
};

export const UPCOMING_POLL_INTERVAL = 120000; // 2 minutes
export const PRE_POLL_INTERVAL = 30000; // 30 seconds
export const LIVE_POLL_INTERVAL = 25000; // 25 seconds

export const ALL_SETTLED_FULFILLED = 'fulfilled';

export const BRIGHTCOVE_PLAYER_SCRIPT = 'https://players.brightcove.net/{{accountId}}/{{playerId}}_default/index.min.js';

export const EVENTS = {
    VIDEO: {
        // event fired when a new video starts playing
        START: 'video/start',
        // video fired when a video ends playing
        END: 'video/end',
        // video fired when the KDP is ready to do work !
        READY: 'player/ready',
        // incoming event to start the current video playing
        PLAY: 'player/play-video',
        // incoming event to pause the current video
        PAUSE: 'player/pause-video',
        // fired when the full screen close has finished
        CLOSE_FULL_SCREEN: 'player/full-screen-closed'
    }
};

export const STYLE_CLS_NAMES = {
    ANIMATE_IN: 'animate-in',
    ANIMATE_OUT: 'animate-out',
    IS_ANIMATING: 'is-animating',
    IS_ACTIVE: 'is-active',
    IS_DISABLED: 'is-disabled',
    IS_DOCKED: 'is-docked',
    IS_HIDDEN: 'is-hidden',
    IS_LOADING: 'is-loading',
    IS_OPEN: 'is-open',
    IS_REVEALED: 'is-revealed',
    IS_VISIBLE: 'is-visible',
    U_HIDE: 'u-hide'
};

export const ONE_HUNDRED_PERCENT = 100;

export const LESS_THAN_TEN = 10;

export const TIME = {
    SEVEN_DAYS_IN_MS: 604800000,
    ONE_DAY_IN_MS: 86400000,
    ONE_HOUR_IN_MS: 3600000,
    ONE_MINUTE_IN_MS: 60000,
    ONE_SECOND_IN_MS: 1000,
    ONE_MINUTE_IN_SECS: 60,
    ONE_HOUR_IN_MINS: 60,
    ONE_DAY_IN_HOURS: 24,
    ONE_HOUR_IN_SECONDS: 3600
};

export const MEASUREMENTS = {
    SMALL_MOBILE: 375,
    MOBILE: 415,
    PHABLET: 640,
    TABLET: 840,
    DESKTOP: 1025,
    WIDE: 1300
};

export const TOTAL_BALLS_IN_MATCH = 100;
export const BALLS_PER_OVER = 5;

export const COMPONENT_REVEAL_TRANSITION_OPTIONS = { // eslint-disable-line id-length
    'in': true,
    'appear': true,
    'timeout': 100,
    'classNames': 'reveal-component',
    'unmountOnExit': true
};

export const RESIZE_DEBOUNCE = 500;
export const SCROLL_DEBOUNCE = 150;

export const CRICVIZ_USER = 'pulse_ecb';
export const CRICVIZ_PASS = 'bVyuGmhGn7ES2ce';

// TEMP - only used for developing against dummy match data
export const PRACTICE_GAME_1_ID = 20729;
export const PRACTICE_GAME_2_ID = 36498;
export const PRACTICE_GAME_3_ID = 36610;
export const PRACTICE_GAME_4_ID = 36615;
export const PRACTICE_GAME_5_ID = 36616;
export const DUMMY_PHASES = [
    'No Scoring',
    'No Teams',
    'Upcoming',
    'Pre-match',
    'First Innings',
    'Second Innings',
    'Super Overs',
    'Completed',
    `Practice Game 1 (${ PRACTICE_GAME_1_ID })`,
    `Practice Game 2 (${ PRACTICE_GAME_2_ID })`,
    `Practice Game 3 (${ PRACTICE_GAME_3_ID })`,
    `Practice Game 4 (${ PRACTICE_GAME_4_ID })`,
    `Practice Game 5 (${ PRACTICE_GAME_5_ID })`
];
export const DEFAULT_DUMMY_PHASE = null;
// END TEMP

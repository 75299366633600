import React from 'react';
import PropTypes from 'prop-types';

import { en } from 'scripts/translations';

/**
 * Extras for a scorecard innings
 *
 * @param {object} props - properties passed to the component
 * @param {object} props.inningsData - scoring context data for this innings
 * @param {boolean} props.showAllTable - set to true to always show the full table
 * @returns {object} React render
 */
function Extras( { inningsData, showAllTable } ) {

    const generateExtrasString = stats => {
        let extrasString = '';
        extrasString += `${ stats.noBallRuns !== 0 ? `${ stats.noBallRuns / 2 }(${ stats.noBallRuns })` : stats.noBallRuns } ${ stats.noBallRuns === 1 ? en.noBalls : en.noBalls } &bull; `;
        extrasString += `${ stats.wideRuns } ${ stats.wideRuns === 1 ? en.wide : en.wides } &bull; `;
        extrasString += `${ stats.byeRuns } ${ stats.byeRuns === 1 ? en.bye : en.byes } &bull; `;
        extrasString += `${ stats.legByeRuns } ${ stats.legByeRuns === 1 ? en.legBye : en.legByes }`;
        return { __html: extrasString };
    };

    const getExtrasTotal = stats => {
        return stats.noBallRuns + stats.wideRuns + stats.byeRuns + stats.legByeRuns;
    };

    return (
        <tr className="scorecard__table-row scorecard__table-row--extras">
            <td className="scorecard__table-cell">
                <div className="scorecard__table-cell-alt">
                    { en.extras }
                    <span className="scorecard__table-cell-alt scorecard__table-cell-alt--extra" dangerouslySetInnerHTML={ generateExtrasString( inningsData.batting.stats ) } />
                </div>
            </td>
            <td className="scorecard__table-cell"><strong>{ getExtrasTotal( inningsData.batting.stats ) }</strong></td>
            {
                !showAllTable &&
                    <td className="u-show-tablet"></td>
            }
            {
                showAllTable &&
                    <td colSpan="4" className="u-show-tablet"></td>
            }
            <td colSpan="4" className="u-hide-tablet"></td>
        </tr>
    );
}

Extras.propTypes = {
    inningsData: PropTypes.object.isRequired,
    showAllTable: PropTypes.bool
};

export default Extras;
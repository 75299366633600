import React from 'react';
import { Link } from 'react-router-dom';

import { en } from 'scripts/translations';

import { ReactComponent as Logo } from 'i/elements/the-hundred-logo.svg';
import { ReactComponent as Chevrons } from 'i/chevrons/header.svg';

/**
 * Header for the matches list
 *
 * @returns {object} React render
 */
export default function MatchesHeader() {

    return (
        <header className="match-header match-header--matches">
            <div className="match-header__left">
                <Link to="/" className="match-header__matches-link">
                    <Logo className="match-header__logo"/>
                    <h1 className="match-header__title">{ en.matches }</h1>
                </Link>
            </div>
            <Chevrons className="match-header__chevrons"/>
        </header>
    );
}

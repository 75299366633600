import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import LazyLoadImage from 'scripts/components/common/LazyLoadImage/LazyLoadImage';

/**
 * Sponsor image for a team
 *
 * @param {object} props - properties passed to the component
 * @param {string} props.sponsorImage - URL to an ODIR sponsor image
 * @returns {object} React render
 */
function Sponsor( { sponsorImage } ) {

    if ( !sponsorImage ) {
        return null;
    }

    useEffect( () => {
        return () => { // unmount
            null;
        };
    }, [] ); // eslint-disable-line

    return (
        <div className="squads-sponsor">
            <div className="squads-sponsor__image-container">
                {
                    sponsorImage &&
                        <LazyLoadImage
                            src={ `${ sponsorImage }?height=140` }
                            srcConfigs={ [
                                { srcSet: `${ sponsorImage }?height=140, ${ sponsorImage }?height=280 2x`, mediaQuery: '(min-width: 840px)' },
                                { srcSet: `${ sponsorImage }?height=114, ${ sponsorImage }?height=228 2x`, mediaQuery: '(max-width: 840px)' }
                            ] }
                            alt="Team Sponsor"
                            placeholder={ false }/>
                }
                <span className="squads-sponsor__text u-hide-tablet">Powered By</span>
            </div>
        </div>
    );
}

Sponsor.propTypes = {
    sponsorImage: PropTypes.string
};

export default Sponsor;
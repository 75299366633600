import React from 'react';
import PropTypes from 'prop-types';

import { en } from 'scripts/translations';

import LazyLoadImage from 'scripts/components/common/LazyLoadImage/LazyLoadImage';

import BallCount from './BallCount';

/**
 * Latest Feed Card for a wicket
 *
 * @param {object} props - properties passed to the component
 * @param {object} props.card - card data
 * @returns {object} React render
 */
function Wicket( { card } ) {

    const renderHeadshot = bowler => {

        if ( bowler && bowler.headshotUrl ) {
            return (
                <LazyLoadImage
                    src={ `${ bowler.headshotUrl }?width=460height=690` }
                    srcConfigs={ [
                        { srcSet: `${ bowler.headshotUrl }?width=460height=690`, mediaQuery: '(max-width: 839px)' },
                        { srcSet: `${ bowler.headshotUrl }?width=460height=690, ${ bowler.headshotUrl }?width=920height=1380 2x`, mediaQuery: '(min-width: 840px)' },
                    ] }
                    alt={ `${ bowler.firstName } ${ bowler.lastName }` }
                    className="team-card__player-img-container"
                    imgClassName="team-card__player-img"
                    placeholder={ false } />
            );
        }

        return null;
    };

    return (
        <div className={ `team-card__content team-background ${ card.number % 2 ? '' : 'team-background--alt' }` }>
            <div className="team-card__text-container">
                {
                    card.ballNumber &&
                    <BallCount ballNumber={ card.ballNumber }/>
                }
                <h1 className="latest-feed__card-title latest-feed__card-title--active">{ en.wicket }</h1>
                <div className="team-card__text">
                    <h3 className="team-card__title">
                        { en.wicketCardTitle.replace( '{batter}', card.facingBatter.fullName ) }
                    </h3>
                    <h4 className="team-card__subtitle">
                        { en.wicketCardSubtitle.replace( '{bowler}', card.bowler.fullName ) }
                    </h4>
                </div>
            </div>
            <div className="team-card__image-container">
                { renderHeadshot( card.bowler ) }
            </div>
            <h1 className="latest-feed__card-title latest-feed__card-title--inactive u-hide-tablet">{ en.wicket }</h1>
        </div>
    );
}

Wicket.propTypes = {
    card: PropTypes.object
};

export default Wicket;

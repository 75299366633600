import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { initialiseVideos } from 'scripts/helpers';

/**
 * A Brightcove Video Player
 *
 * @param {object} props - properties passed to the component
 * @param {string} props.playerName - the ID of the player, used to interact with the player after init
 * @param {number} props.videoId - video ID
 * @param {string} props.className - additional CSS class for the element
 * @returns {object} React render
 */
function VideoPlayer( { playerName, videoId, className } ) {
    
    useEffect( () => {
        initialiseVideos();
        // eslint-disable-next-line
    }, [] );

    return (
        <video
            id={ playerName }
            data-video-id={ videoId }
            data-tracker-status="false"
            data-account="4854911276001"
            data-player="gzQguGP5Em"
            data-embed="default"
            className={ `video-player video-js ${ className }` }
            controls>
        </video>
    );
}

VideoPlayer.propTypes = {
    playerName: PropTypes.string,
    videoId: PropTypes.string,
    className: PropTypes.string
};

export default VideoPlayer;
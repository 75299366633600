import React, { useContext, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { en } from 'scripts/translations';

import matchContext from 'scripts/context/match/matchContext';

import LazyLoadImage from 'scripts/components/common/LazyLoadImage/LazyLoadImage';

import { ReactComponent as PlayerSilhouette } from 'i/elements/player-silhouette.svg';
import { ReactComponent as BattingIcon } from 'i/icons/bat.svg';
import { ReactComponent as OutIcon } from 'i/icons/out.svg';

import Header from './Header';
import Extras from './Extras';
import PenaltyRuns from './PenaltyRuns';

/**
 * Batting for a scorecard innings
 *
 * @param {object} props - properties passed to the component
 * @param {object} props.inningsData - scoring context data for this innings
 * @param {boolean} props.showAllTable - set to true to always show the full table
 * @returns {object} React render
 */
function Batting( { inningsData, showAllTable } ) {

    const { match } = useContext( matchContext );
    const { scorecard } = match;
    
    const tableEle = useRef( null );

    const [ isScrollable, setIsScrollable ] = useState( false );

    const renderHeadshot = headshotUrl => {

        if ( headshotUrl ) {
            return (
                /**
                 * although the following dimensions are very large for this use case, these are the same dimensions used for headshots through the site
                 * using them in this way makes better use of the ODIR cloudfront cache, which makes requests much, much faster
                 */
                <LazyLoadImage
                    src={ `${ headshotUrl }?width=460height=690` }
                    alt="Player Image"
                    imgClassName="scorecard__player-img"
                    placeholder={ false } />
            );
        }

        return (
            <div className="scorecard__player-img scorecard__player-img--silhouette">
                <PlayerSilhouette />
            </div>
        );
    };

    const renderName = player => {

        if ( player.stats.mod ) {
            return (
                <>
                    <div className="scorecard__player-name-container">
                        <OutIcon className="scorecard__icon-out" />
                        <span className="scorecard__player-name scorecard__player-name--out u-hide-tablet">{ player.info.fullName }</span>
                        <span className="scorecard__player-name scorecard__player-name--out u-show-tablet">{ player.info.shortName }</span>
                    </div>
                    <span className="scorecard__player-dismissal-message">{ player.stats.mod?.text || '' }</span>
                </>
            );
        } else if ( ( scorecard.currentState.facingBatter && scorecard.currentState.facingBatter.info && scorecard.currentState.facingBatter.info.id === player.info.id ) || ( scorecard.currentState.nonFacingBatter && scorecard.currentState.nonFacingBatter.info && scorecard.currentState.nonFacingBatter.info.id === player.info.id ) ) {
            return (
                <div className="scorecard__player-name-container">
                    <BattingIcon className="scorecard__icon-batting" />
                    <span className="scorecard__player-name scorecard__player-name--batting u-hide-tablet">{ player.info.fullName }</span>
                    <span className="scorecard__player-name scorecard__player-name--batting u-show-tablet">{ player.info.fullName }</span>
                </div>
            );
        }

        return (
            <div className="scorecard__player-name-container">
                <span className="scorecard__player-name u-hide-tablet">{ player.info.fullName }</span>
                <span className="scorecard__player-name u-show-tablet">{ player.info.fullName }</span>
            </div>
        );
    };

    const renderPlayerStatsCells = player => {

        const playerHasBatted = ( scorecard.currentState.facingBatter && scorecard.currentState.facingBatter.info && scorecard.currentState.facingBatter.info.id === player.info.id ) || ( scorecard.currentState.nonFacingBatter && scorecard.currentState.nonFacingBatter.info && scorecard.currentState.nonFacingBatter.info.id === player.info.id ) || player.stats.dismissal;
        const noStat = playerHasBatted ? '0' : '-';

        return (
            <>
                <td className="scorecard__table-cell scorecard__table-cell--border"><strong>{ player.stats.r || noStat }</strong></td>
                <td className="scorecard__table-cell">{ player.stats.b || noStat }</td>
                <td className={ `scorecard__table-cell ${ showAllTable ? '' : 'u-hide-tablet' }` }>{ player.stats[ '4s' ] || noStat }</td>
                <td className={ `scorecard__table-cell ${ showAllTable ? '' : 'u-hide-tablet' }` }>{ player.stats[ '6s' ] || noStat }</td>
                <td className={ `scorecard__table-cell ${ showAllTable ? '' : 'u-hide-tablet' }` }>{ player.stats.sr }</td>
            </>
        );
    };

    useEffect( () => {
        if ( tableEle.current ) {
            setIsScrollable( tableEle.current.clientWidth > tableEle.current.parentElement.clientWidth );
        }
    }, [] ); // eslint-disable-line

    return (
        <>
            <Header title={ en.batting } team={ inningsData.batting.team } showScrollingIcon={ showAllTable || isScrollable } />
            <div className="scorecard__table-container scorecard__table-container--batting">
                <table className="scorecard__table" ref={ tableEle }>
                    <thead className="scorecard__table-head">
                        <tr className="scorecard__table-head-row">
                            <th className="scorecard__table-heading scorecard__table-heading--player">{ en.player }</th>
                            <th className="scorecard__table-heading">{ en.runs }</th>
                            <th className="scorecard__table-heading">{ en.balls }</th>
                            <th className={ `scorecard__table-heading scorecard__table-heading--lower ${ showAllTable ? '' : 'u-hide-tablet' }` }>4s</th>
                            <th className={ `scorecard__table-heading scorecard__table-heading--lower ${ showAllTable ? '' : 'u-hide-tablet' }` }>6s</th>
                            <th className={ `scorecard__table-heading scorecard__table-heading--sr ${ showAllTable ? '' : 'u-hide-tablet' }` }>{ en.strikeRate }</th>
                        </tr>
                    </thead>
                    <tbody className="scorecard__table-body">
                        {
                            inningsData.batting.stats.byPlayer.map( player => (
                                <tr className="scorecard__table-row" key={ player.info.id }>
                                    <td className="scorecard__table-cell scorecard__table-cell--player">
                                        <div className={ `scorecard__player-image-container ${ player.stats.dismissal ? 'scorecard__player-image-container--out' : '' }` }>
                                            { renderHeadshot( player.info.headshotUrl ) }
                                        </div>
                                        <div className="scorecard__player">
                                            { renderName( player ) }
                                        </div>
                                    </td>
                                    { renderPlayerStatsCells( player ) }
                                </tr>
                            ) )
                        }
                        <Extras inningsData={ inningsData } showAllTable={ showAllTable } />
                        <PenaltyRuns inningsData={ inningsData } showAllTable={ showAllTable } />
                        <tr className="scorecard__table-row scorecard__table-row--total">
                            <td className="scorecard__table-cell-alt">Totals</td>
                            <td className="scorecard__table-cell"><strong>{ inningsData.batting.stats.runs }</strong></td>
                            <td className="scorecard__table-cell">{ inningsData.batting.stats.ballsFaced }</td>
                            <td className={ `scorecard__table-cell ${ showAllTable ? '' : 'u-hide-tablet' }` }>{ inningsData.batting.stats.fours }</td>
                            <td className={ `scorecard__table-cell ${ showAllTable ? '' : 'u-hide-tablet' }` }>{ inningsData.batting.stats.sixes }</td>
                            <td className={ `scorecard__table-cell ${ showAllTable ? '' : 'u-hide-tablet' }` }>-</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}

Batting.propTypes = {
    inningsData: PropTypes.object.isRequired,
    showAllTable: PropTypes.bool
};

export default Batting;
import React from 'react';
import { CSSTransition } from 'react-transition-group';

import { COMPONENT_REVEAL_TRANSITION_OPTIONS } from 'scripts/constants';

import PostMatchHeader from './PostMatchHeader';
import MatchReport from './MatchReport';

/**
 * Container for the post match Latest screen components, a header and match report article
 *
 * @returns {object} React render
 */
export default function PostMatch() {

    return (
        <CSSTransition { ...COMPONENT_REVEAL_TRANSITION_OPTIONS }>
            <section className="post-match reveal-component">
                <PostMatchHeader />
                <MatchReport />
            </section>
        </CSSTransition>
    );
}
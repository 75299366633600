import {
    splitPlayerName,
    bindPlayerInfo
} from 'scripts/helpers';

/**
 * Models the currentState object for use in the scoring reducer
 *
 * @param {object} scoring - the match scoring data
 * @param {object} teams - an object containing the home and away teams from the match context
 * @returns {object} the currentState object for use in the scoring reducer
 */
export const modelCurrentState = ( scoring, teams ) => {
    
    const currentState = scoring.currentState;
    let modelledCurrentState = {};
    
    if ( currentState && Object.keys( currentState ).length ) {

        let currentBowler = null;
        let facingBatter = null;
        let nonFacingBatter = null;
        
        const currentInningsIndex = currentState.currentInningsIndex >= 1 ? 1 : 0; // we only display the stats for the first two innings

        // as the currentBowler is an ID, if there is no bowler it can either be -1 or 0
        const currentBowlerWithInfo = currentState.currentBowlerPulseId > 0 ? bindPlayerInfo( currentState.currentBowlerPulseId, teams ) : null;
        if ( currentBowlerWithInfo ) {
            currentBowler = {
                info: currentBowlerWithInfo,
                stats: getPlayerBowlingStats( currentState.currentBowlerPulseId, scoring.innings[ currentInningsIndex ].scorecard.bowlingStats, 'playerId' )
            };
        }
        
        // as the facingBatsman is an ID, if there is no bowler it can either be -1 or 0
        const facingBatterWithInfo = currentState.facingBatsmanPulseId > 0 ? bindPlayerInfo( currentState.facingBatsmanPulseId, teams ) : null;
        if ( facingBatterWithInfo ) {
            facingBatter = {
                info: facingBatterWithInfo,
                stats: getPlayerBattingStats( currentState.facingBatsmanPulseId, scoring.innings[ currentInningsIndex ].scorecard.battingStats )
            };
        }

        // as the nonFacingBatsman is an ID, if there is no bowler it can either be -1 or 0
        const nonFacingBatterWithInfo = currentState.nonFacingBatsmanPulseId > 0 ? bindPlayerInfo( currentState.nonFacingBatsmanPulseId, teams ) : null;
        if ( nonFacingBatterWithInfo ) {
            nonFacingBatter = {
                info: nonFacingBatterWithInfo,
                stats: getPlayerBattingStats( currentState.nonFacingBatsmanPulseId, scoring.innings[ currentInningsIndex ].scorecard.battingStats )
            };
        }

        modelledCurrentState = {
            bowler: currentBowler,
            currentBattersIds: currentState.currentBatsmen,
            facingBatter: facingBatter,
            nonFacingBatter: nonFacingBatter,
            currentPartnership: currentState.currentPartnership,
            partnership: currentState.partnership
        };
    }

    return modelledCurrentState;
};

/**
 * Models the passed innings data into just bowling stats
 *
 * @param {object} scoring - the match scoring data
 * @param {Array<number>} battingOrder - the batting order for the match, e.g. [0,1]
 * @param {object} teams - an object containing the home and away teams from the match context
 * @returns {Array<object>} the over history for each innings
 */
export const modelInnings = ( scoring, battingOrder, teams ) => {

    let modelledInnings = [];

    const firstBattingTeam = battingOrder[ 0 ] === 0 ? teams.home : teams.away;
    const firstBowlingTeam = battingOrder[ 0 ] === 1 ? teams.home : teams.away;

    scoring.innings.forEach( ( innings, idx ) => {

        const modelledInning = {
            batting: {
                team: idx === 0 ? firstBattingTeam : firstBowlingTeam,
                stats: modelTeamBattingStats( innings, idx === 0 ? firstBattingTeam : firstBowlingTeam, scoring )
            },
            bowling: {
                team: idx === 0 ? firstBowlingTeam : firstBattingTeam,
                stats: modelTeamBowlingStats( innings, idx === 0 ? firstBowlingTeam : firstBattingTeam, )
            },
            overHistory: innings.overHistory
        };
        modelledInnings.push( modelledInning );
    } );

    return modelledInnings;
};

/**
 * Formats and binds squad players with their BIO metdata
 *
 * @param {object<Array>} squad - an object containing an array of the team's players, an array of those player's BIOs and the squads wicket keeper and captain player IDs
 * @returns {object} the squad with player's metadata bound each player
 */
export const modelSquad = squad => {

    if ( !squad ) {
        return [];
    }

    let formattedSquad = [];
    
    squad.players.forEach( player => { // eslint-disable-line complexity

        let formattedPlayer = {
            ...player,
            pulseId: player.id || player.id
        };

        /**
         * These values are stored in squad data so can be applied to every player if relevant
         * They can be different for each match so should not be stored in session storage
         */
        if ( formattedPlayer.id === squad.wicketKeeperId ) {
            formattedPlayer.wicketKeeper = true;
        }
        if ( formattedPlayer.id === squad.captainId ) {
            formattedPlayer.captain = true;
        }

        const playerNames = splitPlayerName( player.fullName );
        formattedPlayer.firstName = playerNames.first;
        formattedPlayer.lastName = playerNames.last;
        
        const fromSessionStorage = JSON.parse( sessionStorage.getItem( `${ formattedPlayer.id }_bioData` ) );
        if ( fromSessionStorage && fromSessionStorage.headshotUrl ) {

            formattedPlayer.firstName = fromSessionStorage.firstName;
            formattedPlayer.lastName = fromSessionStorage.lastName;
            formattedPlayer.headshotUrl = fromSessionStorage.headshotUrl;
            formattedPlayer.role = fromSessionStorage.role;
            formattedPlayer.overseas = fromSessionStorage.overseas || false;

            formattedSquad.push( formattedPlayer );
            return; // we have the info we need, break out of this player
        }
    
        if ( squad.bios && squad.bios.length > 0 ) {

            const matchingBio = squad.bios.find( bio => {
                if ( bio.references && bio.references.length > 0 ) {
                    const bioPlayerRef = bio.references.filter( ( { type } ) => type === 'CRICKET_PLAYER' )[ 0 ];
                    return formattedPlayer.id === bioPlayerRef.id;
                }
                return null;
            } );

            if ( matchingBio ) {

                if ( matchingBio.forename ) {
                    formattedPlayer.firstName = matchingBio.forename;
                }
                if ( matchingBio.surname ) {
                    formattedPlayer.lastName = matchingBio.surname;
                }
                if ( matchingBio.headshot && matchingBio.headshot.onDemandUrl ) {
                    formattedPlayer.headshotUrl = matchingBio.headshot.onDemandUrl;
                } else {
                    formattedPlayer.headshotUrl = null;
                }
                if ( matchingBio.metadata && Object.keys( matchingBio.metadata ).length > 0 ) {
                    formattedPlayer.role = matchingBio.metadata.role || null;
                    formattedPlayer.overseas = matchingBio.metadata.overseas || false;
                }
            }
        }

        if ( formattedPlayer.id ) {
            sessionStorage.setItem( `${ formattedPlayer.id }_bioData`, JSON.stringify( { ...formattedPlayer } ) );
        }
        formattedSquad.push( formattedPlayer );

    } );

    return formattedSquad;
};

/**
 * Models the passed innings data into just bowling stats
 *
 * @param {object} inningsData - an innings object from the scoring response to model bowling data from
 * @param {object} bowlingTeam - the bowling team object
 * @returns {object} modelled bowling stats for an innings
 */
const modelTeamBowlingStats = ( inningsData, bowlingTeam ) => {

    if ( Object.keys( inningsData ).length ) {

        let playersWithStats = [];
        
        if ( inningsData.scorecard.bowlingStats.length ) {
            inningsData.scorecard.bowlingStats.forEach( bowler => {
                const playerInfo = getPlayerBowlingStats( bowler.playerId, bowlingTeam.squad, 'id' );
                if ( playerInfo ) {
                    playersWithStats.push( { info: playerInfo, stats: bowler } );
                }
            } );
        }

        // Temp fix to append non-bowlers to the bowling stats table with 'u-hide' to prevent the app from crashing due to 'Rendered fewer hooks than expected' error when new bowlers come on
        let playersWithoutStats = [];
        if ( bowlingTeam.squad.length > 0 ) {
            bowlingTeam.squad.forEach( player => {
                const playerInfo = getPlayerBowlingStats( player.id, inningsData.scorecard.bowlingStats, 'playerId' );
                if ( !playerInfo ) {
                    playersWithoutStats.push( { info: player, stats: null } );
                }
            } );
        }

        return {
            inningsNumber: inningsData.inningsNumber,
            wkts: inningsData.scorecard.wkts,
            byPlayer: [ ...playersWithStats, ...playersWithoutStats ]
        };
    }

    return null;
};

/**
 * Models the passed innings data into just batting stats
 *
 * @param {object} inningsData - an innings object from the scoring response to model batting data from
 * @param {object} battingTeam - the batting team object
 * @returns {object} modelled batting stats for an innings
 */
const modelTeamBattingStats = ( inningsData, battingTeam ) => {

    if ( Object.keys( inningsData ).length ) {

        let byPlayer = []; // each player with their stats applied
        let fow = []; // fall of wicket with the player data bound to it

        if ( inningsData.scorecard.battingStats.length > 0 && battingTeam.squad.length > 0 ) {

            inningsData.scorecard.battingStats.forEach( scorecardPlayer => {

                const matchingPlayerData = battingTeam.squad.find( squadPlayer => squadPlayer.id === scorecardPlayer.playerId );
                if ( matchingPlayerData ) {
                    byPlayer.push( { info: matchingPlayerData, stats: scorecardPlayer } );
                }

            } );

            if ( inningsData.scorecard.fow && inningsData.scorecard.fow.length > 0 ) {

                inningsData.scorecard.fow.forEach( fwoPlayer => {

                    const matchingPlayerData = battingTeam.squad.find( squadPlayer => squadPlayer.id === fwoPlayer.playerId );
                    if ( matchingPlayerData ) {
                        fwoPlayer.player = matchingPlayerData;
                        fow.push( fwoPlayer );
                    }
                } );
            }
        }

        return {
            inningsNumber: inningsData.inningsNumber,
            runRate: inningsData.runRate,
            declared: inningsData.declared,
            runs: inningsData.scorecard.runs,
            ballsFaced: inningsData.scorecard.ballsFaced,
            fours: inningsData.scorecard.fours,
            sixes: inningsData.scorecard.sixes,
            wickets: inningsData.scorecard.wkts,
            fow: fow.sort( ( compareA, compareB ) => ( compareA.wicketNumber - compareB.wicketNumber ) ),
            allOut: inningsData.scorecard.allOut,
            noBallRuns: inningsData.scorecard.extras.noBallRuns,
            wideRuns: inningsData.scorecard.extras.wideRuns,
            byeRuns: inningsData.scorecard.extras.byeRuns,
            legByeRuns: inningsData.scorecard.extras.legByeRuns,
            penaltyRuns: inningsData.scorecard.extras.penaltyRuns,
            byPlayer
        };
    }

    return null;
};

/**
 * Models the passed innings data into just batting stats
 *
 * @param {number} playerPulseId - the Pulse ID of the player
 * @param {Array<object>} allBattingStats - the batting stats for an innings
 * @returns {object} modelled batting stats for an innings
 */
const getPlayerBattingStats = ( playerPulseId, allBattingStats ) => {
    return allBattingStats.find( playerStats => playerStats.id === playerPulseId ) || null;
};

/**
 * Models the passed innings data into just bowling stats
 *
 * @param {number} playerPulseId - the Pulse ID of the player
 * @param {Array<object>} allBowlingStats - the bowling stats for an innings
 * @param {string} key - the key of the player ID in the bowling stats array
 * @returns {object} modelled bowling stats for an innings
 */
const getPlayerBowlingStats = ( playerPulseId, allBowlingStats, key = 'id' ) => {
    return allBowlingStats.find( playerStats => playerStats[ key ] === playerPulseId ) || null;
};

import React, { useContext, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import {
    COMPONENT_REVEAL_TRANSITION_OPTIONS,
    MEASUREMENTS
} from 'scripts/constants';
import { toggleScorebox } from 'scripts/helpers';
import { useWindowWidth } from 'scripts/hooks';

import matchContext from 'scripts/context/match/matchContext';
import scoreboxContext from 'scripts/context/scorebox/scoreboxContext';

import LiveStreamVideo from 'scripts/components/common/LiveStreamVideo/LiveStreamVideo';
import PageHeader from 'scripts/components/common/Header/PageHeader';

/**
 * Screen - Live Stream
 * When the selected match has a live stream, this screen will be an option to navigate to
 *
 * @returns {object} React render
 */
function LiveStream() {

    const { match } = useContext( matchContext );
    const { scoreboxOpen, setScoreboxOpen } = useContext( scoreboxContext );

    const windowWidth = useWindowWidth();
    const [ isMobile, setIsMobile ] = useState( windowWidth < MEASUREMENTS.TABLET );

    useEffect( () => {
        if ( scoreboxOpen ) {
            toggleScorebox(); // on the Live Stream screen the scorebox is hidden
            setScoreboxOpen( false );
        }

        return () => { // unmount
            null;
        };
    }, [] ); // eslint-disable-line

    useEffect( () => {
        setIsMobile( windowWidth < MEASUREMENTS.TABLET );
    }, [ windowWidth ] );
    
    if ( match.liveStreamVideo === null ) {
        return null;
    }
    
    return (
        <CSSTransition { ...COMPONENT_REVEAL_TRANSITION_OPTIONS }>
            {
                isMobile ? (
                    <div className="wrapper">
                        <PageHeader className="page-header--live-stream" title={ 'No Live Stream' } description={ 'For the latest updates for this match, tap LATEST.' }/>
                    </div>
                ) : (
                    <div className="live-stream reveal-component">
                        <LiveStreamVideo liveStreamVideo={ match.liveStreamVideo }/>
                    </div>
                )
            }
        </CSSTransition>
    );
}

export default LiveStream;


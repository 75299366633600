/**
 * Triggers sharing the page on social with a custom message
 *
 * @param {string} serviceName - the name of the social service to share on
 * @param {string} sharePath - the page pathname to use to create a sharing URL
 * @param {string} shareMessage - the accompanying message with the share
 * @param {object} windowConfiguration - a custom configuration for the sharing window
 */
export function shareOnSocial( serviceName, sharePath, shareMessage, windowConfiguration = {} ) {

    const { domain } = window.environment;

    const SOCIAL_LINKS = {
        twitter: { url: 'http://www.twitter.com/intent/tweet?text=' },
        facebook: { url: 'http://www.facebook.com/sharer/sharer.php?u=' },
        whatsapp: { url: 'whatsapp://send?text=' },
    };

    const DEFAULT_WINDOW_CONFIGURATION = { // eslint-disable-line id-length
        width: '500',
        height: '500',
        menubar: 0,
        location: 1,
        resizable: 1,
        scrollbars: 0,
        status: 0,
        titlebar: 0,
        toolbar: 0
    };

    /**
     * Get page title
     *
     * @returns { HTMLElement } return selected element or null
     */
    const getPageTitle = () => {
        const pageTitleEle = document.querySelector( 'title' );
        return pageTitleEle.innerText;
    };

    /**
     * Share a page url or the current page url ( if no url passed ) to the social
     * media site with which the instance was created
     *
     * @returns {string} page url
     */
    const buildShareUrl = () => {
        const url = `https://${ domain }${ sharePath }` || `https://${ domain }`;
        const pageTitle = getPageTitle();
        const network = SOCIAL_LINKS[ serviceName ].url;

        switch ( serviceName ) {
            case 'facebook':
                return network + encodeURIComponent( url );
            case 'twitter':
            case 'whatsapp':
                if ( shareMessage ) {
                    return network + encodeURIComponent( `${ shareMessage } via ${ url }` );
                } else if ( pageTitle ) {
                    return network + encodeURIComponent( `${ pageTitle } via ${ url }` );
                }
                return network + encodeURIComponent( `${ url } via` );
            default:
                if ( pageTitle ) {
                    return network + encodeURIComponent( `${ pageTitle } via ${ url }` );
                }
                return network + encodeURIComponent( `${ shareMessage } via ${ url }` );
        }
    };

    /**
     * Creates a string representation of the configuration object provided so
     * it can be used in the call to window.open, for example;
     *
     * "menubar=no,location=yes,resizable=yes,scrollbars=yes,status=yes"
     *
     * @returns {string} comma separated list of configuration parameters
     */
    const makeWindowConfigString = () => {
        const config = windowConfiguration || DEFAULT_WINDOW_CONFIGURATION;
        const settings = Object.keys( config );
        return settings.reduce( ( acc, cur ) => ( acc += `${ cur }=${ config[ cur ] },` ), [] ); // eslint-disable-line no-param-reassign
    };

    /**
     * Create a share url for the service with which the instance was created and open a
     * new window using the parameters provided, or the defaults.
     */
    const sharePage = () => {
        const url = buildShareUrl();
        const config = makeWindowConfigString();
        window.open( url, 'shareWindow', config );
    };

    sharePage();
}

/**
 * Creates a script to be appended to the document AFTER the tweet has been rendered
 * Should be used within useEffect to ensure tweets have rendered
 */
export const initialiseTwitterEmbeds = () => {
    const script = document.createElement( 'script' );
    script.src = 'https://platform.twitter.com/widgets.js';
    document.body.appendChild( script );
};

/**
 * Creates a script to be appended to the document AFTER the tweet has been rendered
 * Should be used within useEffect to ensure tweets have rendered
 */
export const initialiseInstagramEmbeds = () => {
    const script = document.createElement( 'script' );
    script.src = 'https://www.instagram.com/embed.js';
    document.body.appendChild( script );
};

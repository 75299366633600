import React, { useContext } from 'react';

import { splitTeamName } from 'scripts/helpers';
import { en } from 'scripts/translations';

import matchContext from 'scripts/context/match/matchContext';

import TeamBadge from 'scripts/components/common/TeamBadge/TeamBadge';
import Countdown from 'scripts/components/common/Countdown/Countdown';

/**
 * Scorebox used when the match has no state, acting as a dummy display
 *
 * @returns {object} React render
 */
export default function Scorebox() {

    const { match } = useContext( matchContext );
    const { fixtureNoScoring } = match;

    if ( !fixtureNoScoring ) { // no match info and also no succesful fixtures/id endpoint request

        return (
            <div className="scorebox__main">
                <div className="scorebox__inner-container scorebox__inner-container--pre">
                    <div className="scorebox__team-container scorebox__team-container--home">
                        <span className="scorebox__team-name">
                            { en.home } { en.team }
                        </span>
                        <TeamBadge
                            abbr={ null }
                            className="scorebox__team-badge u-hide-tablet"
                            type="matchListLight" />
                        <TeamBadge
                            abbr={ null }
                            className="scorebox__team-badge u-show-tablet"
                            type="matchListDark" />
                    </div>
                    <div className="scorebox__scores">
                        <div className="scorebox__versus">
                            <span className="scorebox__versus-text">{ en.vs }</span>
                        </div>
                    </div>
                    <div className="scorebox__team-container scorebox__team-container--away">
                        <TeamBadge
                            abbr={ null }
                            className="scorebox__team-badge u-hide-tablet"
                            type="matchListLight" />
                        <TeamBadge
                            abbr={ null }
                            className="scorebox__team-badge u-show-tablet"
                            type="matchListDark" />
                        <span className="scorebox__team-name">
                            { en.away } { en.team }
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="scorebox__main">
            <div className="scorebox__inner-container scorebox__inner-container--pre">
                <div className="scorebox__team-container scorebox__team-container--home">
                    <span className="scorebox__team-name" dangerouslySetInnerHTML={ splitTeamName( fixtureNoScoring.teams.home.shortName ) } />
                    <TeamBadge abbr={ fixtureNoScoring.teams.home.abbreviation } className="scorebox__team-badge u-hide-tablet" type="matchListLight" />
                    <TeamBadge abbr={ fixtureNoScoring.teams.home.abbreviation } className="scorebox__team-badge u-show-tablet" type="matchListDark" />
                </div>
                <div className="scorebox__countdown">
                    <span className="scorebox__countdown-label">{ en.match } { en.countdown }</span>
                    <div className="scorebox-countdown-container">
                        <Countdown datetime={ fixtureNoScoring.info.startTime } />
                    </div>
                </div>
                <div className="scorebox__team-container scorebox__team-container--away">
                    <TeamBadge abbr={ fixtureNoScoring.teams.away.abbreviation } className="scorebox__team-badge u-hide-tablet" type="matchListLight" />
                    <TeamBadge abbr={ fixtureNoScoring.teams.away.abbreviation } className="scorebox__team-badge u-show-tablet" type="matchListDark" />
                    <span className="scorebox__team-name" dangerouslySetInnerHTML={ splitTeamName( fixtureNoScoring.teams.away.shortName ) } />
                </div>
            </div>
        </div>
    );
}
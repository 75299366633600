import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ScoreboxContext from './scoreboxContext';

const ScoreboxState = props => {

    const [ scoreboxOpen, setScoreboxOpen ] = useState( true );

    return (
        <ScoreboxContext.Provider
            value={ {
                scoreboxOpen: scoreboxOpen,
                setScoreboxOpen,
            } }>
            { props.children }
        </ScoreboxContext.Provider>
    );
};

ScoreboxState.propTypes = {
    children: PropTypes.node,
};

export default ScoreboxState;
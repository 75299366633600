import React, { useContext } from 'react';
import { CSSTransition } from 'react-transition-group';

import matchContext from 'scripts/context/match/matchContext';

import { COMPONENT_REVEAL_TRANSITION_OPTIONS } from 'scripts/constants';
import { en } from 'scripts/translations';

import PreMatchPromos from './PreMatchPromos';

import { ReactComponent as Chevrons } from 'i/chevrons/pre-match.svg';
import { ReactComponent as MobileChevrons } from 'i/chevrons/pre-match-mobile.svg';

/**
 * Match info available before it begins
 *
 * @returns {object} React render
 */
export default function PreMatch() {

    const { match } = useContext( matchContext );
    const { teams, info } = match;

    return (
        <CSSTransition { ...COMPONENT_REVEAL_TRANSITION_OPTIONS }>
            <section className="pre-match reveal-component mobile-navigation-offset">
                <Chevrons className="pre-match__chevron u-hide-tablet"/>
                <div className="wrapper">
                    <div className="pre-match__main">
                        <MobileChevrons className="pre-match__chevron u-show-tablet"/>
                        <h3 className="pre-match__subtitle">{ en.preMatch }</h3>
                        <h1 className="pre-match__title">
                            <span>{ teams.home.shortName }<span className="pre-match__vs">vs</span></span>
                            <span>{ teams.away.shortName }</span>
                        </h1>
                        <div className="pre-match__info-container">
                            <p className="pre-match__info">
                                <b>{ en.venue }: </b>
                                <span className="pre-match__team">{ info.venue }</span>
                            </p>
                            <p className="pre-match__info">
                                <b>{ en.date }: </b>
                                <span className="pre-match__team">{ info.startTimeLong }</span>
                            </p>
                            {
                                info.tossWinner || info.tossText ? (
                                    <p className="pre-match__info">
                                        <b>{ en.toss }: </b>
                                        <span>{ info.tossText || info.tossWinner }</span>
                                    </p>
                                ) : (
                                    null
                                )
                            }
                        </div>
                        <div className="pre-match__info-container">
                            {
                                info.umpires.length > 0 &&
                                    <p className="pre-match__info">
                                        <b>{ en.officials }: </b>
                                        {
                                            info.umpires.map( ( umpire, i ) => {
                                                if ( i >= ( info.umpires.length - 1 ) ) {
                                                    return <span key={ i }>{ umpire }</span>;
                                                }
                                                return <span key={ i }>{ umpire }, </span>;
                                            } )
                                        }
                                    </p>
                            }
                            {
                                info.referee &&
                                    <p className="pre-match__info">
                                        <b>{ en.referee }: </b>
                                        <span>{ info.referee }</span>
                                    </p>
                            }
                        </div>

                        { match.metadata && match.metadata.status_text_override && (
                            <div className="pre-match__info-container">
                                <p className="pre-match__info">
                                    <b>{ match.metadata.status_text_override }</b>
                                </p>
                            </div>
                        ) }
                    </div>
                    <PreMatchPromos />
                </div>
            </section>
        </CSSTransition>
    );
}

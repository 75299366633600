/**
 * Takes a string and replaces the '|' pipes with highlight markup
 *
 * @param {string} string String that contains '|" characters to be formated
 * @returns {string} replaces piped text with highlight markup
 */
export const formatTitle = ( string ) => {

    const MAX_PIPES = 2;
    const STRING_START_INDEX = 0;
    const STRING_HIGHLIGHT_INDEX = 1;
    const STRING_END_INDEX = 2;
    
    const regex = /\|/g;
    const match = string.match( regex );
    let cleanString = string;

    // if no pipes or more than two pipes, return initial string
    if ( !match || match.length > MAX_PIPES ) { return string; }

    // If only one pipeline, add a pipe at the end
    if ( match.length === 1 ) {
        cleanString = `${cleanString}|`;
    }

    // Split string based on pipes
    const splitString = cleanString.split( regex );

    // Wrap text that was between pipes in the highlight markup
    const highlight = `<span class="highlight">${ splitString[ STRING_HIGHLIGHT_INDEX ] }</span>`;
    
    // Concatenate highlight with start of string
    const highlightString = `${ splitString[ STRING_START_INDEX ] }${ highlight }${ splitString[ STRING_END_INDEX ] }`;

    return { __html: highlightString }; // this object format is used to parse HTML in React
};

/**
 * Takes a team name and splits it by the space and surrounds the second part with a <span>
 *
 * @param {string} teamName - team name
 * @returns {string} Splits team name into 2 with span wrapped around
 */
export const splitTeamName = ( teamName ) => {

    if ( !teamName ) {
        return null;
    }
    
    const [ firstName, secondName ] = teamName.split( ' ' );
    const highlightString = `${ firstName }<span class="highlight">${ secondName }</span>`; // concatenate highlight with start of teamName
    return { __html: highlightString };
};

/**
 * Prepends the given value with enough '0's to make a 3 character number as a string
 * If a newOver is required, wrap the additional '0's in a span for styling
 *
 * @param {number} value - number to check if zeros need to be prepended to
 * @param {number} digits - the number of total digits to display, 2 or 3
 * @param {boolean} newOver - determine whether to stick in span for design
 * @param {string} newOverClassName - if a new over is required this is the class for the wrapping span
 * @returns {object} react object to be rendered using dangerouslySetInnerHTML
 */
 export const prependZeroes = ( value, digits = 3, newOver = false, newOverClassName ) => { // eslint-disable-line

    let prepended = '';

    if ( value < 0 ) {
        if ( newOver ) {
            prepended = `<span class="${ newOverClassName }">${ digits === 2 ? '00' : '000' }</span>`;
        } else {
            prepended = digits === 2 ? '00' : '000';
        }
    } else {

        let toPrepend = '';

        if ( digits === 2 && /^\d{1}$/.test( value ) ) {
            toPrepend = '0';
        } else if ( /^\d{2}$/.test( value ) ) {
            toPrepend = '0';
        } else if ( /^\d{1}$/.test( value ) ) {
            toPrepend = '00';
        }

        prepended = toPrepend !== '' ? `${ toPrepend }${ value }` : value;

        if ( newOver ) {
            prepended = `<span class="${ newOverClassName }">${ toPrepend }</span>${ value }`;
        }
    }

    return { __html: prepended };
};

/**
 * Splits the player name into an object contain first name and second names
 * Splits on the space character
 *
 * @param {string} playerFullName - the player's full name
 * @returns {object} an object of a player's first and second name
 */
export const splitPlayerName = ( playerFullName ) => {
    const [ firstName, lastName ] = playerFullName.split( / (.+)/ );
    return {
        first: firstName,
        last: lastName
    };
};

/**
 * Gets the word equivalent to the passed number's ordinal
 * Assumes no more than 20 are needed
 *
 * @param {number} cardinal - a cardinal number used to look up its ordinal by index
 * @returns {string} the word ordinal for the passed cardinal
 */
export const getWordOrdinal = ( cardinal ) => {

    const ordinals = [
        null,
        'first',
        'second',
        'third',
        'fourth',
        'fifth',
        'sixth',
        'seventh',
        'eighth',
        'ninth',
        'tenth',
        'eleventh',
        'twelfth',
        'thirteenth',
        'fourtheenth',
        'fifteenth',
        'sixteenth',
        'seventeenth',
        'eighteenth',
        'nineteenth',
        'twentieth'
    ];
    
    return ordinals[ cardinal ];
};
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { useInView } from 'react-intersection-observer';

import { ReactComponent as Chevrons } from 'i/chevrons/latest-feed-card.svg';
import { ReactComponent as MobileChevrons } from 'i/chevrons/pre-match-mobile.svg';

/**
 * A Generic Latest Feed card that is not themed to a team
 *
 * @param {object} props - properties passed to the component
 * @param {Array<object>} props.children - React child elements
 * @param {number} props.cardNumber - the number this card appears in the feed
 * @param {string} props.feedPosition - the position the card is in the feed, e.g. 'primary'
 * @param {Function} props.updateFeedPosition - function used to update the feed position
 * @param {boolean} props.isMobile - true if we are currently on a mobile device
 * @returns {object} React render
 */
function GenericCard( { children, cardNumber, feedPosition, updateFeedPosition, isMobile } ) {

    let positionRef = useRef( null );

    const INTERSECTION_THRESHOLD = isMobile ? 0.1 : 0.02; // eslint-disable-line no-magic-numbers
    const [ intersectionRef, inView ] = useInView( {
        threshold: INTERSECTION_THRESHOLD
    } );

    const REVEAL_CARD_TRANSITION_OPTIONS = { // eslint-disable-line id-length
        'in': true,
        'appear': true,
        'timeout': 0,
        'classNames': 'generic-card__loaded'
    };

    useEffect( () => {

        if ( isMobile && positionRef.current && ( cardNumber === feedPosition ) ) {
            const scrollOffset = window.innerHeight / 2;
            const elementRect = positionRef.current.getBoundingClientRect();
            const elementScrollYOffset = elementRect.top - scrollOffset;
            window.scrollTo( { top: elementScrollYOffset } );
        }

        // eslint-disable-next-line
    }, [] );

    return (
        <article
            className="latest-feed__card generic-card"
            onClick={ isMobile ? null : () => updateFeedPosition( cardNumber ) }
            ref={ intersectionRef }>
            <div className="latest-feed__card-inner" ref={ positionRef }>
                {
                    inView ? (
                        <CSSTransition { ...REVEAL_CARD_TRANSITION_OPTIONS }>
                            <div
                                className="generic-card__loaded"
                                onLoad={ isMobile ? () => updateFeedPosition( cardNumber ) : null }>
                                {
                                    isMobile ? (
                                        <MobileChevrons className="generic-card__chevrons generic-card__chevrons--mobile u-show-tablet"/>
                                    ) : (
                                        <Chevrons className="generic-card__chevrons generic-card__chevrons--top u-hide-tablet"/>
                                    )
                                }
                                { children }
                                { /* <Chevrons className="generic-card__chevrons generic-card__chevrons--bottom u-hide-tablet"/> */ }
                            </div>
                        </CSSTransition>
                    ) : (
                        null
                    )
                }
            </div>
        </article>
    );
}

GenericCard.propTypes = {
    children: PropTypes.node.isRequired,
    cardNumber: PropTypes.number.isRequired,
    feedPosition: PropTypes.number.isRequired,
    updateFeedPosition: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired
};

export default GenericCard;

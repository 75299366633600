import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as LoaderIcon } from 'i/icons/loader.svg';

/**
 * Spinner used to denote something is loading
 *
 * @param {object} props - properties passed to the component
 * @param {string} props.className - additional CSS className for the component
 * @param {string} props.modifier - additional BEM modifying class
 * @returns {object} React render
 */
function Loader( { className, modifier } ) {

    return (
        <div className={ `${ className || '' } loader ${ modifier || '' }` }>
            <LoaderIcon className="loader__icon" />
            <span className="loader__text">Loading...</span>
        </div>
    );
}

Loader.propTypes = {
    className: PropTypes.string,
    modifier: PropTypes.string
};

export default Loader;

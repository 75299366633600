import React, { useContext, useEffect, useState } from 'react';
import { Switch, Route, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// import { DFPSlotsProvider } from 'react-dfp';

import { MEASUREMENTS } from 'scripts/constants';
import { useInterval, useWindowWidth } from 'scripts/hooks';
import { getPollInterval } from 'scripts/helpers';
import { en } from 'scripts/translations';

import matchContext from 'scripts/context/match/matchContext';

import ScoreboxState from 'scripts/context/scorebox/ScoreboxState';

import MatchHeader from './MatchHeader';

import PageHeader from 'scripts/components/common/Header/PageHeader';
import Loader from 'scripts/components/common/Loader/Loader';
import Navigation from 'scripts/components/common/Navigation/Navigation';
import LiveStreamVideo from 'scripts/components/common/LiveStreamVideo/LiveStreamVideo';
import Scorebox from 'scripts/components/common/Scorebox/Scorebox';
// import Advert from 'scripts/components/common/Advert/Advert';
import DummyDataToggle from 'scripts/components/common/DummyDataToggle/DummyDataToggle';

import LiveStream from 'scripts/components/screens/LiveStream/LiveStream';
import Latest from 'scripts/components/screens/Latest/Latest';
import Scorecard from 'scripts/components/screens/Scorecard/Scorecard';
import BallByBall from 'scripts/components/screens/BallByBall/BallByBall';
import Squads from 'scripts/components/screens/Squads/Squads';

/**
 * Routing for a match using the match context provided from the parent MatchWrapper
 *
 * @returns {object} React render
 */
function Match() {

    const { matchId } = useParams();

    const { match, getMatch, loading } = useContext( matchContext );

    const [ dummyDataPhase, setDummyDataPhase ] = useState( null ); // TEMP - only used for developing against dummy match data

    const windowWidth = useWindowWidth();
    const [ isMobile, setIsMobile ] = useState( windowWidth < MEASUREMENTS.TABLET );

    // const [ adDismissed, setAdDismissed ] = useState( false );

    /* eslint-disable react/display-name */
    const routes = [
        {
            path: '/:matchId/latest',
            label: 'Latest',
            renderComponent: () => <Latest /> // eslint-disable-line react/display-name
        },
        {
            path: '/:matchId/live-stream',
            label: 'Live Stream',
            renderComponent: () => <LiveStream /> // eslint-disable-line react/display-name
        },
        {
            path: '/:matchId/scorecard',
            label: 'Scorecard',
            renderComponent: () => <Scorecard /> // eslint-disable-line react/display-name
        },
        {
            path: '/:matchId/ball-by-ball',
            label: 'Ball-by-Ball',
            renderComponent: () => <BallByBall dummyDataPhase={ dummyDataPhase } /> // eslint-disable-line react/display-name
        },
        {
            path: '/:matchId/squads',
            label: 'Squads',
            renderComponent: () => <Squads /> // eslint-disable-line react/display-name
        },
        {
            path: '/:matchId',
            label: 'Latest',
            renderComponent: () => <Latest /> // eslint-disable-line react/display-name
        }
    ];
    /* eslint-enable react/display-name */

    /**
     * Set the title of the page based on match info
     *
     * @returns {object} a Helmet component used to change the page title
     */
    const setPageTitle = () => {

        if ( match && match.teams && Object.keys( match.teams ).length > 0 ) {
            return (
                <Helmet>
                    <title>
                        { match.teams.home.fullName } { en.vsShort } { match.teams.away.fullName } { match.info && match.info.venue ? `at ${ match.info.venue }` : '' }
                    </title>
                </Helmet>
            );
        } else if ( match && match.fixtureNoScoring ) {
            return (
                <Helmet>
                    <title>
                        { match.fixtureNoScoring.teams.home.fullName } { en.vsShort } { match.fixtureNoScoring.teams.away.fullName } { match.fixtureNoScoring.info.venue ? `at ${ match.fixtureNoScoring.info.venue }` : '' }
                    </title>
                </Helmet>
            );
        }

        return null;
    };

    // const renderAds = () => {

    //     if ( !adDismissed && isMobile ) {
    //         return <Advert
    //             adId="div-gpt-ad-1616615353814-0"
    //             adSlot="/22299845211/The_Hundred_TEST_Match_Centre_Mobile_PopUp_300x100"
    //             adWidth={ 300 }
    //             adHeight={ 100 }
    //             setAdDismissed={ setAdDismissed }
    //             className="u-show-tablet" />;
    //     } else if ( !adDismissed ) {
    //         return <Advert
    //             adId="div-gpt-ad-1616615233392-0"
    //             adSlot="/22299845211/The_Hundred_TEST_Match_Centre_Desktop_PopUp_336x280"
    //             adWidth={ 336 }
    //             adHeight={ 280 }
    //             setAdDismissed={ setAdDismissed }
    //             className="u-hide-tablet" />;
    //     }

    //     return null;
    // };

    const renderDummyDataToggle = () => {
        if ( process.env.NODE_ENV === 'development' ) {
            return (
                <DummyDataToggle dummyDataPhase={ dummyDataPhase } setDummyDataPhase={ setDummyDataPhase } />
            );
        }

        return null;
    };

    useEffect( () => {
        getMatch( matchId, dummyDataPhase );
    }, [ matchId, dummyDataPhase ] );
    
    useEffect( () => {
        setIsMobile( windowWidth < MEASUREMENTS.TABLET );
    }, [ windowWidth ] );

    useInterval( () => {
        getMatch( matchId, dummyDataPhase );
    }, getPollInterval( match ) );

    if ( !match || loading ) {
        return <Loader className="app__loader"/>;
    }

    if ( match !== null && match.scorecard !== null && match.scorecard === {} && !loading ) {
        return (
            <div className="wrapper">
                <PageHeader title={ `${ en.no } ${ en.scoring }` } description={ en.checkBack }/>
            </div>
        );
    }

    if ( process.env.NODE_ENV === 'development' ) {
        console.log( '%c%s', 'color: cyan; background: darkslategray;', 'MATCH Context:', match ); // eslint-disable-line no-console
    }

    return (
        <div className="app">
            { setPageTitle() }
            <div className="app__navigation">
                <Navigation />
            </div>
            <ScoreboxState>
                <main className="app__main">
                    <MatchHeader />
                    <div className="app__fixed-header js-scorebox-container">
                        {
                            match.liveStreamVideo && isMobile &&
                                <LiveStreamVideo liveStreamVideo={ match.liveStreamVideo }/>
                        }
                        <Scorebox />
                    </div>
                    <Switch>
                        {
                            routes.map( ( route, i ) => (
                                <Route
                                    path={ route.path }
                                    exact={ route.exact }
                                    render={ () => route.renderComponent() }
                                    key={ i }>
                                </Route>
                            ) )
                        }
                    </Switch>
                </main>
            </ScoreboxState>
            { /* <DFPSlotsProvider dfpNetworkId="22299845211">
                { renderAds() }
            </DFPSlotsProvider> */ }
            { renderDummyDataToggle() }
        </div>
    );
}

export default Match;
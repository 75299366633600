import React from 'react';
import PropTypes from 'prop-types';

import { prependZeroes } from 'scripts/helpers';
import { en } from 'scripts/translations';

import TeamBadge from 'scripts/components/common/TeamBadge/TeamBadge';

/**
 * Dividing header for innings in the Ball By Ball table
 *
 * @param {object} props - properties passed to the component
 * @param {object} props.battingTeamInnings - info on the batting team to display in the header
 * @param {string} props.title - title text
 * @param {boolean} props.isComplete - set to true if this innings is complete
 * @returns {object} React render
 */
function InningsHeader( { battingTeamInnings, title, isComplete } ) {

    return (
        <div className="balls-by-over__innings">
            <h3 className="balls-by-over__innings-title">{ title }</h3>
            <div className="balls-by-over__innings-team">
                <TeamBadge
                    abbr={ battingTeamInnings.team.abbr }
                    className="balls-by-over__innings-team-badge"
                    type="matchListDark" />
                <span className="balls-by-over__innings-team-name" title={ battingTeamInnings.team.shortName }>{ battingTeamInnings.team.shortName }</span>
                <div className="balls-by-over__innings-team-score">
                    <span className="balls-by-over__innings-team-balls">
                        {
                            battingTeamInnings.stats.ballsFaced ? (
                                `${ battingTeamInnings.stats.ballsFaced } ${ en.balls }`
                            ) : (
                                `0 ${ en.balls }`
                            )
                        }
                    </span>
                    {
                        battingTeamInnings.stats.runs ? (
                            <span className="balls-by-over__innings-team-runs" dangerouslySetInnerHTML={ prependZeroes( battingTeamInnings.stats.runs, true, 'balls-by-over__innings-team-runs--zero' ) }/>
                        ) : (
                            <span className="balls-by-over__innings-team-runs"><span className="balls-by-over__innings-team-runs--zero">000</span></span>
                        )
                    }
                    {
                        isComplete &&
                            <span className="balls-by-over__complete">{ en.complete }</span>
                    }
                </div>
            </div>
        </div>
    );
}

InningsHeader.propTypes = {
    battingTeamInnings: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    isComplete: PropTypes.bool
};

export default InningsHeader;
import React from 'react';
import PropTypes from 'prop-types';

import { BALLS_PER_OVER } from 'scripts/constants';

/**
 * A singular ball in the Ball By Ball feed, displaying the score and commentary in a tooltip
 *
 * @param {object} props - properties passed to the component
 * @param {object} props.ball - representative data of a ball that has occured in the over
 * @param {number} props.idx - the index of the ball as it appears in the over
 * @returns {object} React render
 */
function Ball( { ball, idx } ) {

    const getTooltipModifier = () => {

        const modulus = idx % BALLS_PER_OVER;
        if ( modulus < 2 ) {
            return 'ball__tooltip--left tooltip--left';
        } else if ( modulus > 2 ) {
            return 'ball__tooltip--right tooltip--right';
        }
        return '';
    };

    return (
        <div className={ `ball ball--${ ball.score }${ ball.isWicket ? ' ball--wicket' : '' }` } tabIndex="0">
            <div className="ball__ball">
                <span className="ball__score">{ ball.score }</span>
            </div>
            { /* <span className="ball__number">{ ball.number }</span> */ }
            <span className={ `ball__tooltip tooltip ${ getTooltipModifier() }` }>{ ball.message }</span>
        </div>
    );
}

Ball.propTypes = {
    ball: PropTypes.object.isRequired,
    idx: PropTypes.number // the index at which this ball appears in the over
};

export default Ball;
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

/**
 * A team-themed Latest Feed card
 *
 * @param {object} props - properties passed to the component
 * @param {Array<object>} props.children - React child elements
 * @param {number} props.cardNumber - the number this card appears in the feed
 * @param {string} props.feedPosition - the position the card is in the feed, e.g. 'primary'
 * @param {object} props.team - team object
 * @param {Function} props.updateFeedPosition - function used to update the feed position
 * @param {boolean} props.isMobile - true if we are currently on a mobile device
 * @returns {object} React render
 */
function TeamCard( { children, cardNumber, feedPosition, team, updateFeedPosition, isMobile } ) {

    let positionRef = useRef( null );

    const INTERSECTION_THRESHOLD = isMobile ? 0.1 : 0.02; // eslint-disable-line no-magic-numbers
    const [ intersectionRef, inView ] = useInView( {
        threshold: INTERSECTION_THRESHOLD
    } );

    useEffect( () => {
        if ( isMobile && positionRef.current && ( cardNumber === feedPosition ) ) {
            const scrollOffset = window.innerHeight / 2;
            const elementRect = positionRef.current.getBoundingClientRect();
            const elementScrollYOffset = elementRect.top - scrollOffset;
            window.scrollTo( { top: elementScrollYOffset } );
        }
    }, [] ); // eslint-disable-line

    return (
        <article
            className={ `latest-feed__card team-card t-team-theme t-${ team.className }` }
            onClick={ isMobile ? null : () => updateFeedPosition( cardNumber ) }
            ref={ intersectionRef }>
            <div className="latest-feed__card-inner" ref={ positionRef }>
                <div
                    className={ `team-card__loaded ${ inView ? 'team-card__loaded--in-view' : '' }` }
                    onLoad={ isMobile ? () => updateFeedPosition( cardNumber ) : null }>
                    { children }
                </div>
            </div>
        </article>
    );
}

TeamCard.propTypes = {
    children: PropTypes.node.isRequired,
    cardNumber: PropTypes.number.isRequired,
    feedPosition: PropTypes.number.isRequired,
    team: PropTypes.object.isRequired,
    updateFeedPosition: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired
};

export default TeamCard;

import { useEffect, useRef } from 'react';
import { PRE_POLL_INTERVAL } from '../constants';

/**
 * Creates a React Hook to manage setting intervals
 *
 * @param {Function} callback - a function to use as a callback on each interval
 * @param {number} delay - the interval delay in ms
 */
export function useInterval( callback, delay = PRE_POLL_INTERVAL ) {

    const savedCallback = useRef();

    // remember the latest callback.
    useEffect( () => {
        savedCallback.current = callback;
    }, [ callback ] );

    // set up the interval
    useEffect( () => { // eslint-disable-line consistent-return
        function tick() { // eslint-disable-line jsdoc/require-jsdoc
            savedCallback.current();
        }
        if ( delay !== null ) {
            let id = setInterval( tick, delay );
            return () => clearInterval( id );
        }
    }, [ delay ] );
}
import React, { useContext, useEffect } from 'react';

import { toggleScorebox } from 'scripts/helpers';
import { en } from 'scripts/translations';

import matchContext from 'scripts/context/match/matchContext';
import scoreboxContext from 'scripts/context/scorebox/scoreboxContext';

import PreMatch from './preMatch/PreMatch';
import LatestFeed from './liveMatch/LatestFeed/Feed';
import PostMatch from './postMatch/PostMatch';

import PageHeader from 'scripts/components/common/Header/PageHeader';

/**
 * Screen - Latest
 * Pre-match display with promos if the match has not started
 * Latest Feed if the match is live
 * Match Report if match has finished
 *
 * @returns {object} React render
 */
function Latest() {

    const { match } = useContext( matchContext );
    const { info } = match;
    const { scoreboxOpen, setScoreboxOpen } = useContext( scoreboxContext );
    
    const renderMatchState = () => {
        switch ( info.state ) {
            case 'U':
                return <PreMatch />;
            case 'L':
                return <LatestFeed />;
            case 'C':
                return <PostMatch />;
            default:
                console.warn( `Match state is not recognised when attempting to render the "Latest" screen: ${ info.state }` );
                return null;
        }
    };

    useEffect( () => {
        if ( !scoreboxOpen ) {
            toggleScorebox(); // ensure the scorebox is open
            setScoreboxOpen( true );
        }
    }, [] ); // eslint-disable-line

    if ( !Object.keys( info ).length > 0 ) {
        return (
            <div className="wrapper">
                <PageHeader title={ `${ en.coverage } ${ en.willAppearHere }` } description={ en.checkBack }/>
            </div>
        );
    }

    return (
        <div className="latest">
            { renderMatchState( info.state ) }
        </div>
    );
}

export default Latest;

import React, { useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { STYLE_CLS_NAMES } from 'scripts/constants';
import { shareOnSocial } from 'scripts/helpers';
import { en } from 'scripts/translations';

import { ReactComponent as ShareIcon } from 'i/icons/share.svg';
import { ReactComponent as TwitterIcon } from 'i/icons/twitter.svg';
import { ReactComponent as FacebookIcon } from 'i/icons/facebook.svg';
import { ReactComponent as WhatsAppIcon } from 'i/icons/whatsapp.svg';

/**
 * Provides sharing options for Twiiter, Facebook and Whatsapp
 *
 * @param {object} props - properties passed to the component
 * @param {string} props.className - additional CSS class for the component
 * @param {string} props.shareMessage - text that displays when sharing
 * @returns {object} React render
 */
function SocialShare( { className, shareMessage } ) {

    let location = useLocation();
    const [ socialRevealed, toggleSocialReveal ] = useState( false );

    const toggleSocialMenu = useCallback( () => {
        if ( socialRevealed ) {
            toggleSocialReveal( false );
        } else {
            toggleSocialReveal( true );
        }
    }, [ socialRevealed ] );

    const share = socialService => {
        shareOnSocial( socialService, location.pathname, shareMessage );
    };
    
    return (
        <div className={ `social-share ${ socialRevealed ? STYLE_CLS_NAMES.ANIMATE_IN : STYLE_CLS_NAMES.ANIMATE_OUT } ${ className || '' }` }>
            <button className="social-share__toggle js-social-toggle u-show-desktop" aria-haspopup="true" onClick={ () => toggleSocialMenu() }>
                <ShareIcon className="icon"/>
            </button>
            <ul className="social-share__options">
                <li className="social-share__option">
                    <button className="social-share__button social-share__button--twitter" onClick={ () => share( 'twitter' ) }>
                        <div className="social-share__button-content" aria-hidden="true">
                            <TwitterIcon className="icon"/>
                        </div>
                        <div className="social-share__button-content social-share__button-content--mask u-hide-desktop" aria-hidden="true">
                            <TwitterIcon className="icon"/>
                        </div>
                        <span className="u-screen-reader">{ en.shareThisPageOn } Twitter</span>
                    </button>
                </li>
                <li className="social-share__option">
                    <button className="social-share__button social-share__button--facebook" onClick={ () => share( 'facebook' ) }>
                        <div className="social-share__button-content" aria-hidden="true">
                            <FacebookIcon className="icon"/>
                        </div>
                        <div className="social-share__button-content social-share__button-content--mask u-hide-desktop" aria-hidden="true">
                            <FacebookIcon className="icon"/>
                        </div>
                        <span className="u-screen-reader">{ en.shareThisPageOn } Facebook</span>
                    </button>
                </li>
                <li className="social-share__option">
                    <button className="social-share__button social-share__button--whatsapp" onClick={ () => share( 'whatsapp' ) }>
                        <div className="social-share__button-content" aria-hidden="true">
                            <WhatsAppIcon className="icon"/>
                        </div>
                        <div className="social-share__button-content social-share__button-content--mask u-hide-desktop" aria-hidden="true">
                            <WhatsAppIcon className="icon"/>
                        </div>
                        <span className="u-screen-reader">{ en.shareThisPageOn } Whatsapp</span>
                    </button>
                </li>
            </ul>
        </div>
    );
}

SocialShare.propTypes = {
    className: PropTypes.string,
    shareMessage: PropTypes.string
};

export default SocialShare;
    
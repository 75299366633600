import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { urlify } from 'scripts/helpers';

import { ReactComponent as ExternalIcon } from 'i/icons/external.svg';
import { ReactComponent as ArrowUpIcon } from 'i/icons/arrow-up.svg';

/**
 * Individual nav item component
 *
 * @param {object} props - properties passed to the component
 * @param {object} props.route - route object for this navigation item
 * @returns {object} React render
 */
function NavigationItem( { route } ) {

    const routeClassName = urlify( route.label );

    const itemCls = classNames( 'navigation__item', `navigation__item--${ routeClassName }`, {
        'u-hide-tablet': route.hideOnMobile,
        'navigation__item--disabled': !route.enabled
    } );

    if ( route.external ) {
        return (
            <li className={ itemCls }>
                <a className="navigation__link navigation__link--external" href={ `${ route.path }` } target="_blank" rel="noopener noreferrer">
                    <span className="navigation__link-icon-container">
                        { route.icon }
                        <ExternalIcon className="navigation__link-icon navigation__link-icon--external"/>
                    </span>
                    <span className="navigation__link-text">
                        { route.label }
                    </span>
                </a>
            </li>
        );
    }

    if ( !route.enabled ) {
        return (
            <li className={ itemCls }>
                <div className="navigation__link">
                    {
                        routeClassName === 'ball-by-ball' ? (
                            <>
                                <div className="navigation__link-orbit-container">
                                    <span className="navigation__link-orbit"></span>
                                    { route.icon }
                                </div>
                                <span className="navigation__link-icon-container">
                                    <ArrowUpIcon className="navigation__link-icon navigation__link-icon--arrow u-show-tablet"/>
                                </span>
                            </>
                        ) : (
                            <span className="navigation__link-icon-container">
                                { route.icon }
                            </span>
                        )
                    }
                    <span className="navigation__link-text">
                        { route.label }
                    </span>
                </div>
            </li>
        );
    }

    return (
        <li className={ itemCls }>
            <NavLink
                to={ route.path }
                className="navigation__link"
                isActive={ match => match && match.url }
                activeClassName="navigation__link--selected">
                {
                    routeClassName === 'ball-by-ball' ? (
                        <>
                            <div className="navigation__link-orbit-container">
                                <span className="navigation__link-orbit"></span>
                                { route.icon }
                            </div>
                            <span className="navigation__link-icon-container">
                                <ArrowUpIcon className="navigation__link-icon navigation__link-icon--arrow u-show-tablet"/>
                            </span>
                        </>
                    ) : (
                        <span className="navigation__link-icon-container">
                            { route.icon }
                        </span>
                    )
                }
                <span className="navigation__link-text">
                    { route.label }
                </span>
            </NavLink>
        </li>
    );
}

NavigationItem.propTypes = {
    route: PropTypes.object
};
 
export default NavigationItem;
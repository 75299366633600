import React from 'react';
import PropTypes from 'prop-types';

import { en } from 'scripts/translations';

/**
 * Latest Feed Card for a pre match data
 *
 * @param {object} props - properties passed to the component
 * @param {object} props.teams - match teams
 * @param {object} props.info - match info
 * @returns {object} React render
 */
function PreMatch( { teams, info } ) {

    return (
        <div className="generic-card__content">
            <h1 className="latest-feed__card-title latest-feed__card-title--active">{ en.preMatch }</h1>
            <div className="pre-match pre-match--feed">
                <div className="pre-match__main">
                    <h1 className="pre-match__title">
                        <span>{ teams.home.shortName }<span className="pre-match__vs">{ en.vs }</span></span>
                        <span>{ teams.away.shortName }</span>
                    </h1>
                    <div className="pre-match__info-container">
                        <p className="pre-match__info">
                            <b>{ en.venue }: </b>
                            <span className="pre-match__team">{ info.venue }</span>
                        </p>
                        <p className="pre-match__info">
                            <b>{ en.date }: </b>
                            <span className="pre-match__team">{ info.startTimeLong }</span>
                        </p>
                        {
                            info.tossWinner || info.tossText ? (
                                <p className="pre-match__info">
                                    <b>{ en.toss }: </b>
                                    <span>{ info.tossText || info.tossWinner }</span>
                                </p>
                            ) : (
                                null
                            )
                        }
                    </div>
                    <div className="pre-match__info-container">
                        {
                            info.umpires.length > 0 &&
                                <p className="pre-match__info">
                                    <b>{ en.officials }: </b>
                                    {
                                        info.umpires.map( ( umpire, i ) => {
                                            if ( i >= ( info.umpires.length - 1 ) ) {
                                                return <span key={ i }>{ umpire }</span>;
                                            }
                                            return <span key={ i }>{ umpire }, </span>;
                                        } )
                                    }
                                </p>
                        }
                        {
                            info.referee &&
                                <p className="pre-match__info">
                                    <b>{ en.referee }: </b>
                                    <span>{ info.referee }</span>
                                </p>
                        }
                    </div>
                </div>
            </div>
            <h1 className="latest-feed__card-title latest-feed__card-title--inactive u-hide-tablet">{ en.preMatch }</h1>
        </div>
    );
}

PreMatch.propTypes = {
    teams: PropTypes.object,
    info: PropTypes.object
};

export default PreMatch;

import React from 'react';
import PropTypes from 'prop-types';

import PageHeader from 'scripts/components/common/Header/PageHeader';
import PlayerCard from 'scripts/components/common/PlayerCard/PlayerCard';

/**
 * List of players in a squad
 *
 * @param {object} props - properties passed to the component
 * @param {object} props.team - a team object that contains a squad array
 * @returns {object} React render
 */
function PlayerList( { team } ) {

    if ( !team || !team.squad || team.squad.length === 0 ) {
        return <PageHeader title={ `${ team.fullName } Squad has not yet been announced.` } description="Check back here later for more details."/>;
    }

    return (
        <div className="players-list">
            <ul className="players-list__grid">
                {
                    team.squad.map( ( player, i ) => {
                        return <PlayerCard player={ player } teamTheme={ team.className } link={ true } key={ i }/>;
                    } )
                }
            </ul>
        </div>
    );
}

PlayerList.propTypes = {
    team: PropTypes.object
};

export default PlayerList;
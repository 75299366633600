import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { en } from 'scripts/translations';

import LazyLoadImage from 'scripts/components/common/LazyLoadImage/LazyLoadImage';

import { ReactComponent as PlayerSilhouette } from 'i/elements/player-silhouette.svg';

import Header from './Header';

/**
 * Bowling for a scorecard innings
 *
 * @param {object} props - properties passed to the component
 * @param {object} props.inningsData - scoring context data for this innings
 * @param {boolean} props.showAllTable - set to true to always show the full table
 * @returns {object} React render
 */
function Bowling( { inningsData, showAllTable } ) {

    const tableEle = useRef( null );

    const [ isScrollable, setIsScrollable ] = useState( false );

    const renderHeadshot = headshotUrl => {

        if ( headshotUrl ) {
            return (
                /**
                 * although the following dimensions are very large for this use case, these are the same dimensions used for headshots through the site
                 * using them in this way makes better use of the ODIR cloudfront cache, which makes requests much, much faster
                 */
                <LazyLoadImage
                    src={ `${ headshotUrl }?width=460height=690` }
                    alt="Player Image"
                    imgClassName="scorecard__player-img"
                    placeholder={ false } />
            );
        }

        return (
            <div className="scorecard__player-img scorecard__player-img--silhouette">
                <PlayerSilhouette />
            </div>
        );
    };

    useEffect( () => {
        if ( tableEle.current ) {
            setIsScrollable( tableEle.current.clientWidth > tableEle.current.parentElement.clientWidth );
        }
    }, [] ); // eslint-disable-line

    return (
        <>
            <Header title={ en.bowling } team={ inningsData.bowling.team } showScrollingIcon={ showAllTable || isScrollable } />
            <div className="scorecard__table-container">
                <table className="scorecard__table" ref={ tableEle }>
                    <thead className="scorecard__table-head">
                        <tr className="scorecard__table-head-row">
                            <th className="scorecard__table-heading scorecard__table-heading--player">{ en.player }</th>
                            <th className="scorecard__table-heading">{ en.balls }</th>
                            <th className="scorecard__table-heading">{ en.wickets }</th>
                            <th className="scorecard__table-heading">{ en.runs }</th>
                            <th className={ `scorecard__table-heading ${ showAllTable ? '' : 'u-hide-tablet' }` }>{ en.economy }</th>
                            <th className={ `scorecard__table-heading ${ showAllTable ? '' : 'u-hide-tablet' }` }>{ en.dots }</th>
                            <th className={ `scorecard__table-heading ${ showAllTable ? '' : 'u-hide-tablet' }` }>{ en.maidens }</th>
                        </tr>
                    </thead>
                    <tbody className="scorecard__table-body">
                        {
                            inningsData.bowling.stats.byPlayer.map( player => (
                                <tr className={ `scorecard__table-row scorecard__table-row--bowler ${player.stats ? '' : 'u-hide'}` } key={ player.info.id }>
                                    <td className="scorecard__table-cell scorecard__table-cell--player">
                                        <div className="scorecard__player-image-container">
                                            { renderHeadshot( player.info.headshotUrl ) }
                                        </div>
                                        <div className="scorecard__player-meta">
                                            <div className="scorecard__player-name-container">
                                                <span className="scorecard__player-name u-hide-tablet">{ player.info.fullName }</span>
                                                <span className="scorecard__player-name u-show-tablet">{ player.info.shortName }</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="scorecard__table-cell">{ player.stats ? player.stats.balls : '-' }</td>
                                    <td className="scorecard__table-cell">{ player.stats ? player.stats.w : '-' }</td>
                                    <td className="scorecard__table-cell scorecard__table-cell--border">{ player.stats ? player.stats.r : '-' }</td>
                                    <td className={ `scorecard__table-cell ${ showAllTable ? '' : 'u-hide-tablet' }` }>{ player.stats ? player.stats.e : '-' }</td>
                                    <td className={ `scorecard__table-cell ${ showAllTable ? '' : 'u-hide-tablet' }` }>{ player.stats ? player.stats.d : '-' }</td>
                                    <td className={ `scorecard__table-cell ${ showAllTable ? '' : 'u-hide-tablet' }` }>{ player.stats ? player.stats.maid : '-' }</td>
                                </tr>
                            ) )
                        }
                    </tbody>
                </table>
            </div>
        </>
    );
}

Bowling.propTypes = {
    inningsData: PropTypes.object.isRequired,
    showAllTable: PropTypes.bool
};

export default Bowling;
import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';

import { getContentUrl } from 'scripts/endpoints';
import {
    initialiseVideos,
    initialiseTwitterEmbeds,
    initialiseInstagramEmbeds
} from 'scripts/helpers';
import { en } from 'scripts/translations';

import matchContext from 'scripts/context/match/matchContext';

import Loader from 'scripts/components/common/Loader/Loader';
import PageHeader from 'scripts/components/common/Header/PageHeader';
import SocialShare from 'scripts/components/common/SocialShare/SocialShare';

/**
 * Match Report Article from the Content API
 *
 * @returns {object} React render
 */
export default function MatchReport() {

    const { match } = useContext( matchContext );
    const matchId = match.info.id;

    const [ matchReport, setMatchReport ] = useState( null );
    const [ reportLoading, setReportLoading ] = useState( false );
    const [ embedsInitialised, setEmbedsInitialised ] = useState( false );
    
    let matchReportEle = null;

    /**
     * Removes surrounding template markup from article api body
     * Remove styling and js that's stitched into the article response by the API
     *
     * @param {string} articleBody article body data
     * @returns {object<string>} article body with no embedded requests for styles of scripts, in object format for use with dangerouslySetInnerHTML
     */
    const cleanArticleBody = articleBody => {

        let body = '';

        if ( articleBody.includes( '<!-- article-api-body-start -->' ) ) {
            const trimStart = articleBody.split( '<!-- article-api-body-start -->' )[ 1 ];
            body = trimStart.split( '<!-- article-api-body-end -->' )[ 0 ];
        } else {
            return { __html: articleBody };
        }
        
        return { __html: body };
    };

    /**
     * Get this game's match report from the Content API
     */
    const getMatchReport = async () => {

        setReportLoading( true );

        const params = {
            page: 0,
            pageSize: 1,
            tagNames: 'Match Report',
            references: `CRICKET_MATCH:${ match.info.id }`
        };
        const url = getContentUrl( 'text', params );
        try {
            const res = await axios.get( url );
            if ( res.data.content.length > 0 ) {
                setMatchReport( res.data.content[ 0 ] );
            } else {
                console.warn( 'No Match Report article for this match.' );
                setMatchReport( null );
            }
        } catch ( err ) {
            console.error( `Could not get Match Report article for this match: ${ err }` );
            setMatchReport( null );
        }

        setReportLoading( false );
    };

    /**
     * Find embedded widgets in articles and initialise them
     */
    const initialiseEmbeds = () => {

        if ( matchReportEle ) {

            const videoEmbeds = [].slice.call( matchReportEle.querySelectorAll( 'video' ) );
            if ( videoEmbeds.length > 0 ) {
                initialiseVideos();
            }
    
            const tweetTimelineElem = matchReportEle.querySelector( '.twitter-timeline' );
            const tweetElem = matchReportEle.querySelector( '.twitter-tweet, .twitter-video' );
            if ( tweetTimelineElem || tweetElem ) {
                initialiseTwitterEmbeds();
            }
    
            const instagramPost = matchReportEle.querySelector( '.instagram-post' );
            if ( instagramPost ) {
                initialiseInstagramEmbeds();
            }

            setEmbedsInitialised( true );
        }

    };

    /**
     * Sets the match report as rendered with a reference to the rendered element
     *
     * @param {HTMLElement} element - the match report element stored like a ref
     */
    const matchReportRendered = element => {
        matchReportEle = element;
        if ( !embedsInitialised ) {
            initialiseEmbeds();
        }
    };

    /**
     * Conditionally decides what to render as the match report
     *
     * @returns {object} React render
     */
    const renderReportState = () => {

        if ( reportLoading ) {
            return <Loader className="match-report__loader" modifier="loader--light" />;
        } else if ( !matchReport ) {
            return <PageHeader title={ `${ en.matchReport } ${ en.willAppearHere }` } description={ en.checkBack } />;
        }

        return (
            <article className="match-report" ref={ matchReportRendered }>
                {
                    matchReport === null ? (
                        <div className="match-report__container">
                            <header className="match-report__header">
                                <h1 className="match-report__title">{ en.noMatchReport }</h1>
                            </header>
                        </div>
                    ) : (
                        <>
                            <div className="match-report__social">
                                <SocialShare shareMessage={ matchReport.title }/>
                            </div>
                            <div className="match-report__container">
                                <header className="match-report__header">
                                    <h1 className="match-report__title">{ matchReport.title }</h1>
                                </header>
                                {
                                    matchReport.summary &&
                                        <h3 className="match-report__summary">{ matchReport.summary }</h3>
                                }
                                {
                                    matchReport.body &&
                                        <div className="match-report__body" dangerouslySetInnerHTML={ cleanArticleBody( matchReport.body ) } />
                                }
                            </div>
                        </>
                    )
                }
            </article>
        );
    };

    useEffect( () => { // for requesting the match report
        getMatchReport( matchId );
    }, [ matchId ] );

    return renderReportState();
}
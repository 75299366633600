import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { splitTeamName, prependZeroes } from 'scripts/helpers';
import { en } from 'scripts/translations';

import matchContext from 'scripts/context/match/matchContext';

import TeamBadge from 'scripts/components/common/TeamBadge/TeamBadge';

/**
 * Scorebox used when the match has finished
 *
 * @param {object} props - props passed to component
 * @param {boolean} props.isSuper5 - set to true if the scorebox is in a Super 5 state
 * @returns {object} React render
 */
export default function Scorebox( { isSuper5 } ) {

    const { match } = useContext( matchContext );
    const { teams, scorecard } = match;

    let homeBattingInningsIndex;
    let awayBattingInningsIndex;

    if ( scorecard.innings.length > 0 ) {
        homeBattingInningsIndex = scorecard.innings[ 0 ].batting.team.id === teams.home.id ? 0 : 1;
        awayBattingInningsIndex = scorecard.innings[ 0 ].batting.team.id === teams.away.id ? 0 : 1;
    }

    /**
     * Render score container element for the current innings index
     * If no valid index passed we cannot access any score data, so render empty element instead
     *
     * @param {number} battingInningsIndex - array index for the batting innings
     * @returns {HTMLElement} Score container element
     */
    const renderScore = battingInningsIndex => {
        
        // If battingInningsIndex is undefined we have no score data, render empty element instead
        if ( typeof battingInningsIndex === 'undefined' ) {
            return <div className="scorebox__score-container"></div>;
        }

        // In the event of the game getting called off mid way through, a team may not have a score
        // this will just render a blank score for that team - 'No Result'
        if ( !scorecard?.innings[ battingInningsIndex ]?.batting?.stats?.runs ) {
            return <div className="scorebox__score-container"></div>;
        }

        return (
            <div className="scorebox__score-container">
                <div className="scorebox__score-alt scorebox__score-alt--next" dangerouslySetInnerHTML={ prependZeroes( scorecard.innings[ battingInningsIndex ].batting.stats.runs + 1 ) }></div>
                <div className="scorebox__score" dangerouslySetInnerHTML={ prependZeroes( scorecard.innings[ battingInningsIndex ].batting.stats.runs, true, 'scorebox__score--alt' ) }></div>
                <div className="scorebox__score-alt scorebox__score-alt--prev" dangerouslySetInnerHTML={ prependZeroes( scorecard.innings[ battingInningsIndex ].batting.stats.runs - 1 ) }></div>
                <span className="scorebox__score-runs u-show-tablet">{ en.runs }</span>
            </div>
        );
    };

    return (
        <div className="scorebox__main">
            <div className="scorebox__inner-container scorebox__inner-container--post">
                <div className="scorebox__team-container scorebox__team-container--home">
                    <span className="scorebox__team-name u-hide-tablet" dangerouslySetInnerHTML={ splitTeamName( teams.home.shortName ) } />
                    <TeamBadge
                        abbr={ teams.home.abbr }
                        className="scorebox__team-badge u-hide-tablet"
                        type="matchListLight" />
                    <TeamBadge
                        abbr={ teams.home.abbr }
                        className="scorebox__team-badge u-show-tablet"
                        type="matchListDark" />
                </div>
                <div className="scorebox__scores">
                    { renderScore( homeBattingInningsIndex ) }
                    <div className="scorebox__versus">
                        <span className="scorebox__versus-text">{ en.vs }</span>
                    </div>
                    { renderScore( awayBattingInningsIndex ) }
                    {
                        isSuper5 &&
                            <span className="scorebox__additional-text">{ en.super5 }</span>
                    }
                </div>
                <div className="scorebox__team-container scorebox__team-container--away">
                    <TeamBadge
                        abbr={ teams.away.abbr }
                        className="scorebox__team-badge u-hide-tablet"
                        type="matchListLight" />
                    <TeamBadge
                        abbr={ teams.away.abbr }
                        className="scorebox__team-badge u-show-tablet"
                        type="matchListDark" />
                    <span className="scorebox__team-name u-hide-tablet" dangerouslySetInnerHTML={ splitTeamName( teams.away.shortName ) } />
                </div>
            </div>
        </div>
    );
}

Scorebox.propTypes = {
    isSuper5: PropTypes.bool
};
import React from 'react';
import PropTypes from 'prop-types';

import { en } from 'scripts/translations';

import { ReactComponent as InPlayIcon } from 'i/icons/in-play.svg';
import { ReactComponent as ArrowLeft } from 'i/icons/arrow-left.svg';

/**
 * Top section of the Scorebox
 * Displays teams and venue as well as an indicator if the match is live
 *
 * @param {object} props - properties passed to the component
 * @param {object} props.info - from the match context
 * @param {object} props.scorecard - from the match context
 * @param {object} props.teams - from the match context
 * @param {object} props.fixtureNoScoring - from the match context
 * @param {boolean} props.isLive - true if the match is live
 * @returns {object} React render
 */
export default function ScoreboxHeader( { info, scorecard, teams, fixtureNoScoring, isLive } ) {

    const { parentDomain } = window.environment;

    const renderTeams = () => {

        if ( teams && isLive ) {
            return (
                <div className="scorebox-header__teams">
                    <span className={ `scorebox-header__team scorebox-header__team--home ${ teams.home.id === scorecard.innings[ scorecard.currentInningsIndex ].batting.team.id ? 'is-batting' : '' }` }>{ teams.home.fullName }</span>
                    <span className="scorebox-header__versus">{ en.vsShort }</span>
                    <span className={ `scorebox-header__team scorebox-header__team--away ${ teams.away.id === scorecard.innings[ scorecard.currentInningsIndex ].batting.team.id ? 'is-batting' : '' }` }>{ teams.away.fullName }</span>
                    <span className="scorebox-header__icon-in-play">
                        <InPlayIcon className="u-hide-mobile" />
                    </span>
                </div>
            );
        } else if ( teams ) {
            return (
                <div className="scorebox-header__teams">
                    <span className="scorebox-header__team scorebox-header__team--home">{ teams.home.fullName }</span>
                    <span className="scorebox-header__versus">{ en.vsShort }</span>
                    <span className="scorebox-header__team scorebox-header__team--away">{ teams.away.fullName }</span>
                </div>
            );
        } else if ( fixtureNoScoring ) {
            return (
                <div className="scorebox-header__teams">
                    <span className="scorebox-header__team scorebox-header__team--home">{ fixtureNoScoring.teams.home.fullName }</span>
                    <span className="scorebox-header__versus">{ en.vsShort }</span>
                    <span className="scorebox-header__team scorebox-header__team--away">{ fixtureNoScoring.teams.away.fullName }</span>
                </div>
            );
        }

        return (
            <div className="scorebox-header__teams">
                <span className="scorebox-header__team scorebox-header__team--home">{ en.home } { en.team }</span>
                <span className="scorebox-header__versus">{ en.vsShort }</span>
                <span className="scorebox-header__team scorebox-header__team--away">{ en.away } { en.team }</span>
            </div>
        );
    };

    const renderMatchDetails = () => {

        if ( info && isLive ) {
            return (
                <div className="scorebox-header__venue" title={ info.venue }>
                    { en.live } { en.from } <span className="scorebox-header__venue-name">{ info.venue }</span>
                </div>
            );
        } else if ( info ) {
            return (
                <div className="scorebox-header__venue">
                    <span className="scorebox-header__time">{ info.startTimeLong }</span>
                    <span className="scorebox-header__venue-name" title={ info.venue }>{ info.venue }</span>
                </div>
            );
        } else if ( fixtureNoScoring ) {
            return (
                <div className="scorebox-header__venue">
                    <span className="scorebox-header__time">{ fixtureNoScoring.info.startTimeLong }</span>
                    <span className="scorebox-header__venue-name" title={ fixtureNoScoring.info.venue }>{ fixtureNoScoring.info.venue }</span>
                </div>
            );
        }

        return (
            <div className="scorebox-header__venue">
                { en.venue } TBC
            </div>
        );
    };
    
    return ( // scoring for match exists and is not live
        <div className={ `scorebox-header ${ isLive ? 'scorebox-header--live' : '' }` }>
            <div className="scorebox-header__link-back u-hide-tablet">
                <a href={ `${ parentDomain }/home` } className="scorebox-header__link-back-link" rel="noopener noreferrer" title="Back to The Hundred">
                    <ArrowLeft className="scorebox-header__link-back-icon" />
                    Back to The Hundred
                </a>
            </div>
            { renderTeams() }
            { renderMatchDetails() }
        </div>
    );
}

ScoreboxHeader.propTypes = {
    info: PropTypes.object,
    scorecard: PropTypes.object,
    teams: PropTypes.object,
    fixtureNoScoring: PropTypes.object,
    isLive: PropTypes.bool
};
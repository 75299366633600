import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as BallIcon } from 'i/icons/ball.svg';

/**
 * A placeholder for a ball that is not bowled yet in the Ball By Ball display
 * If the ball is the first in the placeholder array, treat is as the "next" delivery
 *
 * @param {object} props - properties passed to the component
 * @param {boolean} props.isCurrentBall - set to true if this is the current ball being bowled
 * @returns {object} React render
 */
function PlaceholderBall( { isCurrentBall } ) {

    return (
        <div className={ `ball ball--placeholder ${ isCurrentBall ? 'ball--current' : '' }` }>
            <div className="ball__ball">
                <div className="ball__ball-orbit"></div>
                {
                    isCurrentBall &&
                        <BallIcon className="ball__icon"/>
                }
            </div>
            <span className="ball__number">-</span>
        </div>
    );
}

PlaceholderBall.propTypes = {
    isCurrentBall: PropTypes.bool
};

export default PlaceholderBall;
import React from 'react';
import PropTypes from 'prop-types';

import { urlify } from 'scripts/helpers';

import Card from './Card';

/**
 * Wrapping element for a card that decides whether the card should link to a player profile
 *
 * @param {object} props - properties passed to the component
 * @param {object} props.player - the player object
 * @param {string} props.teamTheme - theming class for a team
 * @param {string} props.modifier - modifying CSS BEM class for the player card
 * @param {boolean} props.link - set to true if this is to link to player profile
 * @returns {object} React render
 */
function PlayerCard( { player, teamTheme, modifier, link } ) {

    const { parentDomain } = window.environment;
    const themingClass = player.team ? player.team.className : teamTheme;

    return (
        <>
            {
                link ? (
                    <a className={ `player-card player-card--link ${ themingClass ? `t-team-theme t-${ themingClass }` : '' } ${ modifier || '' }` } href={ `${ parentDomain }/players/${ player.id }/${ urlify( player.fullName ) }` } target="_blank" rel="noopener noreferrer">
                        <Card player={ player }/>
                    </a>
                ) : (
                    <div className={ `player-card ${ themingClass ? `t-team-theme t-${ themingClass }` : '' } ${ modifier || '' }` }>
                        <Card player={ player }/>
                    </div>
                )
            }
        </>
    );
}

PlayerCard.propTypes = {
    player: PropTypes.object.isRequired,
    teamTheme: PropTypes.string,
    modifier: PropTypes.string,
    link: PropTypes.bool
};

export default PlayerCard;
import React, { useContext, useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import {
    COMPONENT_REVEAL_TRANSITION_OPTIONS,
    MEASUREMENTS,
    STYLE_CLS_NAMES
} from 'scripts/constants';
import { useWindowWidth } from 'scripts/hooks';
import { en } from 'scripts/translations';

import matchContext from 'scripts/context/match/matchContext';

import PageHeader from 'scripts/components/common/Header/PageHeader';
import Loader from 'scripts/components/common/Loader/Loader';

import { prepareFeed } from './prepareFeed';

import GenericCard from './CardGeneric';
import TeamCard from './CardTeam';

import PreMatch from './cardContent/PreMatch';
import Lineups from './cardContent/Lineups';
import Runs from './cardContent/Runs';
import Wicket from './cardContent/Wicket';

import { ReactComponent as ChevronUpIcon } from 'i/icons/chevron-up.svg';
import { ReactComponent as ChevronLeftIcon } from 'i/icons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from 'i/icons/chevron-right.svg';

/**
 * Feed of cards that portray events that have occurrect in a match
 *
 * @returns {object} LatestFeed React render
 */
function LatestFeed() {

    const { match } = useContext( matchContext );
    const { info, teams, scorecard } = match;

    const windowWidth = useWindowWidth();
    const [ isMobile, setIsMobile ] = useState( windowWidth < MEASUREMENTS.TABLET );

    const [ feed, updateFeed ] = useState( [] );
    const [ feedLoaded, setFeedLoaded ] = useState( false );
    const [ feedPosition, setFeedPosition ] = useState( 0 );
    const [ feedLength, setFeedLength ] = useState( 0 );
    const [ newFeedItems, setNewFeedItems ] = useState( 0 );
    const [ notificationVisible, setNotificationVisible ] = useState( false );

    const NOTIFICATION_TRANSITION_OPTIONS = { // eslint-disable-line id-length
        'in': true,
        'appear': true,
        'timeout': 100,
        'classNames': 'latest-feed__notification'
    };

    /**
     * Initialises the feed by setting the feed position to the number of items in the feed
     * This number is either set to the number of items in the feed or the number the User was previously on when they last viewed the feed
     *
     * @param {number} preparedFeedLength - the number of items in the feed
     */
    const initFeed = preparedFeedLength => {

        const feedPositionFromStorage = JSON.parse( sessionStorage.getItem( `${ info.id }_feedPosition` ) );

        /**
         * Set the starting position of the feed to the most recent item
         * or the position it was in when the User was last here
         */
        if ( feedPositionFromStorage && feedPositionFromStorage <= preparedFeedLength ) {
            setFeedPosition( feedPositionFromStorage );
        } else {
            sessionStorage.setItem( `${ info.id }_feedPosition`, preparedFeedLength );
            setFeedPosition( preparedFeedLength );
        }

        /**
         * If the feed's length has already been set, i.e. the feed has been loaded once
         * and the feed's length is different to the feed we've just prepared, save the difference out in a state
         */
        if ( feedLength > 0 && preparedFeedLength > feedLength ) {
            setNewFeedItems( preparedFeedLength - feedLength );
            setNotificationVisible( true );
        } else {
            setNotificationVisible( false );
        }

        setFeedLength( preparedFeedLength );
        setFeedLoaded( true );
    };

    /**
     * Get the container for the card based on the card's type
     * Either a Generic or a Team card
     *
     * @param {object} card - the latest feed card
     * @returns {object} React Component card
     */
    const getCard = card => {

        switch ( true ) {
            case /pre-match/.test( card.type ):
                return (
                    <GenericCard
                        cardNumber={ card.number }
                        feedPosition={ feedPosition }
                        updateFeedPosition={ updateFeedPosition }
                        isMobile={ isMobile }>
                        <PreMatch teams={ teams } info={ info } />
                    </GenericCard>
                );
            case /lineups/.test( card.type ):
                return (
                    <GenericCard
                        cardNumber={ card.number }
                        feedPosition={ feedPosition }
                        updateFeedPosition={ updateFeedPosition }
                        isMobile={ isMobile }>
                        <Lineups teams={ teams } />
                    </GenericCard>
                );
            case /W(?!d)/.test( card.type ): // wicket
                return (
                    <TeamCard
                        cardNumber={ card.number }
                        feedPosition={ feedPosition }
                        team={ card.bowler.team }
                        updateFeedPosition={ updateFeedPosition }
                        isMobile={ isMobile }>
                        <Wicket card={ card } />
                    </TeamCard>
                );
            case /^2$/.test( card.type ):
            case /^3$/.test( card.type ):
            case /^4$/.test( card.type ):
            case /^5$/.test( card.type ):
            case /^6$/.test( card.type ):
                return (
                    <TeamCard
                        cardNumber={ card.number }
                        feedPosition={ feedPosition }
                        team={ card.facingBatter.team }
                        updateFeedPosition={ updateFeedPosition }
                        isMobile={ isMobile }>
                        <Runs card={ card } />
                    </TeamCard>
                );
            default:
                return null;
        }
    };

    /**
     * Based on the passed card number will return a string class to apply to that card and relevant surrounding cards
     *
     * @param {number} cardNumber - the selected card's number
     * @returns {string} a string used as a class on each card to control the styling of shown cards
     */
    const getCardPosition = cardNumber => {

        if ( cardNumber === feedPosition + 1 ) {
            return 'next';
        } else if ( cardNumber === feedPosition ) {
            return 'primary';
        } else if ( cardNumber === feedPosition - 1 ) {
            return 'secondary';
        } else if ( cardNumber === feedPosition - 2 ) {
            return 'tertiary';
        }
        return null;
    };
    
    /**
     * Updates the feed position by setting the state and session storage to the new feed position
     *
     * @param {number} newFeedPosition - the desired feed position to update to
     */
    const updateFeedPosition = newFeedPosition => {
        sessionStorage.setItem( `${ info.id }_feedPosition`, newFeedPosition );
        setFeedPosition( newFeedPosition );
    };

    /**
     * Mobile notification to indicate there are new feed items has been clicked so scroll user to the first item
     * Hide the notification
     */
    const scrollToTop = () => {
        window.scrollTo( {
            top: 0,
            behavior: 'smooth'
        } );
        setNotificationVisible( false );
    };

    useEffect( () => {
        
        prepareFeed( match ).then( preparedFeed => {
            updateFeed( preparedFeed );
            initFeed( preparedFeed.length );
        } ).catch( err => {
            console.error( 'Could not prepare the Latest Feed, errored with: ', err );
            setFeedLoaded( true );
        } );

    }, [ scorecard.innings ] ); // eslint-disable-line

    useEffect( () => {
        setIsMobile( windowWidth < MEASUREMENTS.TABLET );
    }, [ windowWidth ] );
    
    if ( !feedLoaded ) {
        return <Loader />;
    }

    if ( !feed || feed.length === 0 ) {
        return (
            <div className="wrapper">
                <PageHeader title={ `${ en.coverage } ${ en.willAppearHere }` } description={ en.checkBack }/>
            </div>
        );
    }

    return (
        <>
            { /* MOBILE NOTIFICATION WHEN NEW FEED ITEMS ARE AVAILABLE */ }
            {
                isMobile && newFeedItems > 0 &&
                    <CSSTransition { ...NOTIFICATION_TRANSITION_OPTIONS }>
                        <button
                            className={ `latest-feed__notification latest-feed__notification--new ${ notificationVisible ? '' : STYLE_CLS_NAMES.IS_HIDDEN }` }
                            onClick={ () => scrollToTop() }>
                            { newFeedItems } { en.feedNewUpdates }
                            <ChevronUpIcon className="latest-feed__notification-icon"/>
                        </button>
                    </CSSTransition>
            }

            <CSSTransition { ...COMPONENT_REVEAL_TRANSITION_OPTIONS }>
                <div className="latest-feed reveal-component mobile-navigation-offset">

                    { /* LINK TO MOST RECENT FEED ITEM */ }
                    {
                        !isMobile && feedPosition !== feed.length &&
                            <CSSTransition { ...NOTIFICATION_TRANSITION_OPTIONS }>
                                <button
                                    className="latest-feed__notification latest-feed__notification--front"
                                    onClick={ () => updateFeedPosition( feed.length ) }>
                                    <ChevronLeftIcon className="latest-feed__notification-icon"/>
                                    { en.feedLatest }
                                </button>
                            </CSSTransition>
                    }

                    { /* LINK TO EARLIEST FEED ITEM */ }
                    {
                        !isMobile && feedPosition !== 1 &&
                            <CSSTransition { ...NOTIFICATION_TRANSITION_OPTIONS }>
                                <button
                                    className="latest-feed__notification latest-feed__notification--back"
                                    onClick={ () => updateFeedPosition( 1 ) }>
                                    { en.feedEarliest }
                                    <ChevronRightIcon className="latest-feed__notification-icon"/>
                                </button>
                            </CSSTransition>
                    }

                    { /* LOADER FOR NEXT ITEM */ }
                    <div className="latest-feed__card-loading u-hide-tablet">
                        <Loader className="latest-feed__card-loading-icon" modifier="loader--no-text" />
                    </div>

                    { /* FEED */ }
                    <ol className="latest-feed__feed">
                        {
                            feed.map( card => (
                                <li
                                    className={ `latest-feed__item ${ getCardPosition( card.number ) || '' }` }
                                    data-card-number={ card.number }
                                    key={ card.number }>
                                    { getCard( card ) }
                                </li>
                            ) )
                        }
                    </ol>
                    
                </div>
            </CSSTransition>
        </>
    );
}

export default LatestFeed;
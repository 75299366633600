export const en = { // eslint-disable-line id-length
    yes: 'yes',
    no: 'no', // eslint-disable-line id-length
    date: 'date',
    seconds: 'seconds',
    minutes: 'minutes',
    hours: 'hours',
    days: 'days',
    toss: 'toss',
    venue: 'venue',
    umpire: 'umpire',
    officials: 'officials',
    referee: 'referee',
    team: 'team',
    home: 'home',
    away: 'away',
    vsShort: 'v',
    vs: 'vs', // eslint-disable-line id-length
    run: 'run',
    runs: 'runs',
    needed: 'needed',
    out: 'out',
    wicket: 'wicket',
    wicketShort: 'w',
    wickets: 'wickets',
    fallOfWicket: 'fall of wicket',
    one: 'one',
    two: 'two',
    three: 'three',
    four: 'four',
    five: 'five',
    six: 'six',
    twoRunsCardTitle: 'Two runs for {batter}',
    twoRunsCardSubtitle: 'from the bowling of {bowler}',
    threeRunsCardTitle: 'Three runs for {batter}',
    threeRunsCardSubtitle: 'from the bowling of {bowler}',
    fourRunsCardTitle: 'Four runs scored by {batter}',
    fourRunsCardSubtitle: 'off the delivery by {bowler}',
    fiveRunsCardTitle: 'Five runs scored by {batter}',
    fiveRunsCardSubtitle: 'off the delivery by {bowler}',
    sixRunsCardTitle: 'Six runs scored by {batter}',
    sixRunsCardSubtitle: 'from {bowler}\'s bowling',
    wicketCardTitle: '{batter} is out!',
    wicketCardSubtitle: 'dismissed by {bowler}',
    score: 'score',
    batting: 'batting',
    batters: 'batters',
    bowling: 'bowling',
    bowlers: 'bowlers',
    ball: 'ball',
    balls: 'balls',
    faced: 'faced',
    left: 'left',
    economy: 'economy',
    dots: 'dots',
    maidens: 'maidens',
    extras: 'extras',
    penaltyRuns: 'penalty runs',
    noBall: 'no ball',
    noBalls: 'no balls',
    wide: 'wide',
    wides: 'wides',
    legBye: 'leg bye',
    legByes: 'leg byes',
    bye: 'bye',
    byes: 'byes',
    strikeRate: 'strike rate',
    innings: 'innings',
    upcoming: 'upcoming',
    complete: 'complete',
    completed: 'completed',
    live: 'live',
    liveNow: 'live now',
    from: 'from',
    first: 'first',
    second: 'second',
    match: 'match',
    matches: 'matches',
    thisMatch: 'this match',
    preMatch: 'pre match',
    postMatch: 'post match',
    matchReport: 'match report',
    countdown: 'countdown',
    latest: 'latest',
    scorecard: 'scorecard',
    ballByBall: 'ball-by-ball',
    lineups: 'lineups',
    squads: 'squads',
    league: 'league',
    rules: 'rules',
    liveStream: 'live stream',
    player: 'player',
    coach: 'coach',
    currently: 'currently',
    explained: 'explained',
    basic: 'basic',
    advanced: 'advanced',
    predictor: 'predictor',
    predictorDescription: 'Win Viz assesses the teams\' performance to predict which side will win based on the current match situation. This should provide a good idea as to who is ahead in the game at any given moment.',
    chanceToWin: 'Chance to Win',
    chanceToTie: 'Chance to Tie',
    feedLatest: 'Latest',
    feedEarliest: 'Earliest',
    feedNewUpdates: 'New Updates',
    tapBallForDetails: 'Tap Balls For More Details',
    coverage: 'coverage',
    willAppearHere: 'will appear here',
    checkBack: 'Please check back later.',
    watchLiveStream: 'Watch the live stream now!',
    shareThisPageOn: 'Share this page on',
    noMatchReport: 'This match has no match report.',
    super5: 'Match is in a Super 5',
    matchNotConcluded: 'This match has not concluded yet.',
    competitionRules: 'Competition Rules',
    notFoundTitle: 'Not Found',
    notFoundDescription: 'Go back to select a different match from the list.'
};

import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

import { RESIZE_DEBOUNCE } from 'scripts/constants';

/**
 * React Hook to manage getting the window width
 *
 * @param {number} delay - the debounce amount in ms
 * @returns {object} width of the window
 */
export function useWindowWidth( delay = RESIZE_DEBOUNCE ) {

    const [ width, setWidth ] = useState( window.innerWidth );

    useEffect( () => {
        const handleResize = () => setWidth( window.innerWidth );
        const debouncedHandleResize = debounce( handleResize, delay );
        window.addEventListener( 'resize', debouncedHandleResize );
        return () => {
            window.removeEventListener( 'resize', debouncedHandleResize );
        };
    }, [ delay ] );

    return width;
}

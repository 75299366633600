import dayjs from 'dayjs';

import {
    MENS_TOURNAMENT_ID,
    WOMENS_TOURNAMENT_ID
} from 'scripts/constants';

import {
    getTeamClass,
    buildMatchUrl
} from 'scripts/helpers';

import {
    GET_MATCHES,
    MATCHES_ERROR
} from '../types';

/**
 * Groups Matches by their state, e.g. 'U', 'L', 'C'
 *
 * @param {Array<object>} matches - list of matches from the cricket API
 * @returns {object} shiny new array of match objects ready to for rendering as a match card
 */
const groupMatchesByState = matches => {

    if ( !matches ) {
        return {};
    }

    const upcomingMatches = matches.filter( match => match.scheduleEntry.matchState === 'U' );
    const liveMatches = matches.filter( match => match.scheduleEntry.matchState === 'L' );
    const completedMatches = matches.filter( match => match.scheduleEntry.matchState === 'C' );

    return {
        upcoming: groupMatchesByDay( upcomingMatches ),
        live: modelMatches( liveMatches ),
        completed: groupMatchesByDay( completedMatches )
    };
};

/**
 * Using match start times, group matches into objects keyed by their match day as a string
 *
 * @param {Array<object>} matchesByState - list of matches to be grouped by day
 * @returns {object<object>} object containing objects keyed by match day, each with an array of the matches that occurs on that day
 */
const groupMatchesByDay = matchesByState => {

    let groupedMatches = {};
    const modelledMatches = modelMatches( matchesByState );

    let lastMatchDate = '';
    modelledMatches.forEach( match => {

        const matchDate = dayjs( match.timestamp ).format( 'dddd Do MMMM' );

        if ( lastMatchDate !== matchDate ) { // this match is on a different day
            lastMatchDate = matchDate;
            groupedMatches[ matchDate ] = [ match ];
        } else { // this match on the same day as the last match
            groupedMatches[ matchDate ].push( match );
        }
    } );

    return groupedMatches;
};

/**
 * Models match data for use in rendering match cards
 *
 * @param {Array<object>} matches - list of matches data to be modelled
 * @returns {Array<object>} shiny new array of match objects ready to for rendering as a match card
 */
const modelMatches = matches => {

    let modelledMatches = [];

    matches.forEach( match => {
        const team1ShortName = match.scheduleEntry.team1.team.shortName;
        const team2ShortName = match.scheduleEntry.team2.team.shortName;

        const modelledMatch = {
            matchId: match.scheduleEntry.matchId.id,
            description: match.label,
            teams: {
                home: {
                    fullName: match.scheduleEntry.team1.team.fullName,
                    shortName: team1ShortName,
                    abbr: match.scheduleEntry.team1.team.abbreviation,
                    teamClass: getTeamClass( team1ShortName )
                },
                away: {
                    fullName: match.scheduleEntry.team2.team.fullName,
                    shortName: team2ShortName,
                    abbr: match.scheduleEntry.team2.team.abbreviation,
                    teamClass: getTeamClass( team2ShortName )
                }
            },
            timestamp: match.timestamp,
            displayTime: {
                time: dayjs( match.timestamp ).format( 'HH:mm' ),
                day: dayjs( match.timestamp ).format( 'dddd' ),
                date: dayjs( match.timestamp ).format( 'DD' ),
                month: dayjs( match.timestamp ).format( 'MMMM' )
            },
            venue: match.scheduleEntry.venue.fullName,
            city: match.scheduleEntry.venue.city,
            isFinal: ( match.label && match.label.toLowerCase().includes( 'final' ) ) ? true : false,
            linkUrl: buildMatchUrl( match.scheduleEntry.matchId.id )
        };

        if ( match.tournamentId.id.toString() === MENS_TOURNAMENT_ID ) {
            modelledMatch.gender = 'Men';
            if ( match.scheduleEntry.venue.metadata && match.scheduleEntry.venue.metadata[ `display_name_${ MENS_TOURNAMENT_ID }` ] ) {
                modelledMatch.venue = match.scheduleEntry.venue.metadata[ `display_name_${ MENS_TOURNAMENT_ID }` ];
            }
        } else if ( match.tournamentId.id.toString() === WOMENS_TOURNAMENT_ID ) {
            modelledMatch.gender = 'Women';
            if ( match.scheduleEntry.venue.metadata && match.scheduleEntry.venue.metadata[ `display_name_${ WOMENS_TOURNAMENT_ID }` ] ) {
                modelledMatch.venue = match.scheduleEntry.venue.metadata[ `display_name_${ WOMENS_TOURNAMENT_ID }` ];
            }
        }

        modelledMatches.push( modelledMatch );
    } );

    return modelledMatches;
};

export const matchesReducer = ( state, action ) => {

    switch ( action.type ) {
        case GET_MATCHES:
            return {
                ...state,
                matches: groupMatchesByState( action.payload.content ),
                loading: false,
            };
        case MATCHES_ERROR:
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
};
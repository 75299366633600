import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend( AdvancedFormat );

import {
    GET_MATCH,
    MATCH_ERROR
} from '../types';

import {
    getUmpires,
    getTeamWon,
    getTeamClass,
    getVenueName
} from 'scripts/helpers';

import {
    MENS_TOURNAMENT_ID,
    WOMENS_TOURNAMENT_ID
} from 'scripts/constants';

import {
    modelCurrentState,
    modelInnings,
    modelSquad
} from './matchReducerHelpers';

export default ( state, action ) => {

    switch ( action.type ) {
        case GET_MATCH: {

            const teams = reduceTeams( action.payload.squads, action.payload.scoring, action.payload.fixtureNoScoring );
            const info = reduceInfo( action.payload.scoring, teams );
            const scorecard = reduceScorecard( action.payload.scoring, teams );
            const winViz = reduceCricViz( action.payload.cricViz );
            const liveStreamVideo = reduceLiveStreamVideo( action.payload.liveStreamVideo );
            const fixtureNoScoring = reduceFixtureNoScoring( action.payload.fixtureNoScoring );
            const metadata = reduceMetadata( action.payload.fixtureWithMetadata );

            return {
                ...state,
                match: {
                    dummyDataPhase: action.payload.dummyDataPhase,
                    info,
                    teams,
                    scorecard,
                    winViz,
                    liveStreamVideo,
                    metadata,
                    fixtureNoScoring // only populated if no scoring
                },
                loading: false
            };
        }
        case MATCH_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
};

/**
 * Reduces and formats the team data down to the values we require
 *
 * @param {object} squads - an object containing both the home and away players, their bios and wicket keeper and captain IDs
 * @param {object} scoring - the full scoring object
 * @param {object} fixtureNoScoring - the response from the fixture API, returned when there's no scoring
 * @returns {object<object>} modelled team data for both home and away teams
 */
const reduceTeams = ( squads, scoring, fixtureNoScoring ) => {

    let isScoring = true;
    if ( !scoring || !scoring.matchInfo || !scoring.matchInfo.teams || scoring.matchInfo.teams.length === 0 ) {
        isScoring = false;
    }

    const HOME = 0;
    const AWAY = 1;

    const homeTeam = isScoring ? scoring.matchInfo.teams[ HOME ] : fixtureNoScoring.scheduleEntry.team1;
    const awayTeam = isScoring ? scoring.matchInfo.teams[ AWAY ] : fixtureNoScoring.scheduleEntry.team2;

    if ( !homeTeam || !awayTeam ) {
        return null;
    }

    return {
        home: {
            id: homeTeam.team.id || homeTeam.team.id,
            abbr: homeTeam.team.abbreviation || null,
            fullName: homeTeam.team.fullName,
            shortName: homeTeam.team.shortName || null,
            className: getTeamClass( homeTeam.team.shortName ),
            type: homeTeam.team.type || null,
            squad: modelSquad( squads.home )
        },
        away: {
            id: awayTeam.team.id || awayTeam.team.id,
            abbr: awayTeam.team.abbreviation || null,
            fullName: awayTeam.team.fullName,
            shortName: awayTeam.team.shortName || null,
            className: getTeamClass( awayTeam.team.shortName ),
            type: awayTeam.team.type || null,
            squad: modelSquad( squads.away )
        }
    };
};

/**
 * Reduces the scoring 'matchInfo' object into the values we require
 *
 * @param {object} scoring - the match scoring data
 * @param {object} teams - an object containing the home and away teams from the match context
 * @returns {object} the match info object
 */
const reduceInfo = ( scoring, teams ) => { // eslint-disable-line complexity

    let info = {};

    if ( scoring && scoring.matchInfo && Object.keys( scoring.matchInfo ).length > 0 && teams ) {

        info = {
            id: scoring.matchId.id,
            state: scoring.matchInfo.matchState ? scoring.matchInfo.matchState : null,
            phase: scoring.currentState && scoring.currentState.phase ? scoring.currentState.phase : null,
            startTime: scoring.matchInfo.matchDate ? scoring.matchInfo.matchDate : 'TBC',
            startTimeLong: scoring.matchInfo.matchDate ? dayjs( scoring.matchInfo.matchDate ).format( 'dddd Do MMMM YYYY, HH:mm' ) : null,
            venue: scoring.matchInfo.venue ? getVenueName( scoring.matchInfo.venue.fullName ) : 'TBC',
            teamsAnnounced: scoring.matchInfo.teams.length > 0 && scoring.matchInfo.teams[ 0 ].players.length > 0 ? true : false,
            inProgress: scoring.currentState && scoring.currentState.inProgress ? scoring.currentState.inProgress : null,
            totalBalls: scoring.matchInfo.totalBalls ? scoring.matchInfo.totalBalls : null,
            summary: scoring.matchInfo.matchSummary ? scoring.matchInfo.matchSummary : null
        };
        
        if ( Object.keys( scoring.matchInfo.additionalInfo ).length > 0 ) {
            info.referee = scoring.matchInfo.additionalInfo[ 'referee.name' ] || null;
            info.umpires = getUmpires( scoring.matchInfo );
            info.tossWinner = scoring.matchInfo.additionalInfo[ 'toss.winner' ] || null;
            info.tossText = scoring.matchInfo.additionalInfo[ 'toss.elected' ] || null;
        }

        if ( scoring.matchInfo.matchState === 'C' && scoring.matchInfo.matchStatus ) {
            info.outcome = scoring.matchInfo.matchStatus.text !== '' ? scoring.matchInfo.matchStatus.text : null;
            info.winner = {
                fullName: getTeamWon( scoring.matchInfo, 'fullName' ),
                shortName: getTeamWon( scoring.matchInfo, 'shortName' ),
                abbr: getTeamWon( scoring.matchInfo, 'abbreviation' )
            };
        }

        if ( teams && teams.home && teams.home.type ) {
            info.type = teams.home.type;
        }
    }

    return info;
};

/**
 * Reduces and formats the scoring data into the values we require
 *
 * @param {object} scoring - the match scoring data
 * @param {object} teams - an object containing the home and away teams from the match context
 * @returns {object} the scoring object
 */
const reduceScorecard = ( scoring, teams ) => {

    if ( !scoring || !scoring.matchInfo || !scoring.matchInfo.battingOrder || !teams ) { // if there is no batting order the game has not started, so there is no scoring
        return null;
    }

    return {
        currentInningsIndex: scoring.currentState && scoring.currentState.currentInningsIndex ? scoring.currentState.currentInningsIndex : 0,
        battingOrder: scoring.matchInfo.battingOrder,
        currentState: modelCurrentState( scoring, teams ),
        innings: modelInnings( scoring, scoring.matchInfo.battingOrder, teams )
    };
};

/**
 * Reduces the CricViz data to just return WinViz portion
 *
 * @param {object} cricViz - the cricViz object
 * @returns {object} the reduced cricViz object to just include winViz
 */
const reduceCricViz = cricViz => {
    if ( cricViz && cricViz.winviz && Object.keys( cricViz.winviz ).length > 0 ) {
        return {
            inningsNumber: parseInt( cricViz.winviz.prediction_innings_number ),
            runsPrediction: parseInt( cricViz.winviz.prediction_runs ),
            homePercent: parseInt( cricViz.winviz.team1_percent ),
            awayPercent: parseInt( cricViz.winviz.team2_percent ),
            tiePercent: parseInt( cricViz.winviz.tie_percent )
        };
    }
    return null;
};

/**
 * Reduces and formats the CMS video data into just the values we require
 *
 * @param {object} video - the video object
 * @returns {object} the reduced video object
 */
const reduceLiveStreamVideo = video => {
    if ( video ) {
        return {
            mediaId: video.mediaId,
            thumbnailUrl: video.thumbnail.onDemandUrl || video.thumbnailUrl
        };
    }
    return null;
};

/**
 * Reduces the "No Scoring" Fixture, i.e. a call to the fixtures/id Cricket API endpoint because there is no scoring for this match
 *
 * @param {object} fixture - a fixture object as retrieved from the fixtures/id endpoint
 * @returns {object} reduced fixture object with only useful properties kept
 */
const reduceFixtureNoScoring = fixture => {
    if ( fixture && Object.keys( fixture ).length > 0 ) {
        const fixtureType = fixture.scheduleEntry.team1.team.type;
        const tournamentId = fixtureType === 'w' ? `display_name_${WOMENS_TOURNAMENT_ID}` : `display_name_${MENS_TOURNAMENT_ID}`;
        const venueNameMetaName = fixture.scheduleEntry.venue.metadata && fixture.scheduleEntry.venue.metadata[ tournamentId ] ? fixture.scheduleEntry.venue.metadata[ tournamentId ] : null;
        fixture.scheduleEntry.venue.metadata && fixture.scheduleEntry.venue.metadata[ `display_name_${ MENS_TOURNAMENT_ID }` ];
        return {
            info: {
                type: fixtureType,
                startTime: fixture.scheduleEntry.matchDate,
                startTimeLong: fixture.scheduleEntry.matchDate ? dayjs( fixture.scheduleEntry.matchDate ).format( 'dddd Do MMMM YYYY, HH:mm' ) : null,
                venue: venueNameMetaName ? venueNameMetaName : fixture.scheduleEntry.venue.fullName
            },
            teams: {
                home: { ...fixture.scheduleEntry.team1.team },
                away: { ...fixture.scheduleEntry.team2.team }
            }
        };
    }
    return null;
};

/**
 * Reduces the metadata from the fixtureWithMetadata endpoint to return the metadata object as set in the CMS
 *  - this is intended to be used to display the match status override text only
 *  - this is not used for scoring
 *
 * @param {object} fixtureWithMetadata - the fixtureWithMetadata object
 * @returns {object} the reduced fixtureWithMetadata object
 */
const reduceMetadata = fixtureWithMetadata => {
    
    if ( fixtureWithMetadata && Object.keys( fixtureWithMetadata.metadata ).length > 0 ) {
        return fixtureWithMetadata.metadata;
    }
    return null;
};
import React from 'react';
import PropTypes from 'prop-types';

import { en } from 'scripts/translations';

import PlayerCard from 'scripts/components/common/PlayerCard/PlayerCard';

/**
 * Displays the current bowler and the two batters in play
 *
 * @param {object} props - properties passed to the component
 * @param {object} props.player - current batting/bowling player object, including info and stats, or null
 * @param {string} props.primaryStat - the primary stat to display
 * @param {string} props.primaryStatLabel - the name of the label for the primary stat
 * @param {string} props.secondaryStat - the secondary stat to display
 * @param {string} props.secondaryStatLabel - the name of the label for the secondary stat
 * @param {string} props.className - an additional CSS class for this element
 * @returns {object} React render
 */
function InPlayPlayer( { player, primaryStat, primaryStatLabel, secondaryStat, secondaryStatLabel, className = '' } ) {

    return (
        <figure className={ `in-play-player ${ className } ${ !player ? 'in-play-player--inactive' : '' }` }>
            {
                player && player.info ? (
                    <>
                        <PlayerCard player={ player.info } modifier={ 'in-play-player__card' } link={ false }/>
                        <figcaption className="in-play-player__caption">
                            <span className="in-play-player__name">{ player.info.fullName }</span>
                            {
                                player.stats ? (
                                    <span className="in-play-player__stats">
                                        <span className="in-play-player__stat">{ player.stats[ primaryStat ] } { en[ primaryStatLabel || primaryStat ] }</span>
                                        <span className="in-play-player__stat in-play-player__stat--secondary">/{ player.stats[ secondaryStat ] } { en[ secondaryStatLabel || secondaryStat ] }</span>
                                    </span>
                                ) : (
                                    <span className="in-play-player__stats">
                                        <span className="in-play-player__stat">0 { en[ primaryStatLabel || primaryStat ] }</span>
                                        <span className="in-play-player__stat in-play-player__stat--secondary">/0 { en[ secondaryStatLabel || secondaryStat ] }</span>
                                    </span>
                                )
                            }
                        </figcaption>
                    </>
                ) : (
                    <>
                        <PlayerCard player={ {} } modifier={ 'in-play-player__card' } link={ false }/>
                        <figcaption className="in-play-player__caption">
                            <span className="in-play-player__name">-</span>
                            <span className="in-play-player__stats">
                                <span className="in-play-player__stat">- { en[ primaryStatLabel || primaryStat ] }</span>
                                <span className="in-play-player__stat in-play-player__stat--secondary">/- { en[ secondaryStatLabel || secondaryStat ] }</span>
                            </span>
                        </figcaption>
                    </>
                )
            }
        </figure>
    );
}

InPlayPlayer.propTypes = {
    player: PropTypes.object,
    primaryStat: PropTypes.string,
    primaryStatLabel: PropTypes.string,
    secondaryStat: PropTypes.string,
    secondaryStatLabel: PropTypes.string,
    className: PropTypes.string
};

export default InPlayPlayer;
import React from 'react';
import PropTypes from 'prop-types';

import { prependZeroes } from 'scripts/helpers';

import { ReactComponent as BallIcon } from 'i/icons/ball.svg';

/**
 * Displays a ball icon and ball number with any necessary prepended zeros
 *
 * @param {object} props - properties passed to the component
 * @param {number} props.ballNumber - what ball this is in the inning
 * @returns {object} React render
 */
function BallCount( { ballNumber } ) {
    return (
        <div className="ball-count">
            <BallIcon className="ball-count__icon"/>
            <div className="ball-count__number" dangerouslySetInnerHTML={ prependZeroes( ballNumber, true, 'ball-count__prepended' ) }></div>
        </div>
    );
}

BallCount.propTypes = {
    ballNumber: PropTypes.number.isRequired
};

export default BallCount;
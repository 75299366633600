import React, { useContext, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import { COMPONENT_REVEAL_TRANSITION_OPTIONS } from 'scripts/constants';
import { toggleScorebox } from 'scripts/helpers';
import { en } from 'scripts/translations';

import matchContext from 'scripts/context/match/matchContext';
import scoreboxContext from 'scripts/context/scorebox/scoreboxContext';

import PageHeader from 'scripts/components/common/Header/PageHeader';
import Tabs from 'scripts/components/common/Tabs/Tabs';

import Innings from './Innings';

/**
 * Screen - Scorecard
 *
 * @returns {object} React render
 */
export default function Scorecard() {

    const { match } = useContext( matchContext );
    const { info, scorecard } = match;
    const { scoreboxOpen, setScoreboxOpen } = useContext( scoreboxContext );

    useEffect( () => {
        if ( !scoreboxOpen ) {
            toggleScorebox(); // ensure the scorebox is open
            setScoreboxOpen( true );
        }
    }, [ match ] ); // eslint-disable-line

    if ( info.state === 'U' || scorecard === null ) {
        return (
            <div className="wrapper">
                <PageHeader title={ `${ en.scorecard } ${ en.willAppearHere }` } description={ en.checkBack }/>
            </div>
        );
    }

    const isSecondInnings = scorecard.currentInningsIndex ? true : false;
    const firstInnings = scorecard.innings[ 0 ];
    const secondInnings = scorecard.innings[ 1 ];

    return (
        <CSSTransition { ...COMPONENT_REVEAL_TRANSITION_OPTIONS }>
            <div className="scorecard reveal-component mobile-navigation-offset">
                <div className="wrapper">
                    {
                        match.metadata && match.metadata.status_text_override && (
                            <div className="scorecard__status">
                                <h3 className="scorecard__status-text">{ match.metadata.status_text_override }</h3>
                            </div>
                        )
                    }
                    {
                        isSecondInnings ? (
                            <Tabs className="scorecard__tabs">
                                <div tabname={ `${ en.first } ${ en.innings } ` }>
                                    <Innings inningsData={ firstInnings } currentInningsIndex={ scorecard.currentInningsIndex } />
                                </div>
                                <div tabname={ `${ en.second } ${ en.innings } ` }>
                                    <Innings inningsData={ secondInnings } currentInningsIndex={ scorecard.currentInningsIndex } />
                                </div>
                            </Tabs>
                        ) : (
                            <Innings inningsData={ firstInnings } />
                        )
                    }
                </div>
            </div>
        </CSSTransition>
    );
}

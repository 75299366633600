/**
 * Function that dispatches an event of a given type
 *
 * @param {string} type The type of the event
 * @param {object} payload Payload data of the event.
 * @param {HTMLElement} target The target, or document, if the target is not specified
 */
export const dispatchEvent = ( type, payload, target ) => {

    const event = new CustomEvent( type, {
        bubbles: true,
        cancelable: false,
        detail: payload || null
    } );

    const eventTarget = target || document;
    eventTarget.dispatchEvent( event );
};

/**
 * Generate a new custom event and fire it on the given element
 *
 * @param {string} name the name of the event
 * @param {object} [data={}] the data to pass to the event
 * @param {HTMLElement} [element=document.body] the element where we should fire the event
 */
export const fireEvent = ( name, data = {}, element = document.body ) => {
    element.dispatchEvent( new CustomEvent( name, {
        detail: data
    } ) );
};

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import MatchState from 'scripts/context/match/MatchState';
import CommentaryState from 'scripts/context/commentary/CommentaryState';
import MatchesState from 'scripts/context/matches/MatchesState';

import Matches from 'scripts/components/screens/Matches/Matches';
import Match from 'scripts/components/screens/Match/Match';
import NotFound from 'scripts/components/screens/NotFound/NotFound';


/**
 * The Hundred Match Centre Application
 *
 * @returns {object} React render
 */
export default function App() {

    return (
        <Switch>
            <Route path="/:matchId(\d+)">
                <MatchState>
                    <CommentaryState>
                        <Match />
                    </CommentaryState>
                </MatchState>
            </Route>
            <Route path="/" exact={ true }>
                <MatchesState>
                    <Matches />
                </MatchesState>
            </Route>
            <Route path="*" render={ () => <NotFound /> } />
        </Switch>
    );
}
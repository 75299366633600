import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import { getContentUrl } from 'scripts/endpoints';

import Coach from './Coach';
import Sponsor from './Sponsor';
import PlayerList from './PlayerList';

/**
 * Singular Squad for a team
 *
 * @param {object} props - properties passed to the component
 * @param {object} props.team - one team to display the squad for
 * @returns {object} React render
 */
function Squad( { team } ) {

    const [ coachBio, setCoachBio ] = useState( null );
    const [ sponsorImage, setSponsorImage ] = useState( null );

    const getCoachBio = async ( teamId ) => {

        const fromSessionStorage = JSON.parse( sessionStorage.getItem( `${ teamId }_coachBio` ) );
        if ( fromSessionStorage ) {
            setCoachBio( fromSessionStorage );
            return;
        }

        const params = {
            page: 0,
            pageSize: 1,
            tagNames: 'The Hundred - Coach',
            references: `CRICKET_TEAM:${ teamId }`,
        };
        const url = getContentUrl( 'bio', params );
        try {
            const res = await axios.get( url );
            if ( res.data.content.length > 0 ) {
                const requestedBio = res.data.content[ 0 ];
                let newCoachBio = {
                    knownName: requestedBio.knownName,
                    imageUrl: null // TODO - silhouette
                };
                if ( requestedBio.headshot && requestedBio.headshot.onDemandUrl ) {
                    newCoachBio.imageUrl = requestedBio.headshot.onDemandUrl;
                }
                sessionStorage.setItem( `${ teamId }_coachBio`, JSON.stringify( newCoachBio ) );
                setCoachBio( newCoachBio );
            } else {
                console.warn( `No coach profile for team ${ teamId }.` );
            }
        } catch ( err ) {
            console.warn( `Error getting coach profile for team ${ teamId }; errored with ${ err }` );
        }
    };

    const getSponsorImage = async ( teamId ) => {

        const fromSessionStorage = sessionStorage.getItem( `${ teamId }_teamSponsorImage` );
        if ( fromSessionStorage ) {
            setSponsorImage( fromSessionStorage );
            return;
        }

        const params = {
            page: 0,
            pageSize: 1,
            tagNames: 'The Hundred - Team Sponsor',
            references: `CRICKET_TEAM:${ teamId }`,
        };
        const url = getContentUrl( 'photo', params );
        try {
            const res = await axios.get( url );
            if ( res.data.content.length > 0 && res.data.content[ 0 ].onDemandUrl ) {
                sessionStorage.setItem( `${ teamId }_teamSponsorImage`, res.data.content[ 0 ].onDemandUrl );
                setSponsorImage( res.data.content[ 0 ].onDemandUrl );
            } else {
                console.warn( `No sponsor image for team ${ teamId }.` );
            }
        } catch ( err ) {
            console.warn( `Error getting sponsor image for team ${ teamId }; errored with ${ err }` );
        }
    };

    useEffect( () => {

        getCoachBio( team.id );
        getSponsorImage( team.id );

        return () => { // remove coaches and sponsor image
            setCoachBio( null );
            setSponsorImage( null );
        };

    }, [ team.id ] );
    
    return (
        <div className={ `squad tab t-team-theme t-${ team.className }` }>
            <div className="squad__header">
                {
                    coachBio &&
                        <Coach coachBio={ coachBio }/>
                }
                {
                    sponsorImage &&
                        <Sponsor sponsorImage={ sponsorImage }/>
                }
            </div>
            <div className="squad__body">
                <PlayerList team={ team }/>
            </div>
        </div>
    );
}

Squad.propTypes = {
    team: PropTypes.object
};

export default Squad;
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { STYLE_CLS_NAMES } from 'scripts/constants';

/**
 * Explanatory text to accompany a component that requires more context
 *
 * @param {object} props - properties passed to the component
 * @param {string} props.title - explainer title text
 * @param {string} props.tooltipText - explainer body text
 * @param {string} props.tooltipModifier - modifying BEM class to add to the tooltip, e.g. tooltip--right
 * @param {string} props.className - additional CSS className
 * @returns {object} React render
 */
function Explainer( { title = '', tooltipText, tooltipModifier, className = '' } ) {

    const [ tooltipOpen, setTooltipOpen ] = useState( false );

    const toggleTooltip = () => {
        tooltipOpen ? setTooltipOpen( false ) : setTooltipOpen( true );
    };

    if ( tooltipText ) {
        return (
            <div className={ `${ className } explainer explainer--tooltip ${ tooltipOpen ? STYLE_CLS_NAMES.IS_ACTIVE : '' }` } onClick={ () => toggleTooltip() }>
                <span className="explainer__title">{ title }</span>
                <span className="explainer__icon">?</span>
                <span className={ `explainer__tooltip tooltip ${ tooltipModifier }` }>{ tooltipText }</span>
            </div>
        );
    }

    return (
        <div className={ `${ className } explainer` }>
            <span className="explainer__title">{ title }</span>
            <span className="explainer__icon">?</span>
        </div>
    );

}

Explainer.propTypes = {
    title: PropTypes.string,
    tooltipText: PropTypes.string,
    tooltipModifier: PropTypes.string,
    className: PropTypes.string
};

export default Explainer;
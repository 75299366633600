import React, { useContext } from 'react';

import matchContext from 'scripts/context/match/matchContext';
import { splitTeamName } from 'scripts/helpers';
import { en } from 'scripts/translations';

import TeamBadge from 'scripts/components/common/TeamBadge/TeamBadge';

import Score from './Score';

import { ReactComponent as ArrowsLiveIcon } from 'i/icons/arrows-live-first-innings.svg';

/**
 * Displays the score for the team batting in the first innings
 *
 * @returns {object} React render
 */
export default function FirstInningsScorebox() {

    const { match } = useContext( matchContext );
    const { scorecard } = match;

    const firstInnings = scorecard.innings[ 0 ];

    return (
        <div className="scorebox__main">
            <div className="scorebox__inner-container scorebox__inner-container--live">
                <div className="scorebox__team-container">
                    <TeamBadge
                        abbr={ firstInnings.batting.team.abbr }
                        className="scorebox__team-badge u-hide-tablet"
                        type="matchListLight" />
                    <TeamBadge
                        abbr={ firstInnings.batting.team.abbr }
                        className="scorebox__team-badge u-show-tablet"
                        type="matchListDark" />
                    <span className="scorebox__team-name" dangerouslySetInnerHTML={ splitTeamName( firstInnings.batting.team.shortName ) } />
                </div>
                <div className="scorebox__runs-container">
                    <Score value={ firstInnings.batting.stats.runs } alt={ false } />
                    <div className="scorebox__runs-label"><span className="scorebox__runs-label-alt">Runs</span>scored</div>
                    <div className="scorebox__wickets-text u-show-phablet">{ firstInnings.bowling.stats.wkts } { en.wickets }</div>
                </div>
                <span className="scorebox__icon-arrows">
                    <ArrowsLiveIcon />
                </span>
                <div className="scorebox__balls-container">
                    <div className="scorebox__label">{ en.balls } <span className="scorebox__label-alt">{ en.faced }</span></div>
                    <Score value={ firstInnings.batting.stats.ballsFaced } alt={ false } />
                </div>
                <div className="scorebox__wickets-container u-hide-phablet">
                    <div className="scorebox__label">{ en.wickets }</div>
                    <Score value={ firstInnings.bowling.stats.wkts } digits={ 2 } alt={ true } />
                </div>
            </div>
        </div>
    );
}
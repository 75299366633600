import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { TIME } from 'scripts/constants';
import { en } from 'scripts/translations';

/**
 * Countdown Clock
 *
 * @typedef {string} Date a JS Date() string
 * @param {Date} datetime - datetime to countdown to
 * @returns {object} React render
 */
function Countdown( { datetime } ) {

    const calculateTimeLeft = () => {

        let timeLeft = {
            days: '00',
            hours: '00',
            minutes: '00',
            seconds: '00'
        };

        const now = +new Date();
        const then = +new Date( datetime );
        const diff = then - now;

        if ( diff > 0 ) {
            timeLeft = {
                days: prependNumber( Math.floor( diff / ( TIME.ONE_SECOND_IN_MS * TIME.ONE_MINUTE_IN_SECS * TIME.ONE_HOUR_IN_MINS * TIME.ONE_DAY_IN_HOURS ) ) ),
                hours: prependNumber( Math.floor( ( diff / ( TIME.ONE_SECOND_IN_MS * TIME.ONE_MINUTE_IN_SECS * TIME.ONE_HOUR_IN_MINS ) ) % TIME.ONE_DAY_IN_HOURS ) ),
                minutes: prependNumber( Math.floor( ( diff / TIME.ONE_SECOND_IN_MS / TIME.ONE_MINUTE_IN_SECS ) % TIME.ONE_HOUR_IN_MINS ) ),
                seconds: prependNumber( Math.floor( ( diff / TIME.ONE_SECOND_IN_MS ) % TIME.ONE_MINUTE_IN_SECS ) )
            };
        }

        return timeLeft;
    };

    /**
     * If the passed number is less than 10, prepend it with a 0
     *
     * @param {number} number - number
     * @returns {string} new number with 0
     */
    const prependNumber = number => {
        const DOUBLE_DIGITS = 10;
        if ( number < DOUBLE_DIGITS ) {
            return `0${ number }`;
        }
        return number.toString();
    };

    const [ clockValues, setClockValues ] = useState( calculateTimeLeft( datetime ) );

    useEffect( () => {
        const timer = setTimeout( () => {
            setClockValues( calculateTimeLeft() );
        }, TIME.ONE_SECOND_IN_MS );
        return () => clearTimeout( timer );
    } );

    return (
        <div className='countdown'>
            <div className="countdown__units-container">
                <div className="countdown__section">
                    <div className="countdown__value">{ clockValues.days }</div>
                    <div className="countdown__label">{ en.days }</div>
                </div>
                <div className="countdown__divider">:</div>
                <div className="countdown__section">
                    <div className="countdown__value">{ clockValues.hours }</div>
                    <div className="countdown__label">{ en.hours }</div>
                </div>
                <div className="countdown__divider">:</div>
                <div className="countdown__section">
                    <div className="countdown__value">{ clockValues.minutes }</div>
                    <div className="countdown__label">{ en.minutes }</div>
                </div>
            </div>
        </div>
    );
}

Countdown.propTypes = {
    datetime: PropTypes.string
};

export default Countdown;
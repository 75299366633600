import {
    BALLS_PER_OVER,
    UPCOMING_POLL_INTERVAL,
    PRE_POLL_INTERVAL,
    LIVE_POLL_INTERVAL
} from 'scripts/constants';
import { urlify } from './url';

/**
 * Gets the player's team and player data from the match 'teams' by the player's ID
 *
 * @param {number} playerId - the player's ID to find data for in the context
 * @param {object} teams - an object containing the home and away teams from the match context
 * @returns {object} returns the player as an object of player and team data
 */
export const bindPlayerInfo = ( playerId, teams ) => {

    let playerData = null;

    const matchingPlayerInfo = [ ...teams.home.squad, ...teams.away.squad ].find( player => player.id === playerId );
    if ( matchingPlayerInfo ) {
        playerData = matchingPlayerInfo;
    }

    const teamsArray = [ teams.home, teams.away ];
    const matchingPlayerTeam = teamsArray.find( team => team.squad.some( player => player.id === playerId ) );
    if ( matchingPlayerTeam ) {
        playerData.team = {
            abbr: matchingPlayerTeam.abbr,
            shortName: matchingPlayerTeam.shortName,
            className: matchingPlayerTeam.className
        };
    }
    
    return playerData;
};

/**
 * Gets the index of the match winner
 *
 * @param {string} outcome - either 'A' or 'B'
 * @returns {number} - The winner index, -1 if no winner
 */
export const matchWinnerAsIndex = outcome => {
    if ( outcome === 'A' ) {
        return 0;
    } else if ( outcome === 'B' ) {
        return 1;
    }
    return -1;
};

/**
 * Gets the team that won the match's name
 *
 * @param {object} matchInfo - the matchInfo object assigned to a match from the scoring API
 * @param {string} nameFormat - either 'fullName', 'shortName' or 'abbreviation'
 * @returns {string} the winning team's name
 */
export const getTeamWon = ( matchInfo, nameFormat ) => {
    if ( matchInfo && matchInfo.matchStatus && matchInfo.matchStatus.outcome ) { // only when all of these are present does the match have an outcome
        const winnerIndex = matchWinnerAsIndex( matchInfo.matchStatus.outcome );
        const winningTeam = matchInfo.teams[ winnerIndex ];
        if ( winningTeam ) {
            return winningTeam.team[ nameFormat ];
        }
        return null;
    }
    return null;
};

/**
 * Returns the team's name as a class for theming and the like
 *
 * @param {object} teamShortName - the team's short name to be converted into a class
 * @returns {string} the team's name as a class
 */
export const teamAsClass = teamShortName => {
    if ( teamShortName ) {
        return urlify( teamShortName.replace( ' Women', '' ).replace( ' Men', '' ) );
    }
    return null;
};

/**
 * Using the current over and the ball within that block, calculates the overall ball number
 *
 * @param {number} over - the over number from progress, so should be a 0-based index
 * @param {number} ball - the ball number from either progress or countingProgress, depending on use case
 * @returns {number} the total number of balls for an innings
 */
export const calcBallNumber = ( over, ball ) => {
    if ( over === 0 ) {
        return ball;
    }
    return ( over * BALLS_PER_OVER ) + ball;
};

/**
 * Returns a maximum of 10 umpire names as an array
 *
 * @param {object} matchInfo - the matchInfo object from the scorecard response
 * @returns {Array} an array of umpire names
 */
export const getUmpires = matchInfo => {

    const limit = 10; // assumes no more than 10 umpires get assigned to a match
    let umpires = [];
    for ( let i = 0; i < limit; i++ ) {
        const umpire = matchInfo.additionalInfo[ 'umpire.name.' + ( i + 1 ) ];
        if ( umpire ) {
            umpires.push( umpire );
        }
    }
    return umpires;
};

/**
 * Concatenates the overHistory from all innings
 *
 * @param {Array<object>} innings - an array of innings objects
 * @returns {Array} an array of all the innings properties of that name
 */
export const concatOverHistory = innings => {

    let result = [];

    innings.forEach( inning => {
        if ( inning.overHistory && inning.overHistory.length > 0 ) {
            result.push( ...inning.overHistory );
        }
    } );

    return result;
};

/**
 * Given a stat name, will concatenate it with the same array in other innings
 *
 * @param {Array<object>} innings - an array of innings objects
 * @param {string} statName - the name of the stat to concatenate from all innings, i.e. 'bowling' or 'batting'
 * @returns {Array} an array of all the innings properties of that name
 */
export const concatStatByProp = ( innings, statName ) => {

    let result = [];

    innings.forEach( inning => {
        if ( inning[ statName ] && inning[ statName ].stats && inning[ statName ].stats.byPlayer.length > 0 ) {
            result.push( ...inning[ statName ].stats.byPlayer );
        }
    } );

    return result;
};

/**
 * Some venues require a different name for this tournament
 * This is usually covered by metadata, but that is not currently supported on the cricket service endpoint
 *
 * @param {string} venueName - the name of the venue
 * @returns {string} the venue name
 */
export const getVenueName = venueName => {

    switch ( venueName ) {
        case 'The Oval':
            return 'The Kia Oval';
        default:
            return venueName;
    }
};

/**
 * Gets the polling interval to use dependent on match state
 *
 * @param {object} match - a matchInfo object from the scoring or fixtureNoScoring objects
 * @returns {number} the poll time in MS
 */
export const getPollInterval = match => {

    if ( match && match.info ) {

        const matchState = match.info.state;
        const matchPhase = match.info.phase;

        if ( matchState === 'L' ) { // match is live

            return LIVE_POLL_INTERVAL;
        } else if ( matchPhase === 'U' ) { // match is upcoming

            return UPCOMING_POLL_INTERVAL;
        } else if ( matchPhase === 'E' ) { // match is in pre-match

            return PRE_POLL_INTERVAL;
        } else if ( matchState === 'C' ) { // match is complete, stop polling

            return null;
        }

        return PRE_POLL_INTERVAL; // match state not recognised, so poll as if in pre-match as a precaution

    }
    return UPCOMING_POLL_INTERVAL; // match has not loaded, so poll as if the match is upcoming
};

import React from 'react';
import PropTypes from 'prop-types';

import { prependZeroes } from 'scripts/helpers';

/**
 * A runs or balls total score
 *
 * @param {object} props - properties passed to the component
 * @param {number} props.value - the run or ball total to display
 * @param {number} props.digits - the number of digits to display
 * @param {boolean} props.alt - set to true to give the score its alternate display
 * @returns {object} React render
 */
function Score( { value, digits, alt } ) {

    const previousValue = value + 1;
    const nextValue = value - 1;
    const altClass = alt ? 'scorebox__score-container--alt' : '';

    return (
        <div className={ `scorebox__score-container ${ altClass }` }>
            <div className="scorebox__score-alt scorebox__score-alt--next">{ previousValue }</div>
            <div className="scorebox__score" dangerouslySetInnerHTML={ prependZeroes( value, digits, true, 'scorebox__score--alt' ) }></div>
            <div className="scorebox__score-alt scorebox__score-alt--prev">{ nextValue }</div>
        </div>
    );
}

Score.propTypes = {
    value: PropTypes.number,
    digits: PropTypes.number,
    alt: PropTypes.bool
};

export default Score;
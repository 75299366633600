import React, { useReducer } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import { CMS_ACCOUNT } from 'scripts/config';
import { idsAsReferenceExpression } from 'scripts/helpers';

import {
    RESPONSE_STATUS,
    MENS_TOURNAMENT_ID,
    WOMENS_TOURNAMENT_ID,
    PRACTICE_GAME_1_ID,
    PRACTICE_GAME_2_ID,
    PRACTICE_GAME_3_ID,
    PRACTICE_GAME_4_ID,
    PRACTICE_GAME_5_ID,
    CRICVIZ_USER,
    CRICVIZ_PASS
} from 'scripts/constants';

import {
    getMatchesUrl,
    getMatchScoringUrl,
    getCricVizUrl,
    getSquadsUrl,
    getContentUrl
} from 'scripts/endpoints';

import {
    GET_MATCH,
    MATCH_ERROR
} from '../types';

import MatchContext from './matchContext';
import matchReducer from './matchReducer';

/**
 * Match Context state
 *
 * @param {object} props - properties passed to the component
 * @param {object} props.children - React component children that this provides context to
 * @returns {object} React render
 */
function MatchState( { children } ) {
    
    const initialState = {
        match: null,
        loading: true,
        error: null
    };

    const [ state, dispatch ] = useReducer( matchReducer, initialState );

    // TEMP - fake data for development
    const DUMMY_DATA = {
        NO_SCORING: {
            scoring: '../../../../temp/no-scoring.json',
            cricViz: '../../../../temp/upcoming-cricviz.json'
        },
        NO_TEAMS: {
            scoring: '../../../../temp/no-teams.json',
            cricViz: '../../../../temp/upcoming-cricviz.json'
        },
        UPCOMING: {
            scoring: '../../../../temp/upcoming-scoring.json',
            cricViz: '../../../../temp/upcoming-cricviz.json'
        },
        PRE_MATCH: {
            scoring: '../../../../temp/pre-match-scoring.json',
            cricViz: '../../../../temp/upcoming-cricviz.json'
        },
        FIRST_INNINGS: {
            scoring: '../../../../temp/first-innings-scoring.json',
            cricViz: '../../../../temp/first-innings-cricviz.json'
        },
        SECOND_INNINGS: {
            scoring: '../../../../temp/second-innings-scoring.json',
            cricViz: '../../../../temp/second-innings-cricviz.json'
        },
        SUPER_OVERS: {
            scoring: '../../../../temp/super-overs-scoring.json',
            cricViz: '../../../../temp/completed-cricviz.json'
        },
        COMPLETED: {
            scoring: '../../../../temp/completed-scoring.json',
            cricViz: '../../../../temp/completed-cricviz.json'
        },
        PRACTICE_GAME: {
            // scoring comes from cricket API
            cricViz: '../../../../temp/completed-cricviz.json'
        }
    };

    const MATCH_PHASES = {
        'No Scoring': DUMMY_DATA.NO_SCORING,
        'No Teams': DUMMY_DATA.NO_TEAMS,
        'Upcoming': DUMMY_DATA.UPCOMING,
        'Pre-match': DUMMY_DATA.PRE_MATCH,
        'First Innings': DUMMY_DATA.FIRST_INNINGS,
        'Second Innings': DUMMY_DATA.SECOND_INNINGS,
        'Super Overs': DUMMY_DATA.SUPER_OVERS,
        'Completed': DUMMY_DATA.COMPLETED
    };
    MATCH_PHASES[ `Practice Game 1 (${ PRACTICE_GAME_1_ID })` ] = DUMMY_DATA.PRACTICE_GAME;
    MATCH_PHASES[ `Practice Game 2 (${ PRACTICE_GAME_2_ID })` ] = DUMMY_DATA.PRACTICE_GAME;
    MATCH_PHASES[ `Practice Game 3 (${ PRACTICE_GAME_3_ID })` ] = DUMMY_DATA.PRACTICE_GAME;
    MATCH_PHASES[ `Practice Game 4 (${ PRACTICE_GAME_4_ID })` ] = DUMMY_DATA.PRACTICE_GAME;
    MATCH_PHASES[ `Practice Game 5 (${ PRACTICE_GAME_5_ID })` ] = DUMMY_DATA.PRACTICE_GAME;
    // END TEMP

    /**
     * Pieces together match data based on scoring, squads, live stream video and CricViz data
     *
     * @param {number} matchId - the ID of the selected match
     * @param {string} dummyDataPhase - the phase selected by the dummy data toggle, e.g. 'First Innings'
     */
    const getMatch = async ( matchId, dummyDataPhase ) => {

        // let [ scoring, cricViz, liveStreamVideo ] = await Promise.all( [ getScoring( matchId, dummyDataPhase ), getCricViz( matchId, dummyDataPhase ), getLiveStreamVideo( matchId, dummyDataPhase ) ] ).catch( err => {
        //     dispatch( {
        //         type: MATCH_ERROR,
        //         payload: err.response
        //     } );
        // } );
        let [ scoring, cricViz, fixtureWithMetadata ] = await Promise.all( [ getScoring( matchId, dummyDataPhase ), getCricViz( matchId, dummyDataPhase ), getFixtureNoScoring( matchId ) ] ).catch( err => {
            dispatch( {
                type: MATCH_ERROR,
                payload: err.response
            } );
        } );

        let homeSquad = {};
        let awaySquad = {};
        let fixtureNoScoring = {};

        /**
         * Occassionally during pre-match we receive match info but no teams
         * When this occurs the match centre will break, so treat the match as upcoming (load the fixtureNoScoring) until we get teams
         */
        if ( scoring && scoring.matchInfo && scoring.matchInfo.teams && scoring.matchInfo.teams.length > 0 ) {
            [ homeSquad, awaySquad ] = await Promise.all( [ getLineup( scoring.matchInfo, true ), getLineup( scoring.matchInfo, false ) ] ).catch( err => {
                dispatch( {
                    type: MATCH_ERROR,
                    payload: err.response
                } );
            } );
        } else {
            fixtureNoScoring = await getFixtureNoScoring( matchId );

            if ( fixtureNoScoring.scheduleEntry && fixtureNoScoring.scheduleEntry.team1 !== {} && fixtureNoScoring.scheduleEntry.team2 !== {} ) {

                [ homeSquad, awaySquad ] = await Promise.all( [ getSquad( fixtureNoScoring.scheduleEntry.team1 ), getSquad( fixtureNoScoring.scheduleEntry.team2 ) ] ).catch( err => {
                    dispatch( {
                        type: MATCH_ERROR,
                        payload: err.response
                    } );
                } );
            }
        }

        dispatch( {
            type: GET_MATCH,
            payload: {
                dummyDataPhase,
                scoring,
                cricViz,
                squads: {
                    home: homeSquad,
                    away: awaySquad
                },
                // liveStreamVideo,
                fixtureNoScoring,
                fixtureWithMetadata
            }
        } );
    };

    /**
     * Requests scoring data
     *
     * @param {number} matchId - the ID of the selected match
     * @param {string} dummyDataPhase - the phase selected by the dummy data toggle, e.g. 'First Innings'
     * @returns {object} a Scoring API response for a match
     */
    const getScoring = async ( matchId, dummyDataPhase ) => {

        let url = getMatchScoringUrl( matchId );

        // TEMP
        if ( dummyDataPhase && dummyDataPhase === `Practice Game 1 (${ PRACTICE_GAME_1_ID })` ) {
            url = getMatchScoringUrl( PRACTICE_GAME_1_ID );
        } else if ( dummyDataPhase && dummyDataPhase === `Practice Game 2 (${ PRACTICE_GAME_2_ID })` ) {
            url = getMatchScoringUrl( PRACTICE_GAME_2_ID );
        } else if ( dummyDataPhase && dummyDataPhase === `Practice Game 3 (${ PRACTICE_GAME_3_ID })` ) {
            url = getMatchScoringUrl( PRACTICE_GAME_3_ID );
        } else if ( dummyDataPhase && dummyDataPhase === `Practice Game 4 (${ PRACTICE_GAME_4_ID })` ) {
            url = getMatchScoringUrl( PRACTICE_GAME_4_ID );
        } else if ( dummyDataPhase && dummyDataPhase === `Practice Game 5 (${ PRACTICE_GAME_5_ID })` ) {
            url = getMatchScoringUrl( PRACTICE_GAME_5_ID );
        }
        // END TEMP

        try {
            let data;

            // TEMP
            if ( dummyDataPhase && !dummyDataPhase.includes( 'Practice Game' ) ) {
                const result = await axios.get( MATCH_PHASES[ dummyDataPhase ].scoring );
                data = result.data;
                if ( process.env.NODE_ENV === 'development' ) {
                    console.log( '%c%s', 'color: saddlebrown; background: lemonchiffon;', 'DUMMY', 'SCORING DATA: ', data ); // eslint-disable-line no-console
                }
            } else { // END TEMP
                const result = await axios.get( url );
                data = result.data;
            }
            return data;
        } catch ( err ) {
            console.error( 'SCORING REQUEST ERRORED: ', err );
            return err.response;
        }
    };

    /**
     * Requests WinViz data from the CricViz API
     *
     * @param {number} matchId - the ID of the selected match
     * @returns {object} a CricViz API response for a match
     */
    const getCricViz = async ( matchId ) => {

        const url = getCricVizUrl( matchId );
        
        try {
            const result = await axios( {
                url: url,
                mode: 'cors',
                method: 'get',
                withCredentials: true,
                auth: {
                    username: CRICVIZ_USER,
                    password: CRICVIZ_PASS
                }
            } );
            return result.data;
        } catch ( err ) {
            console.error( 'CRICVIZ REQUEST ERRORED: ', err );
            return err.response;
        }
    };

    /**
     * Using a team object, getPlayerList() and getPlayerBios(), creates a squad of players and their corresponding CMS BIOs
     * If there are players within the scoring object then the lineups have been announced and they should be used,
     * if there are no players in the scoring a request to the squads endpoint using the team ID and tournament ID is made to get these
     *
     * @param {object} matchInfo - the scoring.matchInfo data
     * @param {boolean} isHomeTeam - set to true if this is for the home team
     * @returns {object} An object containing the player list, corresponding player bios and the wicket keeper and captain IDs
     */
    const getLineup = async ( matchInfo, isHomeTeam ) => {

        const teamIndex = isHomeTeam ? 0 : 1;

        if ( matchInfo.teams.length <= 0 || !matchInfo.teams[ teamIndex ] ) {
            return null;
        }

        const team = matchInfo.teams[ teamIndex ];

        let players = [];

        if ( team.players && team.players.length > 0 ) {
            players = team.players;
        } else {
            players = await getSquadPlayers( team, true ).catch( err => {
                console.error( `Errored getting ${ team.team.shortName }'s player list; errored with ${ err }` );
                return [];
            } );
        }

        if ( players.length > 0 ) {
            let bios = await getSquadBios( players, true ).catch( err => {
                console.error( `Errored getting ${ team.team.shortName }'s player bios; errored with ${ err }` );
                return [];
            } );

            return {
                players,
                bios,
                wicketKeeperId: team.wicketKeeper ? team.wicketKeeper.id : null,
                captainId: team.captain ? team.captain.id : null
            };
        }
        
        return null;
    };

    /**
     * We don't know the lineups for the teams yet as there's no scoring or the players aren't in the scoring,
     * make a request to the squads endpoint to get them from there instead.
     *
     * @param {object} team - the team to get the squad for
     * @returns {object} An object containing the player list, corresponding player bios and the wicket keeper and captain IDs
     */
    const getSquad = async ( team ) => {

        let players = await getSquadPlayers( team, false ).catch( err => {
            console.error( `Errored getting ${ team.shortName }'s squad; errored with ${ err }` );
            return [];
        } );

        if ( players.length > 0 ) {

            let bios = await getSquadBios( players, false ).catch( err => {
                console.error( `Errored getting ${ team.shortName }'s squad bios; errored with ${ err }` );
                return [];
            } );

            return {
                players,
                bios,
                wicketKeeperId: null,
                captainId: null
            };
        }
        
        return null;
    };
    
    /**
     * Gets the player list from the squads endpoint
     *
     * @param {object} team - the team object from scoring.matchInfo
     * @param {boolean} isScoring - set to true if this squad is in the scoring response, as this will use "pulseId" instead of just "id"
     * @returns {Array<object>} a list of players for the given team
     */
    const getSquadPlayers = async ( team, isScoring ) => {

        const teamId = isScoring ? team.team.id : team.team.id;

        const url = getSquadsUrl( teamId, team.team.type === 'MEN' ? MENS_TOURNAMENT_ID : WOMENS_TOURNAMENT_ID );
        try {
            const res = await axios.get( url );
            if ( res.data.ALL.players.length > 0 ) {
                return res.data.ALL.players;
            }
            console.warn( `No squad for team ${ teamId }.` );
            return [];
        } catch ( err ) {
            console.warn( `Errored getting squad players for team ${ teamId }; errored with ${ err }` );
            return [];
        }
    };

    /**
     * Using the players from the scoring or squads request, request their corresponding BIOs from the CMS
     * These BIOs provide supplementary data
     *
     * @param {Array<object>} playerList - the list of players from scoring.matchInfo
     * @param {boolean} isScoring - set to true if this squad is in the scoring response, as this will use "pulseId" instead of just "id"
     * @returns {Array<object>} a list of players CMS Bios, either from the Bio List endpoint or from Session Storage
     */
    const getSquadBios = async ( playerList, isScoring ) => {

        let bios = [];
        let playerIdsWithoutBioImg = [];

        playerList.forEach( player => {
            const fromSessionStorage = JSON.parse( sessionStorage.getItem( `${ isScoring ? player.id : player.id }_bioData` ) );
            if ( !fromSessionStorage || !fromSessionStorage.headshotUrl ) { // firstly, check if the player's bio exists in the session storage and has a headshot, if so it can immediately added to the headshots array
                playerIdsWithoutBioImg.push( isScoring ? player.id : player.id );
            }
        } );

        if ( playerIdsWithoutBioImg.length > 0 ) {

            const params = {
                page: 0,
                pageSize: 40,
                tagNames: 'The Hundred - Player Profile',
                referenceExpression: idsAsReferenceExpression( playerIdsWithoutBioImg, 'CRICKET_PLAYER', 'or' )
            };
            const url = getContentUrl( 'bio', params );
            try {
                const res = await axios.get( url, {
                    headers: {
                        account: CMS_ACCOUNT // account needed to retrieve metadata
                    }
                } );
                const missingBios = res.data.content;

                if ( missingBios.length > 0 ) {
                    missingBios.concat( bios );
                    return missingBios;
                }
                return bios;
            } catch ( err ) {
                console.warn( `Errored getting squad bios; errored with ${ err }` );
            }
        }

        return bios;
    };

    /**
     * Gets the live stream for this match from the Content API, if applicable
     *
     * @param {number} matchId - the ID of the selected match
     * @param {string} dummyDataPhase - the phase selected by the dummy data toggle, e.g. 'First Innings'
     * @returns {object} a video object from the Content API
     */
    // const getLiveStreamVideo = async ( matchId, dummyDataPhase ) => {

    //     let params = {
    //         page: 0,
    //         pageSize: 1,
    //         tagNames: 'Live Stream',
    //         references: `CRICKET_MATCH:${ matchId }`
    //     };
        
    //     // TEMP
    //     if ( dummyDataPhase === `Practice Game 1 (${ PRACTICE_GAME_1_ID })` ) {
    //         params.references = `CRICKET_MATCH:${ PRACTICE_GAME_1_ID }`;
    //     } else if ( dummyDataPhase === `Practice Game 2 (${ PRACTICE_GAME_2_ID })` ) {
    //         params.references = `CRICKET_MATCH:${ PRACTICE_GAME_2_ID }`;
    //     } else if ( dummyDataPhase === `Practice Game 3 (${ PRACTICE_GAME_3_ID })` ) {
    //         params.references = `CRICKET_MATCH:${ PRACTICE_GAME_3_ID }`;
    //     } else if ( dummyDataPhase === `Practice Game 4 (${ PRACTICE_GAME_4_ID })` ) {
    //         params.references = `CRICKET_MATCH:${ PRACTICE_GAME_4_ID }`;
    //     } else if ( dummyDataPhase === `Practice Game 5 (${ PRACTICE_GAME_5_ID })` ) {
    //         params.references = `CRICKET_MATCH:${ PRACTICE_GAME_5_ID }`;
    //     }
    //     // END TEMP
        
    //     const url = getContentUrl( 'video', params );
    //     try {
    //         const res = await axios.get( url );
    //         if ( res.data.content.length === 0 ) {
    //             return null;
    //         }
    //         return res.data.content[ 0 ];
    //     } catch {
    //         return null;
    //     }
    // };

    /**
     * If there is no scoring for a match yet, we still need to get information for the match
     * This makes a call to the Cricket API fixtures/id endpoint for top-level data
     *
     * @param {number} matchId - the ID of the selected match
     * @returns {object} data for a fixture
     */
    const getFixtureNoScoring = async ( matchId ) => {

        const url = getMatchesUrl( [], matchId );
        try {
            const res = await axios.get( url, {
                headers: {
                    account: CMS_ACCOUNT // account needed to retrieve metadata
                }
            } );
            if ( res.status === RESPONSE_STATUS.SUCCESS ) {
                return res.data;
            }
            console.warn( `Could not get fixture data for match ${ matchId }.` );
            return {};
        } catch ( err ) {
            console.warn( `Could not get fixture data for ${ matchId }; errored with ${ err }` );
            return {};
        }
    };

    return (
        <MatchContext.Provider
            value={ {
                match: state.match,
                loading: state.loading,
                error: state.error,
                getMatch,
            } }>
            { children }
        </MatchContext.Provider>
    );
}

MatchState.propTypes = {
    children: PropTypes.node
};

export default MatchState;
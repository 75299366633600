import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { en } from 'scripts/translations';

import Header from './Header';

/**
 * Fall of Wicket table for a scorecard innings
 *
 * @param {object} props - properties passed to the component
 * @param {object} props.inningsData - scoring context data for this innings
 * @param {boolean} props.showAllTable - set to true to always show the full table
 * @returns {object} React render
 */
function FallOfWicket( { inningsData, showAllTable } ) {

    const tableEle = useRef( null );

    const [ isScrollable, setIsScrollable ] = useState( false );

    useEffect( () => {
        if ( tableEle.current ) {
            setIsScrollable( tableEle.current.clientWidth > tableEle.current.parentElement.clientWidth );
        }
    }, [] ); // eslint-disable-line

    return (
        inningsData.batting.stats.fow.length > 0 &&
            <>
                <Header showScrollingIcon={ isScrollable } />
                <div className="scorecard__table-container">
                    <table className="scorecard__table" ref={ tableEle }>
                        <thead className="scorecard__table-head">
                            <tr className="scorecard__table-head-row">
                                <th className="scorecard__table-heading scorecard__table-heading--player">{ en.fallOfWicket }</th>
                                <th className="scorecard__table-heading">
                                    <span className="u-hide-tablet">{ en.team } { en.score }</span>
                                    <span className="u-show-tablet">{ en.score }</span>
                                </th>
                                <th className="scorecard__table-heading">Ball No.</th>
                                <th className={ `scorecard__table-heading ${ showAllTable ? '' : 'u-hide-tablet' }` }></th>
                                <th className={ `scorecard__table-heading ${ showAllTable ? '' : 'u-hide-tablet' }` }></th>
                                <th className={ `scorecard__table-heading ${ showAllTable ? '' : 'u-hide-tablet' }` }></th>
                            </tr>
                        </thead>
                        <tbody className="scorecard__table-body">
                            {
                                inningsData.batting.stats.fow.map( fow => (
                                    <tr className="scorecard__table-row" key={ fow.w }>
                                        <td className="scorecard__table-cell scorecard__table-cell--player">
                                            <div className="scorecard__player-image-container scorecard__player-image-container--out"></div>
                                            <div className="scorecard__player-meta">
                                                <div className="scorecard__player-name-container">
                                                    <span className="scorecard__player-name u-hide-tablet">{ fow.player.fullName }</span>
                                                    <span className="scorecard__player-name u-show-tablet">{ fow.player.lastName }</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="scorecard__table-cell scorecard__table-cell--border">{ fow.r }</td>
                                        <td className="scorecard__table-cell">{ fow.ballNumber }</td>
                                        {
                                            showAllTable ? (
                                                <td colSpan="3"></td>
                                            ) : (
                                                <td colSpan="3" className="u-hide-tablet"></td>
                                            )
                                        }
                                    </tr>
                                ) )
                            }
                        </tbody>
                    </table>
                </div>
            </>
    );
}

FallOfWicket.propTypes = {
    inningsData: PropTypes.object.isRequired,
    showAllTable: PropTypes.bool
};

export default FallOfWicket;
import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';

import matchContext from 'scripts/context/match/matchContext';

import { getContentUrl } from 'scripts/endpoints';
import { teamAsClass } from 'scripts/helpers';
import { en } from 'scripts/translations';

import TeamBadge from 'scripts/components/common/TeamBadge/TeamBadge';
import LazyLoadImage from 'scripts/components/common/LazyLoadImage/LazyLoadImage';

/**
 * Displays the result of the match as a header, with the 'player of the match' headshot
 *
 * @returns {object} React render
 */
export default function PostMatchHeader() {

    const { match } = useContext( matchContext );
    const { info, metadata } = match;
    const [ playerOfTheMatchImage, setPlayerOfTheMatchImage ] = useState( null );

    const getPlayerOfTheMatchImage = async ( matchId ) => {

        const fromSessionStorage = sessionStorage.getItem( `${ matchId }_playerOfTheMatchImage` );

        if ( fromSessionStorage ) {
            setPlayerOfTheMatchImage( fromSessionStorage );
        } else {
            
            const params = {
                page: 0,
                pageSize: 1,
                tagNames: 'Match Report',
                references: `CRICKET_MATCH:${ matchId }`,
            };
            const url = getContentUrl( 'photo', params );
            try {
                const res = await axios.get( url );
                if ( res.data.content.length > 0 && res.data.content[ 0 ].onDemandUrl ) {
                    sessionStorage.setItem( `${ matchId }_playerOfTheMatchImage`, res.data.content[ 0 ].onDemandUrl );
                    setPlayerOfTheMatchImage( res.data.content[ 0 ].onDemandUrl );
                }
                // no need to do anything if there is no image as there is a fallback
            } catch ( err ) {
                // no need to form an error if this doesn't work as there is a fallback
            }
        }
    };

    const matchId = info.id;
    const matchStatusOverride = metadata && metadata.status_text_override;
    
    useEffect( () => {
        getPlayerOfTheMatchImage( matchId );
    }, [ matchId ] ); // eslint-disable-line
    
    const winnerAsClass = teamAsClass( info.winner?.shortName );

    return (
        <header className={ `post-match-header ${ winnerAsClass ? `t-team-theme t-${ winnerAsClass }` : '' } ` }>
            <div className="post-match-header__background team-background">
                <div className="wrapper">
                    {
                        info && info.winner && info.outcome ? (
                            <div className="post-match-header__content">
                                <h1 className="post-match-header__title team-title-font">
                                    { info.outcome }
                                    { matchStatusOverride ? (
                                        <span>{ matchStatusOverride }</span>
                                    ) : null }
                                </h1>
                                {
                                    playerOfTheMatchImage ? (
                                        <LazyLoadImage
                                            src={ `${ playerOfTheMatchImage }?width=460height=690` }
                                            srcConfigs={ [
                                                { srcSet: `${ playerOfTheMatchImage }?width=460height=690, ${ playerOfTheMatchImage }?width=920height=1380 2x` }
                                            ] }
                                            alt="Player of the Match"
                                            className="post-match-header__player-image"
                                            imgClassName="post-match-header__img"
                                            placeholder={ false }/>
                                    ) : (
                                        <TeamBadge abbr={ info.winner.abbr } className="post-match-header__team-badge" type="postMatchHeader"/>
                                    )
                                }
                            </div>
                        ) : (

                            info && info.summary && info.state === 'C' ? (

                                <div className="post-match-header__content">
                                    <h1 className="post-match-header__title">
                                        { info.summary }
                                        { matchStatusOverride ? (
                                            <span>{ matchStatusOverride }</span>
                                        ) : null }
                                    </h1>
                                </div>
                            ) : (
                                <div className="post-match-header__content">
                                    <h1 className="post-match-header__title">
                                        { en.matchNotConcluded }
                                        { matchStatusOverride ? (
                                            <span>{ matchStatusOverride }</span>
                                        ) : null }
                                    </h1>
                                </div>
                            )
                        )
                    }
                </div>
            </div>
        </header>
    );
}
import React from 'react';
import PropTypes from 'prop-types';

import { en } from 'scripts/translations';

/**
 * Penalty Runs for a scorecard innings
 *
 * @param {object} props - properties passed to the component
 * @param {object} props.inningsData - scoring context data for this innings
 * @param {boolean} props.showAllTable - set to true to always show the full table
 * @returns {object} React render
 */
function PenaltyRuns( { inningsData, showAllTable } ) {

    return inningsData.batting.stats.penaltyRuns > 0 && (
        <tr className="scorecard__table-row scorecard__table-row--extras">
            <td className="scorecard__table-cell">
                <div className="scorecard__table-cell-alt">
                    { en.penaltyRuns }
                </div>
            </td>
            <td className="scorecard__table-cell"><strong>{ inningsData.batting.stats.penaltyRuns }</strong></td>
            {
                !showAllTable &&
                    <td className="u-show-tablet"></td>
            }
            {
                showAllTable &&
                    <td colSpan="4" className="u-show-tablet"></td>
            }
            <td colSpan="4" className="u-hide-tablet"></td>
        </tr>
    );
}

PenaltyRuns.propTypes = {
    inningsData: PropTypes.object.isRequired,
    showAllTable: PropTypes.bool
};

export default PenaltyRuns;
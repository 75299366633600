import React from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { COMPONENT_REVEAL_TRANSITION_OPTIONS } from 'scripts/constants';
import { en } from 'scripts/translations';

import PageHeader from 'scripts/components/common/Header/PageHeader';
import MatchesHeader from '../Matches/MatchesHeader';

/**
 * 404 page used if a user lands on an unhandled route
 *
 * @returns {object} React render
 */
function NotFound() {

    return (
        <CSSTransition { ...COMPONENT_REVEAL_TRANSITION_OPTIONS }>
            <div className="not-found reveal-component">
                <MatchesHeader title="Matches" />
                <div className="wrapper">
                    <PageHeader title={ en.notFoundTitle } description={ en.notFoundDescription } />
                    <Link to="/" className="not-found__button button button--primary">
                        Back to Matches
                    </Link>
                </div>
            </div>
        </CSSTransition>
    );
}

export default NotFound;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { playVideo, pauseVideo } from 'scripts/helpers';
import { STYLE_CLS_NAMES } from 'scripts/constants';
import { en } from 'scripts/translations';

import VideoPlayer from 'scripts/components/common/Video/VideoPlayer';
import LazyLoadImage from 'scripts/components/common/LazyLoadImage/LazyLoadImage';

import { ReactComponent as PlayIcon } from 'i/icons/play.svg';
import { ReactComponent as CloseIcon } from 'i/icons/cross.svg';

/**
 * A Brightcove video live stream of a cricket match
 *
 * @param {object} props - properties passed to the component
 * @param {object} props.liveStreamVideo - a video object returned from the Content API
 * @returns {object} React render
 */
function LiveStream( { liveStreamVideo } ) {

    const [ streamRevealed, toggleStreamReveal ] = useState( false );

    const revealStream = () => {
        toggleStreamReveal( true );
        playVideo( 'liveStreamVideo' );
    };

    const hideStream = () => {
        toggleStreamReveal( false );
        pauseVideo( 'liveStreamVideo' );
    };

    return (
        <section className={ `live-stream-video ${ streamRevealed ? STYLE_CLS_NAMES.IS_REVEALED : '' }` }>
            <div className="live-stream-video__container">
                <VideoPlayer playerName="liveStreamVideo" videoId={ liveStreamVideo.mediaId } className="live-stream-video__video"/>
            </div>
            <div className="u-show-tablet">
                {
                    liveStreamVideo.thumbnailUrl ? (
                        <LazyLoadImage
                            src={ `${ liveStreamVideo.thumbnailUrl }?width=840&height=120` }
                            srcConfigs={ [
                                { srcSet: `${ liveStreamVideo.thumbnailUrl }?width=840&height=120, ${ liveStreamVideo.thumbnailUrl }?width=1680&height=240 2x`, mediaQuery: '(max-width: 840px)' }
                            ] }
                            alt="Live Stream Poster Image"
                            placeholderModifier="lazy-image__placeholder--dark lazy-image__placeholder--small"
                            className="live-stream-video__poster"/>
                    ) : (
                        <div className="live-stream__poster live-stream__poster--no-thumbnail"></div>
                    )
                }
                <button className="live-stream-video__reveal" onClick={ () => revealStream() }>
                    <PlayIcon className="live-stream-video__reveal-icon"/>
                    <span className="live-stream-video__reveal-text">{ en.watchLiveStream }</span>
                </button>
                <button className="live-stream-video__close" onClick={ () => hideStream() }>
                    <CloseIcon className="live-stream-video__close-icon"/>
                </button>
            </div>
        </section>
    );
}

LiveStream.propTypes = {
    liveStreamVideo: PropTypes.object
};

export default LiveStream;


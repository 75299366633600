import React, { useReducer } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import {
    PRACTICE_GAME_1_ID,
    PRACTICE_GAME_2_ID,
    PRACTICE_GAME_3_ID,
    PRACTICE_GAME_4_ID,
    PRACTICE_GAME_5_ID
} from 'scripts/constants';
import { getMatchCommentaryUrl } from 'scripts/endpoints';

import CommentaryContext from './commentaryContext';
import commentaryReducer from './commentaryReducer';

import {
    GET_COMMENTARY,
    COMMENTARY_ERROR
} from '../types';

/**
 * Commentary Context state
 *
 * @param {object} props - properties passed to the component
 * @param {object} props.children - React component children that this provides context to
 * @returns {object} React render
 */
function CommentaryState( { children } ) {
    
    const initialState = {
        commentary: null,
        loading: true,
        error: null
    };

    const [ state, dispatch ] = useReducer( commentaryReducer, initialState );

    // TEMP - fake data for development
    const DUMMY_COMMENTARY = {
        FIRST_INNINGS: '../../../../temp/first-innings-commentary.json',
        SECOND_INNINGS: '../../../../temp/second-innings-commentary.json',
        COMPLETED: '../../../../temp/completed-commentary.json'
    };

    const MATCH_PHASES = {
        'First Innings': DUMMY_COMMENTARY.FIRST_INNINGS,
        'Second Innings': DUMMY_COMMENTARY.SECOND_INNINGS,
        'Completed': DUMMY_COMMENTARY.COMPLETED
    };
    // END TEMP

    /**
     * Requests commentary data
     *
     * @param {number} matchId - the ID of the selected match
     * @param {string} dummyDataPhase - the phase selected by the dummy data toggle, e.g. 'First Innings'
     */
    const getCommentary = async ( matchId, dummyDataPhase ) => {

        const params = {
            enrichIds: true
        };
        let url = getMatchCommentaryUrl( matchId, params );

        // TEMP
        if ( dummyDataPhase === `Practice Game 1 (${ PRACTICE_GAME_1_ID })` ) {
            url = getMatchCommentaryUrl( PRACTICE_GAME_1_ID, params );
        } else if ( dummyDataPhase === `Practice Game 2 (${ PRACTICE_GAME_2_ID })` ) {
            url = getMatchCommentaryUrl( PRACTICE_GAME_2_ID, params );
        } else if ( dummyDataPhase === `Practice Game 3 (${ PRACTICE_GAME_3_ID })` ) {
            url = getMatchCommentaryUrl( PRACTICE_GAME_3_ID, params );
        } else if ( dummyDataPhase === `Practice Game 4 (${ PRACTICE_GAME_4_ID })` ) {
            url = getMatchCommentaryUrl( PRACTICE_GAME_4_ID, params );
        } else if ( dummyDataPhase === `Practice Game 5 (${ PRACTICE_GAME_5_ID })` ) {
            url = getMatchCommentaryUrl( PRACTICE_GAME_5_ID, params );
        }
        // END TEMP

        try {

            // TEMP - for dummy commentary data
            let data;
            if ( dummyDataPhase && !dummyDataPhase.includes( 'Practice Game' ) ) {
                const result = await axios.get( MATCH_PHASES[ dummyDataPhase ] );
                data = result.data;
                if ( process.env.NODE_ENV === 'development' ) {
                    console.log( '%c%s', 'color: saddlebrown; background: lemonchiffon;', 'DUMMY', 'COMMENTARY DATA:', data ); // eslint-disable-line no-console
                }
            } else { // END TEMP
                const result = await axios.get( url );
                data = result.data;
            }
            dispatch( {
                type: GET_COMMENTARY,
                payload: {
                    commentary: data
                }
            } );

        } catch ( err ) {
            console.error( 'COMMENTARY REQUEST ERRORED: ', err );
            dispatch( {
                type: COMMENTARY_ERROR,
                payload: err.response
            } );
        }
    };

    return (
        <CommentaryContext.Provider
            value={ {
                commentary: state.commentary,
                loading: state.loading,
                error: state.error,
                getCommentary,
            } }>
            { children }
        </CommentaryContext.Provider>
    );
}

CommentaryState.propTypes = {
    children: PropTypes.node
};

export default CommentaryState;
import React, { useReducer } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import { CMS_ACCOUNT } from 'scripts/config';
import { MENS_TOURNAMENT_ID, WOMENS_TOURNAMENT_ID } from 'scripts/constants';
import { getMatchesUrl } from 'scripts/endpoints';

import MatchesContext from './matchesContext';
import { matchesReducer } from './matchesReducer';

import {
    GET_MATCHES,
    MATCHES_ERROR,
} from '../types';

const MatchesState = props => {
    
    const initialState = {
        matches: null,
        loading: true,
        error: null
    };

    const [ state, dispatch ] = useReducer( matchesReducer, initialState );

    const getMatches = async () => {

        const params = {
            page: 0,
            pageSize: 80,
            sortParameter: 'START_DATE',
            sort: 'asc',
            tournamentIds: `${ MENS_TOURNAMENT_ID },${ WOMENS_TOURNAMENT_ID }`,
        };

        const url = getMatchesUrl( params );

        try {
            const res = await axios.get( url, {
                headers: {
                    account: CMS_ACCOUNT // account needed to retrieve metadata
                }
            } );
            dispatch( {
                type: GET_MATCHES,
                payload: res.data
            } );
        } catch ( err ) {
            dispatch( {
                type: MATCHES_ERROR,
                payload: err.response
            } );
        }
    };

    return (
        <MatchesContext.Provider
            value={ {
                matches: state.matches,
                loading: state.loading,
                error: state.error,
                getMatches,
            } }>
            { props.children }
        </MatchesContext.Provider>
    );
};

MatchesState.propTypes = {
    children: PropTypes.node,
};

export default MatchesState;
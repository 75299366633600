import { EVENTS, BRIGHTCOVE_PLAYER_SCRIPT } from 'scripts/constants';
import { dispatchEvent } from './event';

/**
 * Queries the DOM for video elements to initialise Brightcove on
 */
export function initialiseVideos() {
    
    const PLAYER_NAMES = [];
    const IS_LOADED_ATTR = 'data-is-loaded';

    /**
     * Using handlebars syntax, replaces keys in a string with
     * values from a corresponding map to create a video script src
     *
     * @param {object.<string, string>} videoDetails - key val map of video details to replace in the scriptSrc string
     * @returns {string} returns the formatted video script src string or an empty string
     */
    const formatVideoScriptSrc = videoDetails => {

        let videoPlayerScript = BRIGHTCOVE_PLAYER_SCRIPT;

        if ( videoDetails ) {
            var mapKeys = Object.keys( videoDetails );
            for ( let i = 0; i < mapKeys.length; i++ ) {
                videoPlayerScript = videoPlayerScript.replace( '{{' + mapKeys[ i ] + '}}', videoDetails[ mapKeys[ i ] ] ); // eslint-disable-line no-param-reassign
            }
            return videoPlayerScript;
        }
        return false;
    };

    /**
     * Creates the individual script element for the Brightcove Player
     *
     * @param {HTMLElement} element - the <video> element that contains player info attributes
     * @returns {Promise} a promise to create a video script element for this video
     */
    const createVideoScriptElement = element => {

        return new Promise( ( resolve, reject ) => {

            const videoDetails = {
                accountId: element.getAttribute( 'data-account' ),
                playerId: element.getAttribute( 'data-player' ),
                playerName: element.getAttribute( 'id' )
            };

            if ( !videoDetails.accountId || !videoDetails.playerId ) {
                console.warn( 'Attempting to initialise video with no account or player ID.' );
                reject();
            }

            // search the DOM for existing instances of this script element as there's no reason to add duplicates
            const existingVideoScriptElement = document.querySelector( `script[data-player=${ videoDetails.playerId }]` );
            if ( existingVideoScriptElement && existingVideoScriptElement.nodeName === 'SCRIPT' ) { // the script for this video already exists so reject
                reject();
            }

            /**
             * Push this player's name into the PLAYER_NAMES array
             * for use in initialising player events within createVideoScriptElements
             */
            if ( PLAYER_NAMES.indexOf( videoDetails.playerName ) === -1 ) {
                PLAYER_NAMES.push( videoDetails.playerName );
            }

            const scriptSrc = formatVideoScriptSrc( videoDetails );
            let scriptEl = document.createElement( 'script' );
            scriptEl.setAttribute( 'data-player', videoDetails.playerName );
            document.body.appendChild( scriptEl );

            scriptEl.onerror = () => {
                console.warn( `Video script errored: ${ scriptEl }` );
                // Remove the player id from list of PLAYER_NAMES because the player does not exist
                const indexOfPlayerId = PLAYER_NAMES.indexOf( videoDetails.playerName );
                if ( indexOfPlayerId > -1 ) {
                    PLAYER_NAMES.splice( indexOfPlayerId, 1 );
                }
                scriptEl.setAttribute( IS_LOADED_ATTR, 'false' );
                reject();
            };

            scriptEl.onload = () => {
                scriptEl.setAttribute( IS_LOADED_ATTR, 'true' );
                resolve( videoDetails.playerName );
            };

            scriptEl.src = scriptSrc;
        } );
    };

    /**
     * create the brightcove player and add a representation of it to the global brightcove object
     *
     * @param {string} playerName the player id
     */
    const createVideoPlayer = playerName => {
        
        videojs( playerName ).ready( function() {

            videojs( this.id_ ).on( 'play', function() {
                dispatchEvent( EVENTS.VIDEO.PLAY, {}, document.body );
            } );

            videojs( this.id_ ).on( 'pause', function() {
                dispatchEvent( EVENTS.VIDEO.PAUSE, {}, document.body );
            } );

            videojs( this.id_ ).on( 'ended', function() {
                dispatchEvent( EVENTS.VIDEO.END, {}, document.body );
            } );
        } );
    };

    /**
     * Finds all <video> elements on a page
     * Resolves the Promise that creates a script for each one, providing a duplicate script doesn't already exist
     */
    const createVideoScriptElements = () => {
        const videos = [].slice.call( document.querySelectorAll( 'video' ) );
        videos.forEach( video => {
            createVideoScriptElement( video ).then( playerName => {
                createVideoPlayer( playerName );
            } ).catch( err => {
                console.error( `Failed initialising video script: ${ err }` );
            } );
        } );
    };

    createVideoScriptElements();
}

export const playVideo = playerName => {
    if ( videojs ) {
        videojs( playerName ).play();
    } else {
        console.error( 'Could not play video as the video script is not present.' );
    }
};

export const pauseVideo = playerName => {
    if ( videojs ) {
        videojs( playerName ).pause();
    } else {
        console.error( 'Could not pause video as the video script is not present.' );
    }
};
import React, { useState } from 'react';
import PropTypes from 'prop-types';

/**
 * Displays an image using a class when it has loaded
 * Used in conjunction with Lazy Load
 *
 * @param {object} props - properties passed to the component
 * @param {string} props.src - src ODIR URL for an image
 * @param {Array<object>} props.srcConfigs - object containing srcSet string of ODIR URLs, and a mediaQuery to control them
 * @param {string} props.alt - image alt text
 * @param {string} props.pictureClassName - additional CSS className for the <picture> element
 * @param {string} props.imgClassName - additional CSS className for the <img> element
 * @returns {object} React render
 */
function ImageLoader( { src, srcConfigs, alt, pictureClassName, imgClassName } ) {

    const [ imageLoaded, setImageLoaded ] = useState( false );

    const onLoad = () => {
        setImageLoaded( true );
    };

    return (
        <picture className={ `lazy-image__picture ${ pictureClassName || '' }` }>
            {
                srcConfigs && srcConfigs.length > 0 ? (
                    srcConfigs.map( ( srcConfig, i ) => {
                        return <source srcSet={ srcConfig.srcSet } media={ srcConfig.mediaQuery } key={ i }/>;
                    } )
                ) : (
                    null
                )
            }
            <img
                className={ `lazy-image__img ${ imgClassName || '' } ${ imageLoaded ? 'is-loaded' : '' }` }
                src={ src }
                alt={ alt || 'Image' }
                onLoad={ () => onLoad() }/>
        </picture>
    );
}

ImageLoader.propTypes = {
    src: PropTypes.string.isRequired,
    srcConfigs: PropTypes.array,
    alt: PropTypes.string,
    pictureClassName: PropTypes.string,
    imgClassName: PropTypes.string
};

export default ImageLoader;
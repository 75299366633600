import React, { useContext, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import matchesContext from 'scripts/context/matches/matchesContext';

import { en } from 'scripts/translations';

import Loader from 'scripts/components/common/Loader/Loader';
import Tabs from 'scripts/components/common/Tabs/Tabs';
import PageHeader from 'scripts/components/common/Header/PageHeader';

import MatchesHeader from './MatchesHeader';
import MatchCard from './MatchCard';

import { ReactComponent as InPlayIcon } from 'i/icons/in-play.svg';

/**
 * List of all matches included in this tournament, separated by live, upcoming and completed
 *
 * @returns {object} React render
 */
function Matches() {

    const { matches, getMatches, loading } = useContext( matchesContext );

    useEffect( () => {
        getMatches();
        // eslint-disable-next-line
    }, [] );
    
    if ( matches !== null && matches.length === 0 && !loading ) {
        return (
            <div className="app app--no-nav">
                <main className="app__main">
                    <PageHeader title={ `${ en.no } ${ en.matches }` } description={ en.checkBack } />
                </main>
            </div>
        );
    }

    const headerTransitionOptions = {
        'in': true,
        'appear': true,
        'timeout': 0,
        'classNames': 'match-header',
        'unmountOnExit': true
    };

    const bodyTransitionOptions = {
        'in': true,
        'appear': true,
        'timeout': 100,
        'classNames': 'matches',
        'unmountOnExit': true
    };
    
    return (
        <div className="app app--no-nav">
            <main className="app__main">
                <div className="matches">
                    <CSSTransition { ...headerTransitionOptions }>
                        <MatchesHeader title="Matches"/>
                    </CSSTransition>
                    <div className="wrapper">
                        {
                            loading ? (
                                <Loader />
                            ) : (
                                <CSSTransition { ...bodyTransitionOptions }>
                                    <section className="matches reveal-component">
                                        {
                                            matches.live.length > 0 &&
                                            <div className="matches__live">
                                                <h3 className="matches__day-title matches__day-title--live">
                                                    { en.liveNow }
                                                    <div className="matches__in-play-icon">
                                                        <InPlayIcon className="in-play-icon--light" />
                                                    </div>
                                                </h3>
                                                {
                                                    matches.live.map( match => {
                                                        return (
                                                            <div className="matches__day" key={ match.matchId }>
                                                                <MatchCard match={ match } />
                                                            </div>
                                                        );
                                                    } )
                                                }
                                            </div>
                                        }
                                        <Tabs className="matches__tabs">
                                            <div tabname={ en.upcoming }>
                                                {
                                                    Object.keys( matches.upcoming ).length > 0 ? (
                                                        <ol className="matches__list">
                                                            {
                                                                Object.keys( matches.upcoming ).map( ( matchDay, i ) => {
                                                                    return (
                                                                        <li className="matches__day" key={ i }>
                                                                            <h3 className="matches__day-title">{ matchDay }</h3>
                                                                            {
                                                                                matches.upcoming[ matchDay ].map( ( match, m ) => ( // eslint-disable-line id-length
                                                                                    <div className="matches__match" key={ m }>
                                                                                        <MatchCard match={ match }/>
                                                                                    </div>
                                                                                ) )
                                                                            }
                                                                        </li>
                                                                    );
                                                                } )
                                                            }
                                                        </ol>
                                                    ) : (
                                                        <h4 className="matches__no-matches">No Upcoming Matches</h4>
                                                    )
                                                }
                                            </div>
                                            <div tabname={ en.completed }>
                                                {
                                                    Object.keys( matches.completed ).length > 0 ? (
                                                        <ol className="matches__list">
                                                            {
                                                                Object.keys( matches.completed ).map( ( matchDay, i ) => {
                                                                    return (
                                                                        <li className="matches__day" key={ i }>
                                                                            <h3 className="matches__day-title">{ matchDay }</h3>
                                                                            {
                                                                                matches.completed[ matchDay ].map( ( match, m ) => ( // eslint-disable-line id-length
                                                                                    <div className="matches__match" key={ m }>
                                                                                        <MatchCard match={ match }/>
                                                                                    </div>
                                                                                ) )
                                                                            }
                                                                        </li>
                                                                    );
                                                                } )
                                                            }
                                                        </ol>
                                                    ) : (
                                                        <h4 className="matches__no-matches">No Completed Matches</h4>
                                                    )
                                                }
                                            </div>
                                        </Tabs>
                                    </section>
                                </CSSTransition>
                            )
                        }
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Matches;

import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import matchContext from 'scripts/context/match/matchContext';
import { en } from 'scripts/translations';

import NavigationItem from './NavigationItem';

import { ReactComponent as NavigationBackground } from 'i/elements/menu-background.svg';
import { ReactComponent as HundredLogo } from 'i/elements/the-hundred-logo-condensed.svg';
import { ReactComponent as MatchesIcon } from 'i/icons/bat.svg';
import { ReactComponent as LatestIcon } from 'i/icons/latest.svg';
import { ReactComponent as LiveStreamIcon } from 'i/icons/live-stream.svg';
import ScoreIcon from './ScoreIcon';
import { ReactComponent as BallByBallIcon } from 'i/icons/ball.svg';
import { ReactComponent as SquadsIcon } from 'i/icons/squad.svg';
import { ReactComponent as LeagueIcon } from 'i/icons/league.svg';
import { ReactComponent as RulesIcon } from 'i/icons/rules.svg';

const LIVE_STREAM_POSITION = 2;

/**
 * Main sidebar navigation when viewing a match
 *
 * @returns {object} React render
 */
function Navigation() {

    const { parentDomain } = window.environment;
    const { matchId } = useParams();
    const { match } = useContext( matchContext );
    const { info, scorecard, liveStreamVideo, fixtureNoScoring } = match;

    let matchType = info.type;
    if ( !matchType && fixtureNoScoring ) {
        matchType = fixtureNoScoring.info.type;
    }

    const routes = [
        {
            path: `${ parentDomain }/home`,
            enabled: true,
            hideOnMobile: true,
            external: true,
            label: en.home,
            icon: <HundredLogo className="navigation__link-icon" alt="Hundred Logo" />
        },
        {
            path: `/${ matchId }/latest`,
            enabled: true,
            hideOnMobile: false,
            external: false,
            label: en.latest,
            icon: <LatestIcon className="navigation__link-icon" alt="Latest Icon" />
        },
        // live stream link is here if there is a live stream video
        {
            path: `/${ matchId }/scorecard`,
            enabled: true,
            hideOnMobile: false,
            external: false,
            label: en.scorecard,
            icon: <ScoreIcon />
        },
        {
            path: `/${ matchId }/ball-by-ball`,
            enabled: true,
            hideOnMobile: false,
            external: false,
            label: en.ballByBall,
            icon: <BallByBallIcon className="navigation__link-icon" alt="Ball Icon" />
        },
        {
            path: `/${ matchId }/squads`,
            enabled: true,
            hideOnMobile: false,
            external: false,
            label: info.teamsAnnounced ? en.lineups : en.squads,
            icon: <SquadsIcon className="navigation__link-icon" alt="Squads Icon" />
        },
        {
            path: '/',
            exact: true, // needs to match route exactly to render (otherwise all routes would match this)
            enabled: true,
            hideOnMobile: true,
            external: false,
            label: en.matches,
            icon: <MatchesIcon className="navigation__link-icon" alt="Matches Icon" />
        },
        {
            path: `${ parentDomain }/standings/${ matchType === 'MEN' ? 'men' : 'women' }`,
            enabled: true,
            hideOnMobile: false,
            external: true,
            label: en.league,
            icon: <LeagueIcon className="navigation__link-icon" alt="League Icon" />
        },
        {
            path: `${ parentDomain }/info/competition-rules`,
            enabled: true,
            hideOnMobile: true,
            external: true,
            label: en.rules,
            icon: <RulesIcon className="navigation__link-icon" alt="League Icon" />
        }
    ];

    if ( liveStreamVideo ) {
        const liveStream = {
            path: `/${ matchId }/live-stream`,
            enabled: true,
            hideOnMobile: true,
            external: false,
            label: en.liveStream,
            icon: <LiveStreamIcon className="navigation__link-icon" alt="Live Stream Icon" />
        };
        routes.splice( LIVE_STREAM_POSITION, 0, liveStream ); // as there's a live stream add a link to it after "Latest" but before the "Scorecard"
    }

    if ( !info.teamsAnnounced || !scorecard ) {
        const scorecardLink = routes.find( ( { label } ) => label === en.scorecard );
        if ( scorecardLink ) {
            scorecardLink.enabled = false;
        }
    }

    if ( info.state === 'U' || !scorecard ) {
        const ballByBallLink = routes.find( ( { label } ) => label === en.ballByBall );
        if ( ballByBallLink ) {
            ballByBallLink.enabled = false;
        }
    }

    return (
        <nav className={ `navigation ${ info.state === 'C' ? 'navigation--match-complete' : '' }` }>
            <NavigationBackground className="navigation__background u-show-tablet"/>
            <ul className="navigation__list">
                {
                    routes.map( route => (
                        <NavigationItem route={ route } key={ route.label } />
                    ) )
                }
            </ul>
        </nav>
    );
}

export default Navigation;

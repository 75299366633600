import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

// Original badges
import { ReactComponent as BPBadge } from 'i/team-logos/birmingham-phoenix.svg';
import { ReactComponent as LSBadge } from 'i/team-logos/london-spirit.svg';
import { ReactComponent as MOBadge } from 'i/team-logos/manchester-originals.svg';
import { ReactComponent as NSBadge } from 'i/team-logos/northern-superchargers.svg';
import { ReactComponent as OIBadge } from 'i/team-logos/oval-invincibles.svg';
import { ReactComponent as SBBadge } from 'i/team-logos/southern-brave.svg';
import { ReactComponent as TRBadge } from 'i/team-logos/trent-rockets.svg';
import { ReactComponent as WFBadge } from 'i/team-logos/welsh-fire.svg';

// White Badges
import { ReactComponent as BPWhiteBadge } from 'i/team-logos/birmingham-phoenix-white.svg';
import { ReactComponent as LSWhiteBadge } from 'i/team-logos/london-spirit-white.svg';
import { ReactComponent as MOWhiteBadge } from 'i/team-logos/manchester-originals-white.svg';
import { ReactComponent as NSWhiteBadge } from 'i/team-logos/northern-superchargers-white.svg';
import { ReactComponent as OIWhiteBadge } from 'i/team-logos/oval-invincibles-white.svg';
import { ReactComponent as SBWhiteBadge } from 'i/team-logos/southern-brave-white.svg';
import { ReactComponent as TRWhiteBadge } from 'i/team-logos/trent-rockets-white.svg';
import { ReactComponent as WFWhiteBadge } from 'i/team-logos/welsh-fire-white.svg';

// Black Badges
import { ReactComponent as BPBlackBadge } from 'i/team-logos/birmingham-phoenix-black.svg';
import { ReactComponent as LSBlackBadge } from 'i/team-logos/london-spirit-black.svg';
import { ReactComponent as MOBlackBadge } from 'i/team-logos/manchester-originals-black.svg';
import { ReactComponent as NSBlackBadge } from 'i/team-logos/northern-superchargers-black.svg';
import { ReactComponent as OIBlackBadge } from 'i/team-logos/oval-invincibles-black.svg';
import { ReactComponent as SBBlackBadge } from 'i/team-logos/southern-brave-black.svg';
import { ReactComponent as TRBlackBadge } from 'i/team-logos/trent-rockets-black.svg';
import { ReactComponent as WFBlackBadge } from 'i/team-logos/welsh-fire-black.svg';

// Symbols
import { ReactComponent as BPSymbol } from 'i/team-logos/birmingham-phoenix-symbol.svg';
import { ReactComponent as LSSymbol } from 'i/team-logos/london-spirit-symbol.svg';
import { ReactComponent as MOSymbol } from 'i/team-logos/manchester-originals-symbol.svg';
import { ReactComponent as NSSymbol } from 'i/team-logos/northern-superchargers-symbol.svg';
import { ReactComponent as OISymbol } from 'i/team-logos/oval-invincibles-symbol.svg';
import { ReactComponent as SBSymbol } from 'i/team-logos/southern-brave-symbol.svg';
import { ReactComponent as TRSymbol } from 'i/team-logos/trent-rockets-symbol.svg';
import { ReactComponent as WFSymbol } from 'i/team-logos/welsh-fire-symbol.svg';

// Alternate Badges
import { ReactComponent as NSAlternateBadge } from 'i/team-logos/northern-superchargers-alternate.svg';

// Defaults
import { ReactComponent as TBDBadge } from 'i/team-logos/tbd.svg';

/**
 * Render a team badge
 *
 * @param {object} props - properties passed to the component
 * @param {string} props.abbr - the team abbreviation, usually a three letter string
 * @param {string} props.className - additional CSS class for the badge
 * @param {string} props.type - the badge 'type', or location it is to be used, see getBadgeType() for possibilities and default
 * @returns {object} React render
 */
function TeamBadge( { abbr, className, type } ) {

    // eslint-disable-next-line complexity
    const getOriginalBadge = () => {
        switch ( abbr ) {
            case 'BP':
            case 'BirmPW':
            case 'BirmPh':
                return <BPBadge className={ className } alt="Birmingham Phoenix team badge"/>;
            case 'LS':
            case 'LondSW':
            case 'LondSM':
                return <LSBadge className={ className } alt="London Spirit team badge"/>;
            case 'MO':
            case 'MancOW':
            case 'MancO':
                return <MOBadge className={ className } alt="Manchester Originals team badge"/>;
            case 'NS':
            case 'NSupW':
            case 'NthSup':
                return <NSBadge className={ className } alt="Northern Superchargers team badge"/>;
            case 'OI':
            case 'OvalIW':
            case 'OvalIM':
                return <OIBadge className={ className } alt="Oval Invincibles team badge"/>;
            case 'SB':
            case 'SthBrW':
            case 'SthBrM':
                return <SBBadge className={ className } alt="Southern Brave team badge"/>;
            case 'TR':
            case 'TreRoW':
            case 'TreRoM':
                return <TRBadge className={ className } alt="Trent Rockets team badge"/>;
            case 'WF':
            case 'WelFW':
            case 'WelshF':
                return <WFBadge className={ className } alt="Welsh Fire team badge"/>;
            default:
                return <TBDBadge className={ className } alt="Team to be decided"/>;
        }
    };
    const originalBadge = useMemo( () => getOriginalBadge( abbr ), [ abbr ] );

    // eslint-disable-next-line complexity
    const getWhiteBadge = () => {
        switch ( abbr ) {
            case 'BP':
            case 'BirmPW':
            case 'BirmPh':
                return <BPWhiteBadge className={ className } alt="Birmingham Phoenix team badge"/>;
            case 'LS':
            case 'LondSW':
            case 'LondSM':
                return <LSWhiteBadge className={ className } alt="London Spirit team badge"/>;
            case 'MO':
            case 'MancOW':
            case 'MancO':
                return <MOWhiteBadge className={ className } alt="Manchester Originals team badge"/>;
            case 'NS':
            case 'NSupW':
            case 'NthSup':
                return <NSWhiteBadge className={ className } alt="Northern Superchargers team badge"/>;
            case 'OI':
            case 'OvalIW':
            case 'OvalIM':
                return <OIWhiteBadge className={ className } alt="Oval Invincibles team badge"/>;
            case 'SB':
            case 'SthBrW':
            case 'SthBrM':
                return <SBWhiteBadge className={ className } alt="Southern Brave team badge"/>;
            case 'TR':
            case 'TreRoW':
            case 'TreRoM':
                return <TRWhiteBadge className={ className } alt="Trent Rockets team badge"/>;
            case 'WF':
            case 'WelFW':
            case 'WelshF':
                return <WFWhiteBadge className={ className } alt="Welsh Fire team badge"/>;
            default:
                return <TBDBadge className={ className } alt="Team to be decided"/>;
        }
    };
    const whiteBadge = useMemo( () => getWhiteBadge( abbr ), [ abbr ] );

    // eslint-disable-next-line complexity
    const getBlackBadge = () => {
        switch ( abbr ) {
            case 'BP':
            case 'BirmPW':
            case 'BirmPh':
                return <BPBlackBadge className={ className } alt="Birmingham Phoenix team badge"/>;
            case 'LS':
            case 'LondSW':
            case 'LondSM':
                return <LSBlackBadge className={ className } alt="London Spirit team badge"/>;
            case 'MO':
            case 'MancOW':
            case 'MancO':
                return <MOBlackBadge className={ className } alt="Manchester Originals team badge"/>;
            case 'NS':
            case 'NSupW':
            case 'NthSup':
                return <NSBlackBadge className={ className } alt="Northern Superchargers team badge"/>;
            case 'OI':
            case 'OvalIW':
            case 'OvalIM':
                return <OIBlackBadge className={ className } alt="Oval Invincibles team badge"/>;
            case 'SB':
            case 'SthBrW':
            case 'SthBrM':
                return <SBBlackBadge className={ className } alt="Southern Brave team badge"/>;
            case 'TR':
            case 'TreRoW':
            case 'TreRoM':
                return <TRBlackBadge className={ className } alt="Trent Rockets team badge"/>;
            case 'WF':
            case 'WelFW':
            case 'WelshF':
                return <WFBlackBadge className={ className } alt="Welsh Fire team badge"/>;
            default:
                return <TBDBadge className={ className } alt="Team to be decided"/>;
        }
    };
    const blackBadge = useMemo( () => getBlackBadge( abbr ), [ abbr ] );

    // eslint-disable-next-line complexity
    const getSymbolBadge = () => {
        switch ( abbr ) {
            case 'BP':
            case 'BirmPW':
            case 'BirmPh':
                return <BPSymbol className={ className } alt="Birmingham Phoenix team badge"/>;
            case 'LS':
            case 'LondSW':
            case 'LondSM':
                return <LSSymbol className={ className } alt="London Spirit team badge"/>;
            case 'MO':
            case 'MancOW':
            case 'MancO':
                return <MOSymbol className={ className } alt="Manchester Originals team badge"/>;
            case 'NS':
            case 'NSupW':
            case 'NthSup':
                return <NSSymbol className={ className } alt="Northern Superchargers team badge"/>;
            case 'OI':
            case 'OvalIW':
            case 'OvalIM':
                return <OISymbol className={ className } alt="Oval Invincibles team badge"/>;
            case 'SB':
            case 'SthBrW':
            case 'SthBrM':
                return <SBSymbol className={ className } alt="Southern Brave team badge"/>;
            case 'TR':
            case 'TreRoW':
            case 'TreRoM':
                return <TRSymbol className={ className } alt="Trent Rockets team badge"/>;
            case 'WF':
            case 'WelFW':
            case 'WelshF':
                return <WFSymbol className={ className } alt="Welsh Fire team badge"/>;
            default:
                return <TBDBadge className={ className } alt="Team to be decided"/>;
        }
    };
    const symbolBadge = useMemo( () => getSymbolBadge( abbr ), [ abbr ] );

    // eslint-disable-next-line complexity
    const getMatchListLightBadge = () => {
        switch ( abbr ) {
            case 'BP':
            case 'BirmPW':
            case 'BirmPh':
                return <BPBadge className={ className } alt="Birmingham Phoenix team badge"/>;
            case 'LS':
            case 'LondSW':
            case 'LondSM':
                return <LSBadge className={ className } alt="London Spirit team badge"/>;
            case 'MO':
            case 'MancOW':
            case 'MancO':
                return <MOBadge className={ className } alt="Manchester Originals team badge"/>;
            case 'NS':
            case 'NSupW':
            case 'NthSup':
                return <NSAlternateBadge className={ className } alt="Northern Superchargers team badge"/>;
            case 'OI':
            case 'OvalIW':
            case 'OvalIM':
                return <OIBlackBadge className={ className } alt="Oval Invincibles team badge"/>;
            case 'SB':
            case 'SthBrW':
            case 'SthBrM':
                return <SBBadge className={ className } alt="Southern Brave team badge"/>;
            case 'TR':
            case 'TreRoW':
            case 'TreRoM':
                return <TRBadge className={ className } alt="Trent Rockets team badge"/>;
            case 'WF':
            case 'WelFW':
            case 'WelshF':
                return <WFBadge className={ className } alt="Welsh Fire team badge"/>;
            default:
                return <TBDBadge className={ className } alt="Team to be decided"/>;
        }
    };
    const matchListLightBadge = useMemo( () => getMatchListLightBadge( abbr ), [ abbr ] );

    // eslint-disable-next-line complexity
    const getMatchListDarkBadge = () => {
        switch ( abbr ) {
            case 'BP':
            case 'BirmPW':
            case 'BirmPh':
                return <BPBadge className={ className } alt="Birmingham Phoenix team badge"/>;
            case 'LS':
            case 'LondSW':
            case 'LondSM':
                return <LSWhiteBadge className={ className } alt="London Spirit team badge"/>;
            case 'MO':
            case 'MancOW':
            case 'MancO':
                return <MOWhiteBadge className={ className } alt="Manchester Originals team badge"/>;
            case 'NS':
            case 'NSupW':
            case 'NthSup':
                return <NSWhiteBadge className={ className } alt="Northern Superchargers team badge"/>;
            case 'OI':
            case 'OvalIW':
            case 'OvalIM':
                return <OIWhiteBadge className={ className } alt="Oval Invincibles team badge"/>;
            case 'SB':
            case 'SthBrW':
            case 'SthBrM':
                return <SBWhiteBadge className={ className } alt="Southern Brave team badge"/>;
            case 'TR':
            case 'TreRoW':
            case 'TreRoM':
                return <TRWhiteBadge className={ className } alt="Trent Rockets team badge"/>;
            case 'WF':
            case 'WelFW':
            case 'WelshF':
                return <WFWhiteBadge className={ className } alt="Welsh Fire team badge"/>;
            default:
                return <TBDBadge className={ className } alt="Team to be decided"/>;
        }
    };
    const matchListDarkBadge = useMemo( () => getMatchListDarkBadge( abbr ), [ abbr ] );

    // eslint-disable-next-line complexity
    const getPostMatchHeaderBadge = () => {
        switch ( abbr ) {
            case 'BP':
            case 'BirmPW':
            case 'BirmPh':
                return <BPBadge className={ className } alt="Birmingham Phoenix team badge"/>;
            case 'LS':
            case 'LondSW':
            case 'LondSM':
                return <LSWhiteBadge className={ className } alt="London Spirit team badge"/>;
            case 'MO':
            case 'MancOW':
            case 'MancO':
                return <MOWhiteBadge className={ className } alt="Manchester Originals team badge"/>;
            case 'NS':
            case 'NSupW':
            case 'NthSup':
                return <NSBadge className={ className } alt="Northern Superchargers team badge"/>;
            case 'OI':
            case 'OvalIW':
            case 'OvalIM':
                return <OIBlackBadge className={ className } alt="Oval Invincibles team badge"/>;
            case 'SB':
            case 'SthBrW':
            case 'SthBrM':
                return <SBBlackBadge className={ className } alt="Southern Brave team badge"/>;
            case 'TR':
            case 'TreRoW':
            case 'TreRoM':
                return <TRBadge className={ className } alt="Trent Rockets team badge"/>;
            case 'WF':
            case 'WelFW':
            case 'WelshF':
                return <WFWhiteBadge className={ className } alt="Welsh Fire team badge"/>;
            default:
                return <TBDBadge className={ className } alt="Team to be decided"/>;
        }
    };
    const postMatchHeaderBadge = useMemo( () => getPostMatchHeaderBadge( abbr ), [ abbr ] );

    const getBadgeType = useCallback( () => {
        switch ( type ) {
            case 'light':
                return blackBadge;
            case 'dark':
                return whiteBadge;
            case 'symbol':
                return symbolBadge;
            case 'matchListLight':
                return matchListLightBadge;
            case 'matchListDark':
                return matchListDarkBadge;
            case 'postMatchHeader':
                return postMatchHeaderBadge;
            default:
                return originalBadge;
        }
    }, [ abbr ] );

    return (
        getBadgeType( type )
    );
}

TeamBadge.propTypes = {
    abbr: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string
};

export default TeamBadge;
import React from 'react';

import { en } from 'scripts/translations';

import { ReactComponent as Logo } from 'i/elements/the-hundred-logo.svg';
import { ReactComponent as Chevrons } from 'i/chevrons/header.svg';
import { ReactComponent as RulesIcon } from 'i/icons/rules.svg';
import { ReactComponent as ArrowLeftIcon } from 'i/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from 'i/icons/arrow-right.svg';

/**
 * Header that provides navigation back to the Matches selector
 *
 * @returns {object} React render
 */
export default function MatchHeader() {
    
    const { parentDomain } = window.environment;

    return (
        <header className="match-header u-show-tablet">
            <div className="match-header__left">
                <a className="match-header__matches-link" href={ `${ parentDomain }/home` } target="_blank" rel="noopener noreferrer">
                    <ArrowLeftIcon className="match-header__matches-link-arrow"/>
                    <Logo className="match-header__logo"/>
                </a>
            </div>
            <div className="match-header__right">
                <a className="match-header__rules-link" href={ `${ parentDomain }/rules` }>
                    <span className="match-header__rules-link-text">{ en.competitionRules }</span>
                    <RulesIcon className="match-header__rules-link-icon"/>
                    <ArrowRightIcon className="match-header__rules-link-arrow"/>
                </a>
            </div>
            <Chevrons className="match-header__chevrons"/>
        </header>
    );
}
import React, { useContext, useState, useEffect } from 'react';

import {
    MEASUREMENTS,
    STYLE_CLS_NAMES
} from 'scripts/constants';
import { useWindowWidth } from 'scripts/hooks';
import { en } from 'scripts/translations';

import matchContext from 'scripts/context/match/matchContext';

import TeamBadge from 'scripts/components/common/TeamBadge/TeamBadge';
import Explainer from 'scripts/components/common/Explainer/Explainer';

/**
 * Displays data from CricViz's WinViz endpoint to determine who is more likely to win the match
 *
 * @returns {object} React render
 */
function WinPredictor() {

    const TWENTY_FIVE_PERCENT = 25;

    const { match } = useContext( matchContext );
    const { teams, winViz } = match;
    
    const windowWidth = useWindowWidth();
    const [ isMobile, setIsMobile ] = useState( windowWidth < MEASUREMENTS.TABLET );

    const [ activeTooltip, setActiveTooltip ] = useState( null );

    const toggleTooltip = toolTipName => {
        if ( toolTipName !== activeTooltip ) {
            setActiveTooltip( toolTipName );
        } else {
            setActiveTooltip( null );
        }
    };

    useEffect( () => {
        setIsMobile( windowWidth < MEASUREMENTS.TABLET );
    }, [ windowWidth ] );

    if ( !winViz ) {
        return null;
    }

    return (
        <div className="win-predictor">
            {
                isMobile &&
                    <div className="win-predictor__header">
                        <h3 className="win-predictor__title">{ en.match } { en.predictor }</h3>
                        <p className="win-predictor__subtitle">{ en.chanceToWin }</p>
                        <Explainer
                            title={ en.explained }
                            tooltipText={ en.predictorDescription }
                            tooltipModifier="tooltip--right"
                            className="win-predictor__explainer"/>
                    </div>
            }
            <div className="win-predictor__chart">
                <div className={ `win-predictor__bar win-predictor__bar--home t-team-theme t-${ teams.home.className } ${ activeTooltip === 'home' ? STYLE_CLS_NAMES.IS_ACTIVE : '' }` } style={ { width: `${ winViz.homePercent }%` } } onClick={ () => toggleTooltip( 'home' ) }>
                    <div className="win-predictor__bar-inner">
                        <TeamBadge
                            abbr={ teams.home.abbr }
                            className="win-predictor__team-badge"
                            type="symbol" />
                        <div className="win-predictor__info">
                            <span className="win-predictor__percent">{ `${ winViz.homePercent }%` }</span>
                            <span className="win-predictor__text u-hide-phablet">{ en.chanceToWin }</span>
                        </div>
                    </div>
                    {
                        winViz.homePercent <= TWENTY_FIVE_PERCENT &&
                            <span className="win-predictor__tooltip tooltip tooltip--left">{ `${ winViz.homePercent }%` } { en.chanceToWin }</span>
                    }
                </div>
                {
                    winViz.tiePercent > 0 &&
                        <div className={ `win-predictor__bar win-predictor__bar--tie ${ activeTooltip === 'tie' ? STYLE_CLS_NAMES.IS_ACTIVE : '' }` } style={ { width: `${ winViz.tiePercent }%` } } onClick={ () => toggleTooltip( 'tie' ) }>
                            <div className="win-predictor__bar-inner">
                                <div className="win-predictor__info">
                                    <span className="win-predictor__percent">{ `${ winViz.tiePercent }%` }</span>
                                    <span className="win-predictor__text u-hide-phablet">{ en.chanceToTie }</span>
                                </div>
                            </div>
                            {
                                winViz.tiePercent <= TWENTY_FIVE_PERCENT &&
                                    <span className="win-predictor__tooltip tooltip">{ `${ winViz.tiePercent }%` } { en.chanceToTie }</span>
                            }
                        </div>
                }
                <div className={ `win-predictor__bar win-predictor__bar--away t-team-theme t-${ teams.away.className } ${ activeTooltip === 'away' ? STYLE_CLS_NAMES.IS_ACTIVE : '' }` } style={ { width: `${ winViz.awayPercent }%` } } onClick={ () => toggleTooltip( 'away' ) }>
                    <div className="win-predictor__bar-inner">
                        <TeamBadge
                            abbr={ teams.away.abbr }
                            className="win-predictor__team-badge"
                            type="symbol" />
                        <div className="win-predictor__info">
                            <span className="win-predictor__percent">{ `${ winViz.awayPercent }%` }</span>
                            <span className="win-predictor__text u-hide-phablet">{ en.chanceToWin }</span>
                        </div>
                    </div>
                    {
                        winViz.awayPercent <= TWENTY_FIVE_PERCENT &&
                            <span className="win-predictor__tooltip tooltip tooltip--right">{ `${ winViz.awayPercent }%` } { en.chanceToWin }</span>
                    }
                </div>
            </div>
            {
                !isMobile &&
                    <Explainer
                        title={ `${ en.predictor } ${ en.explained }` }
                        tooltipText={ en.predictorDescription }
                        tooltipModifier="tooltip--left"
                        className="win-predictor__explainer"/>
            }
        </div>
    );
}

export default WinPredictor;
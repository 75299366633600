import {
    concatOverHistory,
    calcBallNumber,
    bindPlayerInfo,
    // concatStatByProp,
    // getWordOrdinal
} from 'scripts/helpers';

/**
 * Processes the match scorecard data into latest feed cards, acting like a reducer
 *
 * @param {object} match - the match context
 * @returns {Array<object>} array of prepared feed card objects
 */
export const prepareFeed = async ( match ) => {

    const { info, teams, scorecard } = match;

    let preparedFeed = [];
    let cardNumber = 1;

    if ( teams.home.shortName && teams.away.shortName && info ) {
        const preMatchCard = {
            number: cardNumber++,
            type: 'pre-match'
        };
        preparedFeed.push( preMatchCard );
    }

    if ( teams.home.squad && teams.away.squad ) {
        const lineupsCard = {
            number: cardNumber++,
            type: 'lineups'
        };
        preparedFeed.push( lineupsCard );
    }

    const allOverHistory = concatOverHistory( scorecard.innings );

    // TODO - figure out how to display this data at real time, rather than the total number of each stat in every card
    // const allBattingStats = concatStatByProp( scorecard.innings, 'batting' );
    // const allBowlingStats = concatStatByProp( scorecard.innings, 'bowling' );

    if ( allOverHistory && allOverHistory.length > 0 ) {

        allOverHistory.forEach( over => {
            
            if ( over.ovBallDetails.length > 0 ) {
                over.ovBallDetails.forEach( ball => {

                    if ( ball.score && ball.bowlerId && ball.facingBatsmanId ) {
                        
                        switch ( true ) {
                            
                            // case /^2$/.test( ball.score ): // 2
                            // case /^3$/.test( ball.score ): // 3
                            case /^4$/.test( ball.score ): // 4
                            case /^5$/.test( ball.score ): // 5
                            case /^6$/.test( ball.score ): { // 6
                                const bowler = bindPlayerInfo( ball.bowlerId, teams );
                                const facingBatter = bindPlayerInfo( ball.facingBatsmanId, teams );
                                const card = {
                                    number: cardNumber++,
                                    type: ball.score,
                                    ballNumber: calcBallNumber( over.ovNo, ball.countingBall ),
                                    bowler: bowler,
                                    facingBatter: facingBatter,
                                    // eventCount: getEventCount( ball.facingBatsmanId, ball.score, allBattingStats )
                                };
                                preparedFeed.push( card );
                            }
                                break;
                            case /W(?!d)/.test( ball.score ): { // wicket
                                const bowler = bindPlayerInfo( ball.bowlerId, teams );
                                const facingBatter = bindPlayerInfo( ball.facingBatsmanId, teams );
                                const card = {
                                    number: cardNumber++,
                                    type: ball.score,
                                    ballNumber: calcBallNumber( over.ovNo, ball.countingBall ),
                                    bowler: bowler,
                                    facingBatter: facingBatter,
                                    // eventCount: getEventCount( ball.bowlerId, ball.score, allBowlingStats )
                                };
                                preparedFeed.push( card );
                            }
                                break;
                            default:
                                break;
                        }

                    } else if ( ball.bowlerId && ball.facingBatsmanId ) {
                        console.warn( 'Over History entry has no bowler or batter ID -- Batter: ', ball.facingBatsmanId, 'Bowler: ', ball.bowlerId );
                    }
                    
                } );
            }
        } );
    }
    
    return preparedFeed.reverse();
};

// /**
//  * Using the event type and the player's ID, returns how many times this event has occurred this innings for that player
//  *
//  * @param {number} playerId - the player's ID
//  * @param {string} eventType - the type of event to search for, e.g. 'W', '4'
//  * @param {Array<object>} stats - the stats to search for instances of an event, either bowling or batting stats
//  * @returns {string} the word ordinal for the number of times this event has occurred this innings
//  */
// const getEventCount = ( playerId, eventType, stats ) => {

//     const playerStats = stats.find( player => player.info.id === playerId );

//     if ( playerStats ) {

//         switch ( true ) {
//             case /4/.test( eventType ): // 4
//                 if ( playerStats.stats.fours && playerStats.stats.fours !== 0 ) {
//                     return getWordOrdinal( playerStats.stats.fours );
//                 }
//                 return null;
//             case /6/.test( eventType ): // 6
//                 if ( playerStats.stats.sixes && playerStats.stats.sixes !== 0 ) {
//                     return getWordOrdinal( playerStats.stats.sixes );
//                 }
//                 return null;
//             case /W(?!d)/.test( eventType ): // wicket
//                 if ( playerStats.stats.wickets && playerStats.stats.wickets !== 0 ) {
//                     return getWordOrdinal( playerStats.stats.wickets );
//                 }
//                 return null;
//             default:
//                 return null;
//         }
//     }

//     return null;
// };
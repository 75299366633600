/**
 * Builds a query string from a given map of parameters
 *
 * @param {object} params - The parameters map
 * @param {boolean} includeSeparator - Whether to include the ? before the string or not
 * @returns {string} - The query string
 */
export const buildQueryString = ( params, includeSeparator ) => {
    
    let paramsArray = [];

    for ( let key in params ) {
        if ( params.hasOwnProperty( key ) && typeof params[ key ] !== 'undefined' && ( Object.prototype.toString.call( params[ key ] ) !== '[object Array]' || params[ key ].length ) ) { // eslint-disable-line no-prototype-builtins
            var value = [].concat( params[ key ] ).join( ',' );
            var keyValuePair = [ key, encodeURIComponent( value ) ];
            paramsArray.push( keyValuePair.join( '=' ) );
        }
    }

    return paramsArray.length ? ( includeSeparator ? '?' : '' ) + paramsArray.join( '&' ) : '';
};

/**
 * Builds a URL to a match
 *
 * @param {number} id - the id of the match
 * @returns {string} - a match URL
 */
export const buildMatchUrl = id => {
    return `/${ id }`;
};

/**
 * Takes a string and makes it suitable for a URL
 * Replace spaces with hyphens and removes special characters
 *
 * @param {string} string - the string to Urlify
 * @returns {string} a string with hyphens and no special characters
 */
export const urlify = ( string ) => {
    return string
        .toLowerCase()
        .replace( /[^a-z0-9]+/gi, '-' )
        .replace( /-{2,}/g, '-' )
        .replace( /^-|-$/g, '' );
};

/**
 * IDs as Reference Expression
 * Given a passed set of IDs and a reference type, will create a string to use as a referenceExpression query param
 *
 * @param {Array} ids - an array of IDs to make into the reference expression
 * @param {string} referenceType - the type of reference, e.g. 'CRICKET_PLAYER' 'CRICKET_TEAM'
 * @param {string} combiner - either 'and' or 'or'
 * @returns {string} the reference expression string in format 'CRICKET_PLAYER:{id} or CRICKET_PLAYER:{id} or CRICKET_PLAYER:{id}'
 */
export const idsAsReferenceExpression = ( ids, referenceType, combiner ) => {
    let referenceExpression = '';

    ids.forEach( ( id, i ) => {

        if ( i === ids.length - 1 ) {
            referenceExpression += `${ referenceType }:${ id }`;
        } else {
            referenceExpression += `${ referenceType }:${ id } ${ combiner } `;
        }
    } );

    return referenceExpression;
};
import React, { useContext } from 'react';

import matchContext from 'scripts/context/match/matchContext';

import { en } from 'scripts/translations';

import InPlayPlayer from './InPlayPlayer';

import Loader from 'scripts/components/common/Loader/Loader';

import { ReactComponent as BallIcon } from 'i/icons/ball.svg';
import { ReactComponent as BatIcon } from 'i/icons/bat.svg';

/**
 * Displays the current bowler and the two batters in play
 *
 * @returns {object} React render
 */
function InPlay() {

    const { match } = useContext( matchContext );
    const { info, scorecard } = match;

    const isMatchComplete = info.state === 'C' || scorecard.currentInningsIndex > 1;

    if ( !scorecard.currentState ) {
        return <Loader/>;
    }

    const facingBatsmanId = scorecard.currentState.facingBatter.info.id;

    let facingBatsmanData = '';

    const nonFacingBatsmanId = scorecard.currentState.nonFacingBatter.info.id;

    let nonFacingBatsmanData = '';

    const innings = scorecard.innings[ scorecard.currentInningsIndex ];

    innings.batting.stats.byPlayer.forEach( ( player ) => {
        
        if ( player.info.id === facingBatsmanId ) {
            facingBatsmanData = player;
        } else if ( player.info.id === nonFacingBatsmanId ) {
            nonFacingBatsmanData = player;
        }
    } );

    return (
        <div className="in-play">
            <div className="in-play__bowler">
                <h3 className="in-play__heading">
                    <span className="in-play__heading-text in-play__heading-text--small">{ en.currently }</span>
                    <span className="in-play__heading-text">
                        { en.bowling }
                        <BallIcon className="in-play__heading-icon"/>
                    </span>
                </h3>
                <div className="in-play__player-container">
                    <InPlayPlayer
                        player={ isMatchComplete ? null : scorecard.currentState.bowler }
                        primaryStat={ 'w' }
                        primaryStatLabel={ 'wickets' }
                        secondaryStat={ 'balls' }
                        secondaryStatLabel={ 'balls' } />
                </div>
            </div>
            <div className="in-play__batters">
                <h3 className="in-play__heading">
                    <span className="in-play__heading-text in-play__heading-text--small">{ en.currently }</span>
                    <span className="in-play__heading-text">
                        { en.batting }
                        <BatIcon className="in-play__heading-icon"/>
                    </span>
                </h3>
                <div className="in-play__player-container">
                    <InPlayPlayer
                        player={ isMatchComplete ? null : facingBatsmanData }
                        primaryStat={ 'r' }
                        primaryStatLabel={ 'Runs' }
                        secondaryStat={ 'b' }
                        secondaryStatLabel={ 'balls' } />
                    <InPlayPlayer
                        player={ isMatchComplete ? null : nonFacingBatsmanData }
                        primaryStat={ 'r' }
                        primaryStatLabel={ 'Runs' }
                        secondaryStat={ 'b' }
                        secondaryStatLabel={ 'balls' }
                        className={ 'in-play-player--inactive' } />
                </div>
            </div>
        </div>
    );
}

export default InPlay;

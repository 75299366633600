import React from 'react';
import PropTypes from 'prop-types';

import LazyLoadImage from 'scripts/components/common/LazyLoadImage/LazyLoadImage';

import { ReactComponent as PlayerSilhouette } from 'i/elements/player-silhouette.svg';
import { ReactComponent as OverseasIcon } from 'i/icons/overseas.svg';
import { ReactComponent as CaptainIcon } from 'i/icons/captain.svg';
import { ReactComponent as WicketKeeperIcon } from 'i/icons/wicket-keeper.svg';

/**
 * Card containing a player headshot, name and role
 * Also displays icons if the player is overseas, wicket keeper or captain
 *
 * @param {object} props - properties passed to the component
 * @param {object} props.player - the player object
 * @returns {object} React render
 */
function PlayerCard( { player } ) {

    const renderHeadshot = headshotUrl => {

        if ( headshotUrl ) {
            return (
                <LazyLoadImage
                    src={ `${ player.headshotUrl }?width=460height=690` }
                    srcConfigs={ [
                        { srcSet: `${ player.headshotUrl }?width=460height=690`, mediaQuery: '(max-width: 839px)' },
                        { srcSet: `${ player.headshotUrl }?width=460height=690, ${ player.headshotUrl }?width=920height=1380 2x`, mediaQuery: '(min-width: 840px)' },
                    ] }
                    pictureClassName={ 'player-card__image' }
                    imgClassName={ 'player-card__img' }
                    alt={ `${ player.firstName } ${ player.lastName }` }
                    placeholder={ false } />
            );
        }

        return (
            <div className="player-card__image player-card__image--silhouette">
                <PlayerSilhouette />
            </div>
        );
    };

    return (
        <figure className="player-card__image-container placeholder team-player-background">
            { renderHeadshot( player.headshotUrl ) }
            {
                player.overseas || player.captain || player.wicketKeeper ? (
                    <div className="player-card__meta">
                        {
                            player.overseas &&
                                <OverseasIcon className="player-card__meta-icon"/>
                        }
                        {
                            player.captain &&
                                <CaptainIcon className="player-card__meta-icon"/>
                        }
                        {
                            player.wicketKeeper &&
                                <WicketKeeperIcon className="player-card__meta-icon"/>
                        }
                    </div>
                ) : (
                    null
                )
            }
            <figcaption className="player-card__caption">
                <span className="player-card__forename">{ player.firstName }</span><br/>
                <span className="player-card__surname">{ player.lastName }</span><br />
                {
                    player.role &&
                        <span className="player-card__role">{ player.role }</span>
                }
            </figcaption>
        </figure>
    );
}

PlayerCard.propTypes = {
    player: PropTypes.object
};

export default PlayerCard;
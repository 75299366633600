import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import { COMPONENT_REVEAL_TRANSITION_OPTIONS } from 'scripts/constants';
import { toggleScorebox } from 'scripts/helpers';
import { en } from 'scripts/translations';

import scoreboxContext from 'scripts/context/scorebox/scoreboxContext';
import matchContext from 'scripts/context/match/matchContext';

import PageHeader from 'scripts/components/common/Header/PageHeader';
import BallsByOver from './BallByBall/BallByBall';
import InPlay from './InPlay';
import WinPredictor from './WinPredictor';

/**
 * Screen - Ball By Ball
 * Contains a list of all the balls bowled so far split by innings, the current bowler and batters in play and a Win Predictor
 *
 * @param {object} props - properties passed to the component
 * @param {string} props.dummyDataPhase - TEMP - the phase of the data to display
 * @param {number} props.dummyPollInterval - TEMP - the dummy poll interval
 * @returns {object} React render
 */
function BallByBall( { dummyDataPhase, dummyPollInterval } ) {

    const { scoreboxOpen, setScoreboxOpen } = useContext( scoreboxContext );
    const { match } = useContext( matchContext );
    const { info, scorecard } = match;

    useEffect( () => {
        if ( !scoreboxOpen ) {
            toggleScorebox(); // ensure the scorebox is open
            setScoreboxOpen( true );
        }
        // eslint-disable-next-line
    }, [] );

    if ( info.state === 'U' || scorecard === null ) {
        return (
            <div className="wrapper">
                <PageHeader title={ `${ en.ballByBall } ${ en.willAppearHere }` } description={ en.checkBack } />
            </div>
        );
    }

    return (
        <CSSTransition { ...COMPONENT_REVEAL_TRANSITION_OPTIONS }>
            <div className="ball-by-ball reveal-component">
                <div className="ball-by-ball__grid">
                    <div className="ball-by-ball__grid-column ball-by-ball__grid-column--left mobile-navigation-offset">
                        <BallsByOver dummyDataPhase={ dummyDataPhase } dummyPollInterval={ dummyPollInterval } />
                    </div>
                    <div className="ball-by-ball__grid-column">
                        <InPlay />
                        <WinPredictor />
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
}

BallByBall.propTypes = {
    dummyDataPhase: PropTypes.string,
    dummyPollInterval: PropTypes.number
};

export default BallByBall;

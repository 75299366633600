import { CMS_ACCOUNT, DEFAULT_LANGUAGE } from 'scripts/config';
import { buildQueryString } from 'scripts/helpers';

const { cricketApi, commentaryApi, contentApi } = window.environment;

export const getMatchesUrl = ( params, id ) => `${ cricketApi }/fixtures${ id ? `/${ id }` : '' }${ params ? buildQueryString( params, true ) : '' }`;
export const getMatchScoringUrl = ( matchId, params ) => `${ cricketApi }/fixtures/${ matchId }/scoring/${ params ? buildQueryString( params, true ) : '' }`;
export const getMatchCommentaryUrl = ( matchId, params ) => `${ commentaryApi }/${ matchId }/en${ params ? buildQueryString( params, true ) : '' }`;
export const getSquadsUrl = ( teamId, tournamentId, params ) => `${ cricketApi }/tournaments/${ tournamentId }/squads/${ teamId }${ params ? buildQueryString( params, true ) : '' }`;
export const getCricVizUrl = matchId => `https://cdn-api.cricviz.com/site/v2/match/${ matchId }/scorecard?feed=pulse`;
export const getContentUrl = ( type, params, id ) => {
    let url = `${ contentApi }/content/${ CMS_ACCOUNT }/${ type.toLowerCase() }/${ DEFAULT_LANGUAGE }${ id ? `/${ id }` : '' }`;
    if ( params ) {
        url += buildQueryString( params, true );
    }
    return url;
};
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { BALLS_PER_OVER } from 'scripts/constants';
import { en } from 'scripts/translations';

import matchContext from 'scripts/context/match/matchContext';

import Ball from './Ball';
import PlaceholderBall from './PlaceholderBall';

/**
 * An indidual over in the context of the Ball By Ball table
 *
 * @param {object} props - properties passed to the component
 * @param {Array<object>} props.over - a list of all the balls bowled this over
 * @returns {object} React render
 */
function Over( { over } ) {

    const { match } = useContext( matchContext );
    const { info, scorecard, teams } = match;

    const isMatchComplete = info.state === 'C' || scorecard.currentInningsIndex > 1;

    let placeholderBalls = [];

    if ( over.balls.length < BALLS_PER_OVER ) {
        const unbowledInOver = BALLS_PER_OVER - over.balls.length;
        for ( let i = 0; i < unbowledInOver; i++ ) {
            placeholderBalls.push( {} );
        }
    }

    /**
     * Grabs the number of counting balls in this over by checking the countingProgress of the last, or most recent, bowled ball
     *
     * @returns {number} - the countingProgress of the over
     */
    const getOverProgress = () => {
        const lastBall = over.balls[ over.balls.length - 1 ];
        return (
            <span className="over__progress">
                <span className="over__progress-count">{ lastBall.countingProgress.ball }</span>
                { lastBall.countingProgress.ball === 1 ? en.ball : en.balls }
            </span>
        );
    };

    /**
     * Using the teams data from the scoring context, returns the bowler name that matches the passed ID
     *
     * @param {number} id - the ID of the bowler to get
     * @returns {string} the name of the bowler of this over
     */
    const getBowlerName = id => {

        let bowler = teams.home.squad.find( player => player.id === id );
        if ( !bowler ) {
            bowler = teams.away.squad.find( player => player.id === id );
        }

        if ( !bowler ) {
            return null;
        }

        return bowler.fullName;
    };

    return (
        <div className="over">
            <div className="over__info">
                { getOverProgress() }
                <span className="over__bowler">{ getBowlerName( over.bowlerId ) }</span>
            </div>
            <ol className="over__balls">
                {
                    over.balls.map( ( ball, idx ) => (
                        <li className="over__ball" key={ ball.messageOrder }>
                            <Ball ball={ ball } idx={ idx } />
                        </li>
                    ) )
                }
                {
                    placeholderBalls.map( ( ball, idx ) => (
                        <li className="over__ball" key={ idx }>
                            <PlaceholderBall isCurrentBall={ !isMatchComplete && idx === 0 } />
                        </li>
                    ) )
                }
            </ol>
        </div>
    );
}

Over.propTypes = {
    over: PropTypes.object.isRequired
};

export default Over;
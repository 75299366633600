export const CMS_ACCOUNT = 'ecb';
export const DEFAULT_LANGUAGE = 'EN';
export const COOKIE_PREFIX = 'hundred-ball_';

export const APP_PATHS = [
    {
        label: 'local',
        domain: 'localhost',
        parentDomain: 'https://dev-ecb-hundred-ball.pulselive.com',
        cricketApi: 'https://cdp.dev.platform.pulselive.com',
        // scoringApi: 'https://cricketapi-scorecard.dev.platform.pulselive.com/scorecard/scoring',
        commentaryApi: 'https://cdp.dev.platform.pulselive.com/commentary/ecb',
        contentApi: 'https://api.dev.platform.pulselive.com'
    },
    {
        label: 'dev',
        domain: 'matches.dev.thehundred.ecb.pulselive.com',
        parentDomain: 'https://dev-ecb-hundred-ball.pulselive.com',
        cricketApi: 'https://cdp.dev.platform.pulselive.com',
        // scoringApi: 'https://cricketapi-scorecard.dev.platform.pulselive.com/scorecard/scoring',
        commentaryApi: 'https://cdp.dev.platform.pulselive.com/commentary/ecb',
        contentApi: 'https://api.dev.platform.pulselive.com'
    },
    {
        label: 'test',
        domain: 'matches.test.thehundred.ecb.pulselive.com',
        parentDomain: 'https://test-ecb-hundred-ball.pulselive.com',
        cricketApi: 'https://cdp.test.platform.pulselive.com',
        // scoringApi: 'https://cricketapi-scorecard.test.platform.pulselive.com/scorecard/scoring',
        commentaryApi: 'https://cdp.test.platform.pulselive.com/commentary/ecb',
        contentApi: 'https://api.test.platform.pulselive.com'
    },
    {
        label: 'prod',
        domain: 'matches.thehundred.com',
        parentDomain: 'https://thehundred.com',
        cricketApi: 'https://cdp.platform.pulselive.com',
        // scoringApi: 'https://cricketapi-scoring.ecb.pulselive.com/scorecard/scoring',
        commentaryApi: 'https://cdp.platform.pulselive.com/commentary/ecb',
        contentApi: 'https://content-ecb.pulselive.com'
    }
];

/**
 * Returns environment object based on current URL
 *
 * @returns {object} - environment object
 */
export function getEnvironment() {
    let matchingPath = APP_PATHS.find( path => path.domain === window.location.hostname );

    if ( !matchingPath ) {
        const prodPathIdx = 3;
        matchingPath = APP_PATHS[ prodPathIdx ];
    }

    return matchingPath;
}

window.environment = getEnvironment();
import React, { useContext } from 'react';

import matchContext from 'scripts/context/match/matchContext';

import FirstInnings from './FirstInnings';
import SecondInnings from './SecondInnings';
import PostScorebox from '../postMatch/PostScorebox';

/**
 * Scorebox used when the match is live to determine which inning to display
 *
 * @returns {object} React render
 */
export default function LiveScorebox() {

    const { match } = useContext( matchContext );
    const { scorecard } = match;

    if ( scorecard.currentInningsIndex > 1 ) { // when a tiebreaker is being played
        return <PostScorebox isSuper5={ true } />;
    }

    const firstInnings = scorecard.currentInningsIndex ? false : true;

    return (
        firstInnings ? (
            <FirstInnings />
        ) : (
            <SecondInnings />
        )
    );
}
import React from 'react';
import PropTypes from 'prop-types';

import { en } from 'scripts/translations';

import LazyLoadImage from 'scripts/components/common/LazyLoadImage/LazyLoadImage';

import BallCount from './BallCount';

/**
 * Latest Feed Card for runs, either 3, 4, 5, or 6
 *
 * @param {object} props - properties passed to the component
 * @param {object} props.card - card data
 * @returns {object} React render
 */
function Runs( { card } ) {

    const getRunsTranslation = () => {

        switch ( true ) {
            case /2/.test( card.type ):
                return en.two;
            case /3/.test( card.type ):
                return en.three;
            case /4/.test( card.type ):
                return en.four;
            case /5/.test( card.type ):
                return en.five;
            case /6/.test( card.type ):
                return en.six;
            default:
                return en.runs;
        }
    };
    
    const getCardText = () => {

        switch ( true ) {

            case /2/.test( card.type ):
                return (
                    <>
                        <h3 className="team-card__title">
                            { en.twoRunsCardTitle.replace( '{batter}', card.facingBatter.fullName ) }
                        </h3>
                        <h4 className="team-card__subtitle">
                            { en.twoRunsCardSubtitle.replace( '{bowler}', card.bowler.fullName ) }
                        </h4>
                    </>
                );
            case /3/.test( card.type ):
                return (
                    <>
                        <h3 className="team-card__title">
                            { en.threeRunsCardTitle.replace( '{batter}', card.facingBatter.fullName ) }
                        </h3>
                        <h4 className="team-card__subtitle">
                            { en.threeRunsCardSubtitle.replace( '{bowler}', card.bowler.fullName ) }
                        </h4>
                    </>
                );
            case /4/.test( card.type ):
                return (
                    <>
                        <h3 className="team-card__title">
                            { en.fourRunsCardTitle.replace( '{batter}', card.facingBatter.fullName ) }
                        </h3>
                        <h4 className="team-card__subtitle">
                            { en.fourRunsCardSubtitle.replace( '{bowler}', card.bowler.fullName ) }
                        </h4>
                    </>
                );
            case /5/.test( card.type ):
                return (
                    <>
                        <h3 className="team-card__title">
                            { en.fiveRunsCardTitle.replace( '{batter}', card.facingBatter.fullName ) }
                        </h3>
                        <h4 className="team-card__subtitle">
                            { en.fiveRunsCardSubtitle.replace( '{bowler}', card.bowler.fullName ) }
                        </h4>
                    </>
                );
            case /6/.test( card.type ):
                return (
                    <>
                        <h3 className="team-card__title">
                            { en.sixRunsCardTitle.replace( '{batter}', card.facingBatter.fullName ) }
                        </h3>
                        <h4 className="team-card__subtitle">
                            { en.sixRunsCardSubtitle.replace( '{bowler}', card.bowler.fullName ) }
                        </h4>
                    </>
                );
            default:
                return null;
        }
    };

    const renderHeadshot = facingBatter => {

        if ( facingBatter && facingBatter.headshotUrl ) {
            return (
                <LazyLoadImage
                    src={ `${ facingBatter.headshotUrl }?width=460height=690` }
                    srcConfigs={ [
                        { srcSet: `${ facingBatter.headshotUrl }?width=460height=690`, mediaQuery: '(max-width: 839px)' },
                        { srcSet: `${ facingBatter.headshotUrl }?width=460height=690, ${ facingBatter.headshotUrl }?width=920height=1380 2x`, mediaQuery: '(min-width: 840px)' },
                    ] }
                    alt={ `${ facingBatter.firstName } ${ facingBatter.lastName }` }
                    className="team-card__player-img-container"
                    imgClassName="team-card__player-img"
                    placeholder={ false } />
            );
        }

        return null;
    };

    return (
        <div className={ `team-card__content team-background ${ card.number % 2 ? '' : 'team-background--alt' }` }>
            <div className="team-card__text-container">
                {
                    card.ballNumber &&
                        <BallCount ballNumber={ card.ballNumber }/>
                }
                <h1 className="latest-feed__card-title latest-feed__card-title--active">{ getRunsTranslation() }</h1>
                <div className="team-card__text">
                    { getCardText() }
                </div>
            </div>
            <div className="team-card__image-container">
                { renderHeadshot( card.facingBatter ) }
            </div>
            <h1 className="latest-feed__card-title latest-feed__card-title--inactive u-hide-tablet">{ getRunsTranslation() }</h1>
        </div>
    );
}

Runs.propTypes = {
    card: PropTypes.object
};

export default Runs;

import React, { useContext, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import { COMPONENT_REVEAL_TRANSITION_OPTIONS } from 'scripts/constants';
import { toggleScorebox } from 'scripts/helpers';
import { en } from 'scripts/translations';

import matchContext from 'scripts/context/match/matchContext';
import scoreboxContext from 'scripts/context/scorebox/scoreboxContext';

import PageHeader from 'scripts/components/common/Header/PageHeader';
import Tabs from 'scripts/components/common/Tabs/Tabs';

import Squad from './Squad';

/**
 * Screen - Squad
 *
 * @returns {object} React render
 */
export default function Squads() {

    const { match } = useContext( matchContext );
    const { teams } = match;
    const { scoreboxOpen, setScoreboxOpen } = useContext( scoreboxContext );

    useEffect( () => {
        if ( !scoreboxOpen ) {
            toggleScorebox(); // ensure the scorebox is open
            setScoreboxOpen( true );
        }
        
        // eslint-disable-next-line
    }, [] );

    if ( !teams || !Object.keys( teams ).length > 0 ) {
        return (
            <div className="wrapper">
                <PageHeader title={ `${ en.squads } ${ en.willAppearHere }` } description={ en.checkBack }/>
            </div>
        );
    }

    return (
        <CSSTransition { ...COMPONENT_REVEAL_TRANSITION_OPTIONS }>
            <div className="squads reveal-component mobile-navigation-offset">
                <div className="wrapper">
                    <Tabs transitionDuration={ 350 }>
                        <div tabname={ teams.home.shortName } tabclass={ `t-team-theme t-${ teams.home.className }` }>
                            <Squad team={ teams.home }/>
                        </div>
                        <div tabname={ teams.away.shortName } tabclass={ `t-team-theme t-${ teams.away.className }` }>
                            <Squad team={ teams.away }/>
                        </div>
                    </Tabs>
                </div>
            </div>
        </CSSTransition>
    );
}

import React from 'react';
import PropTypes from 'prop-types';

import TeamBadge from 'scripts/components/common/TeamBadge/TeamBadge';

import { ReactComponent as SwipeIcon } from 'i/icons/swipe.svg';

/**
 * A Header for each scorecard table, e.g. Batting, Bowling
 *
 * @param {object} props - properties passed to the component
 * @param {string} props.title - the title to display in this header, should be a translation label
 * @param {object} props.team - the team object for the team these stats pertain to
 * @param {boolean} props.showScrollingIcon - set to true to display the scrolling icon, i.e. when the table can be scrolled
 * @returns {object} React render
 */
function Header( { title, team, showScrollingIcon } ) {

    return (
        <div className="scorecard__header">
            {
                team &&
                    <TeamBadge abbr={ team.abbr } className="scorecard__team-badge" type="matchListDark" />
            }
            {
                title &&
                    <div className="scorecard__header-title">
                        <span className="scorecard__header-label">{ title }</span>
                        <span className="scorecard__header-team">{ team.shortName }</span>
                    </div>
            }
            {
                showScrollingIcon &&
                    <SwipeIcon className="scorecard__header-swipe" />
            }
        </div>
    );
}

Header.propTypes = {
    title: PropTypes.string,
    team: PropTypes.object,
    showScrollingIcon: PropTypes.bool
};

export default Header;
import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';

import { getContentUrl } from 'scripts/endpoints';
import { formatTitle, getPromoModifiers } from 'scripts/helpers';

import matchContext from 'scripts/context/match/matchContext';

import { ReactComponent as Chevrons } from 'i/chevrons/promo.svg';
import { ReactComponent as MobileChevrons } from 'i/chevrons/promo-mobile.svg';
import { ReactComponent as ExternalIcon } from 'i/icons/external.svg';

/**
 * Requests Promos for this match that are tagged with 'The Hundred - Pre-Match Promo' to display before a match
 *
 * @returns {object} React render
 */
function PreMatchPromos() {

    const { match } = useContext( matchContext );
    const [ preMatchPromos, setPreMatchPromos ] = useState( null );

    const getPreMatchPromos = async () => {

        const params = {
            page: 0,
            pageSize: 5, // maximum of 5 promos in pre match
            tagNames: 'The Hundred - Pre-Match Promo',
            references: `CRICKET_MATCH:${ match.info.id }`,
        };
        const url = getContentUrl( 'promo', params );
        try {
            const res = await axios.get( url );
            setPreMatchPromos( res.data );
        } catch ( err ) {
            setPreMatchPromos( null );
        }
    };

    useEffect( () => {
        getPreMatchPromos();
        // eslint-disable-next-line
    }, [] );

    if ( preMatchPromos === null || ( preMatchPromos.content && preMatchPromos.content.length === 0 ) ) {
        return null;
    }

    return (
        <div className="pre-match-promos">
            {
                preMatchPromos.content.map( ( promo, i ) => (
                    
                    <div className={ `pre-match-promos__promo promo-generic ${ getPromoModifiers( 'promo-generic', promo.tags ) }` } key={ i }>
                        <div className="promo-generic__wrapper">
                            <h3 className="promo-generic__title" dangerouslySetInnerHTML={ formatTitle( promo.title ) }/>
                            {
                                promo.description &&
                                    <p className="promo-generic__description">{ promo.description }</p>
                            }
                            <div className="promo-generic__link-container">
                                {
                                    promo.links.map( ( link, idx ) => {
                                        return link.external ? (
                                            <a className="promo-generic__link button" href={ link.promoUrl } target="_blank" rel="noopener noreferrer" key={ idx }>
                                                { link.linkText }
                                                <ExternalIcon className="promo-generic__external-icon"/>
                                            </a>
                                        ) : (
                                            <a className="promo-generic__link button" href={ link.promoUrl } key={ idx }>
                                                { link.linkText }
                                            </a>
                                        );
                                    } )
                                }
                            </div>
                            <Chevrons className="promo-generic__chevron u-hide-tablet"/>
                            <MobileChevrons className="promo-generic__chevron u-show-tablet"/>
                        </div>
                    </div>
                ) )
            }
        </div>
    );
}

export default PreMatchPromos;
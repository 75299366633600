import React from 'react';
import PropTypes from 'prop-types';

/**
 * Header for a page/screen
 *
 * @param {object} props - properties passed to the component
 * @param {string} props.title - title text
 * @param {string} props.description - body text
 * @param {string} props.className - additional CSS className
 * @returns {object} React render
 */
function PageHeader( { title, description, className = '' } ) {
    return (
        <header className={ `page-header ${ className }` }>
            <h1 className="page-header__title">{ title }</h1>
            {
                description &&
                    <span className="page-header__sub">{ description }</span>
            }
        </header>
    );
}

PageHeader.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    className: PropTypes.string
};

export default PageHeader;

import { GET_COMMENTARY, COMMENTARY_ERROR } from '../types';
import { calcBallNumber } from 'scripts/helpers';

const reduceCommentary = commentary => {

    if ( !commentary || !commentary.commentaryEntries ) {
        return null;
    }

    let formattedCommentary = {
        1: [], // first innings
        2: [] // second innings
    };

    if ( commentary && commentary.commentaryEntries && commentary.commentaryEntries.length > 0 ) {

        /**
         * Remove all "Eov" messages as they're not needed
         */
        const removedEovMessages = commentary.commentaryEntries.filter( entry => entry.type !== 'Eov' );

        /**
         * Ensure messages are ordered by their messageOrder property, starting with the most recent
         */
        const orderedEntries = removedEovMessages.sort( ( compareA, compareB ) => ( compareB.messageOrder - compareA.messageOrder ) );

        let currentOver = { // scaffold of an over
            bowlerId: -1,
            innings: -1,
            number: -1,
            balls: []
        };

        orderedEntries.forEach( ( entry, idx ) => {

            if ( entry.countingProgress.innings <= 2 ) { // ignore entries that occur in innings past the second

                if ( currentOver.number === -1 ) { // very first ball processed

                    currentOver.bowlerId = entry.pulseBowlerId;
                    currentOver.innings = entry.countingProgress.innings;
                    currentOver.number = entry.countingProgress.over;

                } else if ( currentOver.number !== entry.countingProgress.over ) { // a new over has started, push the previous, formatted over to its innings
                    formattedCommentary[ currentOver.innings ].push( currentOver );

                    currentOver = { // reset current over
                        bowlerId: entry.pulseBowlerId,
                        innings: entry.countingProgress.innings,
                        number: entry.countingProgress.over,
                        balls: []
                    };
                }

                const ball = {
                    ...entry,
                    number: calcBallNumber( entry.progress.over, entry.countingProgress.ball ),
                    score: getEntryScore( entry.type ),
                    isWicket: ballIsWicket( entry.type )
                };
                currentOver.balls.unshift( ball ); // add each ball to start of the array, effectively reversing it
                
                if ( idx === orderedEntries.length - 1 ) { // this is the last entry (or first ball), so make sure it and its over are pushed
                    formattedCommentary[ entry.countingProgress.innings ].push( currentOver );
                }
            }

        } );
    }
    
    return formattedCommentary;
};

/**
 * Get the score to display in Ball By Ball
 *
 * @param {string} type - the commentary entry type
 * @returns {string} the score to display in Ball By Ball
 */
const getEntryScore = type => {

    switch ( type ) {
        case 'wicket':
        case 'catch':
        case 'run out':
            return 'W';
        case 'wide':
            return 'Wd';
        case 'no_ball':
            return 'Nb';
        case 'leg_bye':
            return 'Lb';
        case 'bye':
            return 'B';
        case 'dot ball':
        case 'normal':
            return '0';
        case 'one':
            return '1';
        case 'two':
            return '2';
        case 'three':
            return '3';
        case 'four':
            return '4';
        case 'five':
            return '5';
        case 'six':
            return '6';
        default:
            return type;
    }
};

/**
 * Is this ball a wicket?
 *
 * @param {string} type - the commentary entry type
 * @returns {string} true if the ball is a wicket
 */
const ballIsWicket = type => {
    
    switch ( type ) {
        case 'wicket':
        case 'catch':
        case 'run out':
            return true;
        default:
            return false;
    }
};

export default ( state, action ) => {

    switch ( action.type ) {
        case GET_COMMENTARY: {
            return {
                ...state,
                commentary: reduceCommentary( action.payload.commentary ),
                loading: false
            };
        }
        case COMMENTARY_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
};
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getPollInterval } from 'scripts/helpers';
import { useInterval } from 'scripts/hooks';
import { en } from 'scripts/translations';

import matchContext from 'scripts/context/match/matchContext';
import commentaryContext from 'scripts/context/commentary/commentaryContext';

import PageHeader from 'scripts/components/common/Header/PageHeader';
import Loader from 'scripts/components/common/Loader/Loader';
import Explainer from 'scripts/components/common/Explainer/Explainer';

import Over from './Over';
import InningsHeader from './InningsHeader';

/**
 * Container for all the overs bowled so far
 *
 * @param {object} props - properties passed to the component
 * @param {string} props.dummyDataPhase - TEMP - the phase of the data to display
 * @returns {object} React render
 */
function BallByBall( { dummyDataPhase } ) {

    const { matchId } = useParams();

    const { match } = useContext( matchContext );
    const { info, scorecard } = match;

    const { commentary, getCommentary, loading } = useContext( commentaryContext );
    
    useEffect( () => {
        getCommentary( matchId, dummyDataPhase );
    }, [ dummyDataPhase ] ); // eslint-disable-line

    useInterval( () => {
        getCommentary( matchId, dummyDataPhase );
    }, getPollInterval( match ) );
    
    if ( loading ) {
        return <Loader />;
    }
    
    if ( !commentary ) {
        return (
            <PageHeader title={ `${ en.ballByBall } ${ en.willAppearHere }` } description={ en.checkBack } className="balls-by-over__header" />
        );
    }

    const firstInnings = scorecard.innings[ 0 ];
    const secondInnings = scorecard.innings[ 1 ];

    return (
        <div className="balls-by-over">
            <Explainer title={ en.tapBallForDetails } className="balls-by-over__explainer u-show-tablet" />
            <div className="balls-by-over__overs">
                {
                    secondInnings?.batting?.stats &&
                        <InningsHeader battingTeamInnings={ secondInnings.batting } title={ `${ en.second } ${ en.innings }` } isComplete={ info.state && info.state === 'C' } />
                }
                {
                    commentary[ 2 ] && commentary[ 2 ].length > 0 &&
                    commentary[ 2 ].map( ( commentaryBlock, idx ) => (
                        <div className="balls-by-over__over" key={ idx }>
                            <Over over={ commentaryBlock }/>
                        </div>
                    ) )
                }
                {
                    firstInnings?.batting?.stats &&
                        <InningsHeader battingTeamInnings={ firstInnings.batting } title={ `${ en.first } ${ en.innings }` } isComplete={ secondInnings?.batting?.stats && secondInnings?.batting?.stats !== {} } />
                }
                {
                    commentary[ 1 ] && commentary[ 1 ].length > 0 &&
                    commentary[ 1 ].map( ( commentaryBlock, idx ) => (
                        <div className="balls-by-over__over" key={ idx }>
                            <Over over={ commentaryBlock }/>
                        </div>
                    ) )
                }
            </div>
        </div>
    );
}

BallByBall.propTypes = {
    dummyDataPhase: PropTypes.string,
    dummyPollInterval: PropTypes.number
};

export default BallByBall;
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { en } from 'scripts/translations';

import LazyLoadImage from 'scripts/components/common/LazyLoadImage/LazyLoadImage';

/**
 * Coach for a team within squads screen
 *
 * @param {object} props - properties passed to the component
 * @param {object} props.coachBio - Content API Bio for a team's coach
 * @returns {object} React render
 */
function Coach( { coachBio } ) {
    
    if ( !coachBio ) {
        return null;
    }

    useEffect( () => {
        return () => { // unmount
            null;
        };
    }, [] ); // eslint-disable-line
    
    return (
        <div className="coach">
            <div className="coach__description team-background">
                <span className="coach__name">{ coachBio.knownName }</span>
                <span className="coach__text">{ en.coach }</span>
            </div>
            <div className="coach__image-container">
                {
                    coachBio.imageUrl &&
                        <LazyLoadImage
                            src={ `${ coachBio.imageUrl }?height=100` }
                            srcConfigs={ [
                                { srcSet: `${ coachBio.imageUrl }?height=100, ${ coachBio.imageUrl }?height=200 2x`, mediaQuery: '(min-width: 641px)' },
                                { srcSet: `${ coachBio.imageUrl }?height=60, ${ coachBio.imageUrl }?height=120 2x`, mediaQuery: '(max-width: 640px)' }
                            ] }
                            alt={ coachBio.knownName }
                            placeholder={ false }/>
                }
            </div>
        </div>
    );
}

Coach.propTypes = {
    coachBio: PropTypes.object
};

export default Coach;
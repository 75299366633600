import React, { useContext } from 'react';

import matchContext from 'scripts/context/match/matchContext';

import { TOTAL_BALLS_IN_MATCH } from 'scripts/constants';
import { splitTeamName } from 'scripts/helpers';
import { en } from 'scripts/translations';

import TeamBadge from 'scripts/components/common/TeamBadge/TeamBadge';

import Score from './Score';
import ChaseBar from './ChaseBar';

import { ReactComponent as ArrowsLiveIcon } from 'i/icons/arrows-live-second-innings.svg';

/**
 * Displays the score for the team batting in the second innings
 *
 * @returns {object} React render
 */
export default function SecondInningsScorebox () {

    const { match } = useContext( matchContext );
    const { scorecard } = match;

    const firstInnings = scorecard.innings[ 0 ];
    const secondInnings = scorecard.innings[ 1 ];

    const totalBalls = TOTAL_BALLS_IN_MATCH;
    const ballsLeft = totalBalls - secondInnings.batting.stats.ballsFaced;

    const currentScore = secondInnings.batting.stats.runs;
    const oppositionScore = firstInnings.batting.stats.runs;
    const runsNeeded = ( oppositionScore - currentScore ) + 1;

    return (
        <div className="scorebox__main">
            <div className="scorebox__inner-container scorebox__inner-container--live">
                <div className="scorebox__team-container">
                    <TeamBadge
                        abbr={ secondInnings.batting.team.abbr }
                        className="scorebox__team-badge u-hide-tablet"
                        type="matchListLight" />
                    <TeamBadge
                        abbr={ secondInnings.batting.team.abbr }
                        className="scorebox__team-badge u-show-tablet"
                        type="matchListDark" />
                    <span className="scorebox__team-name" dangerouslySetInnerHTML={ splitTeamName( secondInnings.batting.team.shortName ) } />
                </div>
                <div className="scorebox__runs-container">
                    <Score value={ runsNeeded } alt={ false } />
                    <div className="scorebox__runs-label"><span className="scorebox__runs-label-alt">{ en.runs } </span>{ en.needed }</div>
                    <div className="scorebox__wickets-text u-show-phablet">{ secondInnings.bowling.stats.wkts } { en.wickets }</div>
                </div>
                <span className="scorebox__icon-arrows">
                    <ArrowsLiveIcon />
                </span>
                <div className="scorebox__balls-container">
                    <div className="scorebox__label">{ en.balls } <span className="scorebox__label-alt">{ en.left }</span></div>
                    <Score value={ ballsLeft } alt={ false } />
                </div>
                <div className="scorebox__wickets-container u-hide-phablet">
                    <div className="scorebox__label">{ en.wickets }</div>
                    <Score value={ secondInnings.bowling.stats.wkts } digits={ 2 } alt={ true } />
                </div>
            </div>
            <ChaseBar currentScore={ currentScore } oppositionScore={ oppositionScore + 1 } opposingTeam={ secondInnings.bowling.team }/>
        </div>
    );
}
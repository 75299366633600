import React, { useContext } from 'react';

import { splitTeamName } from 'scripts/helpers';
import { en } from 'scripts/translations';

import matchContext from 'scripts/context/match/matchContext';

import TeamBadge from 'scripts/components/common/TeamBadge/TeamBadge';
import Countdown from 'scripts/components/common/Countdown/Countdown';

/**
 * Scorebox used when the match hasn't started yet
 *
 * @returns {object} React render
 */
export default function Scorebox() {

    const { match } = useContext( matchContext );
    const { info, teams } = match;

    return (
        <div className="scorebox__main">
            <div className="scorebox__inner-container scorebox__inner-container--pre">
                <div className="scorebox__team-container scorebox__team-container--home">
                    <span className="scorebox__team-name" dangerouslySetInnerHTML={ splitTeamName( teams.home.shortName ) } />
                    <TeamBadge
                        abbr={ teams.home.abbr }
                        className="scorebox__team-badge u-hide-tablet"
                        type="matchListLight" />
                    <TeamBadge
                        abbr={ teams.home.abbr }
                        className="scorebox__team-badge u-show-tablet"
                        type="matchListDark" />
                </div>
                <div className="scorebox__countdown">
                    <span className="scorebox__countdown-label">{ en.match } { en.countdown }</span>
                    <div className="scorebox-countdown-container">
                        <Countdown datetime={ info.startTime } />
                    </div>
                </div>
                <div className="scorebox__team-container scorebox__team-container--away">
                    <TeamBadge
                        abbr={ teams.away.abbr }
                        className="scorebox__team-badge u-hide-tablet"
                        type="matchListLight" />
                    <TeamBadge
                        abbr={ teams.away.abbr }
                        className="scorebox__team-badge u-show-tablet"
                        type="matchListDark" />
                    <span className="scorebox__team-name" dangerouslySetInnerHTML={ splitTeamName( teams.away.shortName ) } />
                </div>
            </div>
        </div>
    );
}